import { Component, EventEmitter, Inject, Input, Output } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"

import { BehaviorSubject, filter, switchMap } from "rxjs"

import { Destructible, ToastService, UploadedFile } from "@anzar/core"

import { FileUploaderService, FsService } from "@pyzar/fs.module"

import { OrderHistoryRepo } from "@backend/order.api"

@Component({
    selector: "eur-order-history-add",
    templateUrl: "./history-add.component.pug",
    providers: [FileUploaderService]
})
export class OrderHistoryAddComponent extends Destructible {
    readonly form = new FormGroup({
        message: new FormControl(),
        is_todo: new FormControl(),
        files: new FormArray([new FormControl()])
    })

    get fileControls() {
        return this.form.get("files") as FormArray
    }

    @Input()
    readonly orderId: number

    @Output()
    readonly cancelled = new EventEmitter<void>()

    @Output()
    readonly done = new EventEmitter<void>()

    readonly busy$ = new BehaviorSubject(false)

    constructor(
        @Inject(OrderHistoryRepo) private readonly orderHistoryRepo: OrderHistoryRepo,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(FileUploaderService) private readonly fileUploader: FileUploaderService,
        @Inject(FsService) private readonly fs: FsService
    ) {
        super()
        this.destruct.subscription(this.fileControls.valueChanges).subscribe((files: any[]) => {
            const hasEmpty = files.some(file => !file)
            if (hasEmpty) {
                return
            }
            this.fileControls.push(new FormControl())
        })
    }

    doAddHistory() {
        this.busy$.next(true)

        this.fileUploader
            .upload(`/order-history/${this.orderId}`)
            .pipe(
                filter(e => e.state === "done"),
                switchMap(() => {
                    const values = this.form.value
                    console.log(values)
                    return this.orderHistoryRepo
                        .add_message({
                            order_id: this.orderId,
                            message: values.message,
                            is_todo: values.is_todo,
                            file_ids: (values.files as any[])
                                .map(file => (file instanceof UploadedFile ? file.id : null))
                                .filter(v => !!v)
                        })
                        .pipe(this.toast.catchError(() => this.busy$.next(false)))
                })
            )
            .subscribe(() => this.done.emit())
    }

    doCancel() {
        this.cancelled.emit()
    }

    doRemoveFile(index: number) {}
}
