import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import { FormControl } from "@angular/forms"

import { defer, distinctUntilChanged, finalize, map, Observable, shareReplay, startWith, switchMap, tap } from "rxjs"

import { Destructible, FileDownloadService, LoadFields, ToastService } from "@anzar/core"

import { DeliveryStatus } from "@backend/__anzar_rpc_output"
import { PickupAddressTrait } from "@backend/carrier.api"
import { Order, OrderItem, OrderRepo, Shipping, ShippingItem, ShippingRepo, ShippingTracking } from "@backend/order.api"

import { AccountingService } from "../../accounting.module"
import { INVOICE_BADGE_FIELDS } from "../../accounting.module/invoice-badge.component"
import { BACKEND_BASE_URL, PartnerService } from "../../common.module"
import { STATUS_COLORS } from "../status"

export const SHIPPING_FIELDS: LoadFields<Shipping> = [
    "id",
    "invoice_id",
    "waybill_id",
    "supplier_id",
    "complaint_id",
    "price",
    "summary",
    "tracking_numbers",
    "label_count",
    "delivery_status",
    "label_id",
    "created_time",
    "return_reason",
    "cod_amount",
    "cod_currency",
    "has_shippable_items",
    { carrier: ["name"] },
    { invoice: INVOICE_BADGE_FIELDS },
    { waybill: INVOICE_BADGE_FIELDS },
    { items: ["id", "order_item_id", "serial"] }
]

const HAS_TRACKINGS_STATE = ["PROGRESS", "SUCCESS", "FAILURE"]
const HAS_WAYBILL_STATE = ["PREPARED", "PROGRESS", "SUCCESS", "FAILURE"]
const UNDELETABLE_LABEL_STATE = ["SUCCESS", "FAILURE"]
const COLLAPSED_STATE = __ENV__ === "production" ? ["PROGRESS", "SUCCESS", "FAILURE"] : ["SUCCESS", "FAILURE"]

const SUBMITTED_STATUS = DeliveryStatus.DATA.getSync("SUBMITTED")

type TrackingState = "PROGRESS" | "SUCCESS" | "FAILURE"
const TRACKING_STATE_REDUCED: { [key: string]: TrackingState } = {
    PREPARING: "PROGRESS",
    PROGRESS: "PROGRESS",
    DELIVERED: "SUCCESS",
    RETURNED: "FAILURE",
    DISPOSED: "FAILURE",
    CANCELLED: "FAILURE"
}
const TRACKING_STATE_COLOR: { [key: string]: string } = {
    PROGRESS: "info",
    SUCCESS: "confirm",
    FAILURE: "critical"
}

@Component({
    selector: ".eur-order-shipping",
    templateUrl: "./shipping.component.pug"
})
export class OrderShippingComponent extends Destructible implements OnChanges {
    @Input() public order: Order
    @Input() public shipping: Shipping
    @Input() public canShip: boolean
    @Input() public canMultiShip: boolean = null
    @Input() public canPrepare: boolean = true
    @Input() public canChangeDeliveryStatus: boolean = false

    @Output() public readonly changes = new EventEmitter<void>()
    @Output() public readonly busy = new EventEmitter<boolean>()

    public readonly labelCount = new FormControl(1)

    public readonly statusColors = STATUS_COLORS
    public isEmpty: boolean = false
    public allItemInShipping: boolean = false
    public orderItemsById: { [key: string]: OrderItem } = {}

    // public canMarkSuccess: boolean = false
    // public canMarkFailure: boolean = false
    public canSend: boolean = false
    public canWaybill: boolean = false
    public canInvoice: boolean = false
    public canUpdateLabelCount: boolean = false
    public canDeleteLabel: boolean = false
    // public hasTrackings: boolean = false

    public isCollapsed: boolean = false
    public isSubmitted: boolean = false

    public readonly trackings$ = defer(() =>
        this.changes.pipe(
            startWith(null),
            switchMap(() => this.shippingRepo.get_trackings({ shipping_id: this.shipping.id })),
            map(trackings => trackings.sort((a, b) => a.tracking_number.localeCompare(b.tracking_number))),
            shareReplay(1)
        )
    )

    public pickupAddress: Observable<PickupAddressTrait>

    public constructor(
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(ShippingRepo) private readonly shippingRepo: ShippingRepo,
        @Inject(AccountingService) private readonly accounting: AccountingService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {
        super()

        this.destruct
            .subscription(this.labelCount.valueChanges)
            .pipe(
                distinctUntilChanged(),
                tap(() => this.busy.next(true)),
                switchMap(lc => this.shippingRepo.set_label_count({ shipping_id: this.shipping.id, label_count: lc })),
                this.toast.catchError(() => this.busy.next(false))
            )
            .subscribe(() => {
                this.busy.next(false)
            })
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("order" in changes) {
            const order = changes.order.currentValue as Order
            order.shippings.sort((a, b) => a.created_time.getTime() - b.created_time.getTime())
            this.isEmpty = !order.shippings || order.shippings.length === 0

            let qty_need = 0
            for (const item of order.items) {
                this.orderItemsById[item.id] = item
                qty_need += item.qty_ordered - item.qty_cancelled
            }

            let qty_in_shipping = 0
            for (const shipping of order.shippings) {
                qty_in_shipping += shipping.items.length
            }
            this.allItemInShipping = qty_need === qty_in_shipping
        }

        if ("shipping" in changes) {
            const shipping: Shipping = changes.shipping.currentValue

            // if (shipping.delivery_status.value === "PROGRESS" && shipping.tracking_numbers?.length > 0) {
            //     this.canMarkSuccess = true
            //     this.canMarkFailure = true
            // } else {
            //     this.canMarkSuccess = false
            //     this.canMarkFailure = false
            // }

            this.isCollapsed = COLLAPSED_STATE.includes(shipping.delivery_status.value)

            this.isSubmitted = shipping.delivery_status.index >= SUBMITTED_STATUS.index

            // this.canSend = shipping.invoice
            //     && (!shipping.tracking_numbers || shipping.tracking_numbers.length === 0)
            //     && (shipping.invoice.type.value === 'INVOICE' || shipping.invoice.type.value === 'MODIFY')
            //     && shipping.has_shippable_items

            this.canSend =
                shipping.has_shippable_items &&
                !this.isSubmitted &&
                ((!shipping.complaint_id &&
                    shipping.invoice &&
                    ["INVOICE", "MODIFY"].includes(shipping.invoice.type.value)) ||
                    shipping.complaint_id !== null)

            // this.hasTrackings = HAS_TRACKINGS_STATE.includes(shipping.delivery_status.value)
            this.canWaybill =
                !shipping.waybill_id &&
                shipping.complaint_id &&
                HAS_WAYBILL_STATE.includes(shipping.delivery_status.value)
            this.canInvoice =
                !shipping.invoice_id &&
                !shipping.complaint_id &&
                !["PENDING", "FAILURE"].includes(shipping.delivery_status.value)

            this.labelCount.setValue(shipping.label_count, { emitEvent: false })
            this.canUpdateLabelCount = shipping.has_shippable_items && !this.isSubmitted
            this.canDeleteLabel = shipping.label_id && !UNDELETABLE_LABEL_STATE.includes(shipping.delivery_status.value)

            this.pickupAddress = this.partnerSvc.allPartners$.pipe(
                map(partners => partners.find(partner => partner.id === this.shipping.supplier_id)),
                map(supplier => this.partnerSvc.getTrait(supplier, "PickupAddressTrait")),
                shareReplay(1)
            )
        }
    }

    public doPrepareShipping(shipping: Shipping) {
        this._action(this.orderRepo.prepare_shipping({ shipping_id: shipping.id }), "Szállítás előkészítése")
    }

    public doSendShipping(shipping: Shipping) {
        this._action(this.orderRepo.send_shipping({ shipping_id: shipping.id }), "Szállítás beküldés")
    }

    public printLabel(shipping: Shipping) {
        this.downloader
            .download(`${this.baseUrl}/get/carrier-label.pdf?shipping_ids=${shipping.id}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", message: "Szállító címke letöltése" }))
            .subscribe()
    }

    public deleteLabel(shipping: Shipping) {
        this._action(this.shippingRepo.delete_labels({ shipping_id: shipping.id }), "Szállítócímke törlése")
    }

    public createWaybill(shipping: Shipping) {
        this._action(this.shippingRepo.waybill({ shipping_id: shipping.id }), "Szállítólevél kiállítása")
    }

    public doMarkSuccess(tracking: ShippingTracking) {
        this._action(
            this.shippingRepo.set_tracking_status({
                shipping_id: tracking.shipping_id,
                tracking_number: tracking.tracking_number,
                status: "DELIVERED"
            }),
            // this.orderRepo.update_delivery_status({ shipping_id: this.shipping.id, status: "SUCCESS" }),
            "Szállítás sikeresre állítása"
        )
    }

    public doMarkFailure(tracking: ShippingTracking) {
        this._action(
            this.shippingRepo.set_tracking_status({
                shipping_id: tracking.shipping_id,
                tracking_number: tracking.tracking_number,
                status: "RETURNED"
            }),
            "Szállítás siertelenre állítása"
        )
    }

    private _action<T extends Observable<any>>(observable: T, msg: string) {
        this.busy.next(true)
        observable
            .pipe(
                finalize(() => {
                    this.busy.next(false)
                }),
                this.toast.handleSave({ align: "bottom center", beginMsg: msg })
            )
            .subscribe(() => {
                this.changes.next()
            })
    }

    public isTrackingStatusChangeable(tracking: ShippingTracking): boolean {
        // Ha csak virtuális elemek vannak, akkor bármikor módosítható
        return (
            !this.shipping.has_shippable_items ||
            (tracking.status.value !== "DELIVERED" && tracking.status.value !== "RETURNED")
        )
    }

    public trackingState(tracking: ShippingTracking) {
        return TRACKING_STATE_REDUCED[tracking.status.value] || "PROGRESS"
    }

    public trackingColor(tracking: ShippingTracking) {
        return TRACKING_STATE_COLOR[this.trackingState(tracking)] || "common"
    }

    public readonly itemSerialControl = new FormControl()
    public isEditSerialIdx: number = NaN

    public startSetItemSerial(index: number, shippingItem: ShippingItem) {
        this.itemSerialControl.setValue(shippingItem.serial)
        this.isEditSerialIdx = index
    }

    public applySetItemSerial(shippingItem: ShippingItem) {
        const serial = this.itemSerialControl.value
        shippingItem.serial = serial
        this.itemSerialControl.reset()
        this.isEditSerialIdx = NaN
        this._action(
            this.shippingRepo.set_serial_number({ shipping_item_id: shippingItem.id, serial }),
            "Sorozatszám mentése"
        )
    }

    public cancelSetItemSerial() {
        this.itemSerialControl.reset()
        this.isEditSerialIdx = NaN
    }
}
