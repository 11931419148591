import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"
import { FsModule } from "@pyzar/fs.module"

import { ProductApi } from "@backend/product.api"

import { EuropeerCommonModule } from "../common.module"
import { ManufacturerUploaderService, ManufacturerUploaderWndComponent } from "./manufacturer-uploader.component"
import { ManufacturersComponent } from "./manufacturers.component"
import { ManufacturersScreeen } from "./manufacturers.screen"

export const ROUTES: Route[] = [{ path: "manufacturers", component: ManufacturersScreeen }]

@NgModule({
    imports: [CommonModule, NzModule, EuropeerCommonModule, PyzarCommonModule, FsModule, ProductApi],
    declarations: [ManufacturersScreeen, ManufacturersComponent, ManufacturerUploaderWndComponent],
    exports: [ManufacturersComponent],
    providers: [ManufacturerUploaderService],
    entryComponents: [ManufacturerUploaderWndComponent]
})
export class ManufacturerModule {}
