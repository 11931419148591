import { ChangeDetectorRef, Component, Inject, Injectable } from "@angular/core"
import { FormControl } from "@angular/forms"

import { Observable } from "rxjs"
import { filter, switchMap } from "rxjs/operators"

import { DropdownLayer, LayerRef, LayerService, ToastService, UploadedFile } from "@anzar/core"

import { FileUploaderService } from "@pyzar/fs.module"

import { Partner, PartnerRepo } from "@backend/partner.api"
import { FileBackend } from "@backend/pyzar.api"

import { PartnerService } from "../../common.module"

@Injectable()
export class ManualImportService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {}

    public show(target: HTMLElement, partner: Partner) {
        return new Observable(subscriber => {
            const behavior = new DropdownLayer({
                backdrop: { type: "filled", hideOnClick: false },
                closeable: false,
                menuLike: true,
                elevation: 10,
                rounded: 3,
                position: {
                    align: "top center",
                    anchor: {
                        ref: target,
                        align: "bottom center"
                    }
                },
                maxWidth: target.offsetWidth,
                minWidth: target.offsetWidth
            })

            const ref = this.layerSvc.createFromComponent(ManualImportWndComponent, behavior, null, [
                { provide: Partner, useValue: partner }
            ])

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    subscriber.complete()
                } else if (event.type === "import-done") {
                    subscriber.next(true)
                }
            })

            ref.show()

            return () => {
                ref.hide()
            }
        })
    }
}

@Component({
    selector: "eur-manula-category-import-wnd",
    templateUrl: "./manual-import.component.pug",
    providers: [FileUploaderService]
})
export class ManualImportWndComponent {
    public readonly file = new FormControl()

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.detectChanges()
        }
    }
    public get isBusy(): boolean {
        return this._isBusy
    }
    private _isBusy: boolean = false

    public readonly isWebshippy = this.PartnerService.hasTrait(this.partner, "WebshippyCategoryTrait")

    public constructor(
        @Inject(Partner) public readonly partner: Partner,
        @Inject(PartnerService) public readonly PartnerService: PartnerService,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FileUploaderService) private readonly fsUploader: FileUploaderService,
        @Inject(PartnerRepo) private readonly partnerRepo: PartnerRepo,
        @Inject(ToastService) private readonly toastService: ToastService,
        @Inject(FileBackend) private readonly fileBackend: FileBackend
    ) {}

    public cancel() {
        this.layerRef.hide()
    }

    public doImport() {
        this.isBusy = true

        this.fsUploader
            .upload(`/partner/${this.partner.id}`)
            .pipe(
                filter(e => e.state === "done"),
                switchMap(event => {
                    const data = this.file.value as UploadedFile

                    return this.partnerRepo.import_categories_from_file({
                        partner_id: this.partner.id,
                        file_id: data.id
                    })
                }),
                this.toastService.handleSave({ align: "bottom center", beginMsg: "Kategóriák importálása..." })
            )
            .subscribe(event => {
                this.layerRef.emit({ type: "import-done" })
                this.layerRef.hide()
            })
    }
}
