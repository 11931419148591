import { ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core"
import { FormControl } from "@angular/forms"

import { NEVER, Subject } from "rxjs"
import { catchError, filter, finalize, switchMap } from "rxjs/operators"

import { ProgressEvent, ToastService } from "@anzar/core"

import { FileUploaderService } from "@pyzar/fs.module"

import { AccountingRepo } from "@backend/accounting.api"
import { UploadPaymentsTrait } from "@backend/accounting.api"
import { Partner } from "@backend/partner.api"

import { PartnerService } from "../../common.module"
import { UploadResultService } from "./upload-result.component"

@Component({
    selector: ".eur-payment-upload-block",
    templateUrl: "./upload-block.component.pug",
    providers: [FileUploaderService, UploadResultService]
})
export class UploadBlockComponent implements OnChanges {
    @Input() public partner: Partner

    public readonly file = new FormControl()
    public readonly payoutDate = new FormControl(new Date())
    public showDateInput = false

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.detectChanges()
        }
    }
    public get isBusy(): boolean {
        return this._isBusy
    }
    private _isBusy: boolean = false

    public constructor(
        @Inject(FileUploaderService) private readonly fileUploader: FileUploaderService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(AccountingRepo) private readonly accountingRepo: AccountingRepo,
        @Inject(UploadResultService) private readonly upr: UploadResultService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ("partner" in changes) {
            const partner: Partner = changes.partner.currentValue
            const trait = this.partnerSvc.getTrait(partner, UploadPaymentsTrait)
            this.showDateInput = trait.manual_payout_date
        }
    }

    public doUpload() {
        this.isBusy = true

        const progress = new Subject<ProgressEvent>()
        this.toast.progress({
            align: "bottom center",
            progress: progress
        })

        progress.next({ message: "Fájl feltöltése" })

        this.fileUploader
            .upload("/payments")
            .pipe(
                filter(event => event.state === "done"),
                switchMap(event => {
                    progress.next({ message: "Adatok importálása" })
                    return this.accountingRepo.import_payments({
                        partner_id: this.partner.id,
                        file_id: this.file.value.id,
                        payout_date: this.showDateInput ? this.payoutDate.value : null
                    })
                }),
                catchError((error: any) => {
                    progress.complete()
                    this.toast.error(`${error}`)
                    this.isBusy = false
                    this.file.reset()
                    return NEVER
                }),
                finalize(() => {
                    progress.complete()
                    this.isBusy = false
                    this.file.reset()
                })
            )
            .subscribe(result => {
                this.upr.show(result)
                this.file.reset()
            })
    }
}
