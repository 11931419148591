import { ChangeDetectorRef, Component, Directive, Inject, Optional } from "@angular/core"
import { FormControl } from "@angular/forms"

import { LayerEvent, LayerRef, ToastService } from "@anzar/core"

import { OrderCancellationReason } from "@backend/enum.api"
import { Order, OrderRepo, Shipping } from "@backend/order.api"

@Directive()
export abstract class CancelBaseComponent {
    public readonly cancellationReason = new FormControl()

    public readonly reasons = OrderCancellationReason.DATA

    public abstract readonly cancelStatus: string

    public set busy(val: boolean) {
        if (this._busy !== val) {
            this._busy = val
            this.cdr.detectChanges()
        }
    }
    public get busy(): boolean {
        return this._busy
    }
    private _busy: boolean

    public constructor(
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(OrderRepo) protected readonly orderRepo: OrderRepo,
        @Inject(Order) @Optional() protected readonly order: Order,
        @Inject(Shipping) @Optional() protected readonly shipping: Shipping,
        @Inject(ToastService) protected readonly toast: ToastService,
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef
    ) {}

    public abstract cancelOrder(): void

    public cancel() {
        this.layerRef.hide()
    }
}

@Component({
    selector: "eur-order-cancel-wnd",
    templateUrl: "./cancel.component.pug"
})
export class CancelComponent extends CancelBaseComponent {
    public readonly cancelStatus: string = "CANCELLED"

    public cancelOrder() {
        this.busy = true
        this.orderRepo
            .do_cancel({ id: this.order.id, reason: this.cancellationReason.value })
            .pipe(
                this.toast.catchError(() => {
                    this.busy = false
                })
            )
            .subscribe(_ => {
                this.layerRef.emit(new LayerEvent("operation", "cancelled"))
                this.layerRef.hide()
            })
    }
}
