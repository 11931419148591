import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, ViewChild } from "@angular/core"

import { Observable } from "rxjs"
import { map, shareReplay, switchMap, take } from "rxjs/operators"

import {
    DataSourceDirective,
    Destructible,
    DynStackDirective,
    LayerRef,
    LayerService,
    ModalLayer,
    ToastService
} from "@anzar/core"

import { Order, OrderRepo } from "@backend/order.api"

import { ComplaintProducts } from "../complaint/complaint-products.service"
import { ORDER_FIELDS } from "./order-details.component"
import { OrderDetailsService } from "./order-details.service"

@Injectable()
export class OrderDetailsWndService {
    public constructor(
        @Inject(DataSourceDirective) private readonly dataSource: DataSourceDirective,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(ToastService) private readonly toast: ToastService
    ) {}

    public show(orderId: number): Observable<any> {
        return this.orderRepo
            .get({ id: orderId }, { loadFields: ORDER_FIELDS })
            .pipe(take(1), this.toast.preload(), switchMap(this._show.bind(this)))
    }

    private _show(order: Order): Observable<number> {
        return new Observable(subscriber => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: true },
                closeable: true,
                rounded: 3,
                elevation: 15,
                trapFocus: true,
                position: {
                    align: {
                        vertical: "top",
                        horizontal: "center"
                    },
                    constraint: {
                        ref: "viewport",
                        inset: "32 16 32 16"
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(OrderDetailsWndComponent, behavior, null, [
                { provide: Order, useValue: order },
                { provide: DataSourceDirective, useValue: this.dataSource }
            ])

            ref.show()
            ref.subscribe(event => {
                if (event.type === "change") {
                    subscriber.next(event.data.id as number)
                } else if (event.type === "hiding") {
                    subscriber.complete()
                }
            })

            return () => {
                if (ref) {
                    ref.hide()
                }
            }
        })
    }
}

@Component({
    selector: "eur-order-details-wnd",
    templateUrl: "./order-details-wnd.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ComplaintProducts, OrderDetailsService]
})
export class OrderDetailsWndComponent extends Destructible {
    @ViewChild("dynStack") public readonly dynStack: DynStackDirective<Order>

    public set inAnimation(val: boolean) {
        if (this._inAnimation !== val) {
            this._inAnimation = val
            this.cdr.detectChanges()
        }
    }
    public get inAnimation(): boolean {
        return this._inAnimation
    }
    public _inAnimation: boolean = true

    public set busy(val: boolean) {
        if (this._busy !== val) {
            this._busy = val
            this.cdr.detectChanges()
        }
    }
    public get busy(): boolean {
        return this._busy
    }
    public _busy: boolean = false

    private pager = this.svc.pager$
    public readonly hasPrev = this.pager.pipe(
        map(pager => !!pager.prevId),
        shareReplay(1)
    )
    public readonly hasNext = this.pager.pipe(
        map(pager => !!pager.nextId),
        shareReplay(1)
    )

    public constructor(
        @Inject(Order) public readonly order: Order,
        @Inject(OrderRepo) public readonly orderRepo: OrderRepo,
        @Inject(LayerRef) public readonly layerRef: LayerRef,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(OrderDetailsService) public readonly svc: OrderDetailsService
    ) {
        super()
        svc.orderFields.next(ORDER_FIELDS)
        svc.orderId.next(order.id)

        layerRef.subscribe(event => {
            if (event.type === "shown") {
                this.dynStack.next(order)
                this.inAnimation = false
            } else if (event.type === "showing" || event.type === "hiding") {
                this.inAnimation = true
            }
        })

        this.destruct.subscription(svc.navigation).subscribe(nav => {
            if (nav.direction === "next") {
                this.dynStack.next(nav.order)
            } else {
                this.dynStack.prev(nav.order)
            }
        })
    }

    public onOrderChanges(orderId: number) {
        this.layerRef.emit({ type: "change", data: { id: orderId } })
    }

    public goNext() {
        this.busy = true
        this.svc.nextOrder().subscribe(() => {
            this.busy = false
        })
    }

    public goPrev() {
        this.busy = true
        this.svc.prevOrder().subscribe(() => {
            this.busy = false
        })
    }
}
