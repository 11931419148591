import { Component, Inject, ViewChild } from "@angular/core"

import { FileDownloadService, ToastService } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"

import { BACKEND_BASE_URL } from "../common.module"
import { ProductCreateWndService } from "./product-create-wnd.component"
import { ProductGridComponent } from "./product-grid.component"

// import { ProductUploaderService } from "./product-uploader.component"

@Component({
    templateUrl: "./product.screen.pug",
    providers: [ProductCreateWndService]
})
export class ProductScreen {
    @ViewChild("grid", { read: ProductGridComponent }) public readonly grid: ProductGridComponent

    public readonly canManageProducts$ = this.authSvc.hasPermission("product.manage")

    public constructor(
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        // @Inject(ProductUploaderService) private readonly uploader: ProductUploaderService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(ProductCreateWndService) private readonly createProd: ProductCreateWndService
    ) {}

    // public doUploadProducts(event: Event) {
    //     this.uploader
    //         .show(event.target as HTMLElement)
    //         .subscribe(() => {
    //             this.grid.reload()
    //         })
    // }

    public doDownloadProducts() {
        const source = this.grid.source
        const filter = JSON.stringify(source.filter)
        const sorter = JSON.stringify(source.sort)
        this.downloader
            .download(`${this.baseUrl}/get/products?filter=${encodeURI(filter)}&sorter=${encodeURI(sorter)}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", message: "Termékek letöltése folyamatban" }))
            .subscribe()
    }

    public doCreateProduct() {
        this.createProd.show().subscribe(supplierProduct => {
            if (supplierProduct) {
                this.grid.source.filter = { id: supplierProduct.product_id } as any
            }
        })
    }
}
