import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import { SafeHtml } from "@angular/platform-browser"

import { parseISO } from "date-fns"

import { FileDownloadService, LoadFields, LocaleService, ToastService } from "@anzar/core"

import { RenderJsonService } from "@pyzar/common.module"
import { FsService } from "@pyzar/fs.module"

import { Order, OrderHistory, OrderItem } from "@backend/order.api"
import { File } from "@backend/pyzar.api"

export const ORDER_HISTORY_FIELDS: LoadFields<OrderHistory> = [
    "id",
    "created_time",
    "type",
    "changes",
    "message",
    "is_todo",
    "resolved",
    { creator: ["name"] },
    { files: ["id", "title"] }
]

interface RelevantChanges {
    type: "status" | "invoice" | "message" | "error" | "change" | "shipping" | "customer_cancel" | "complaint"
    message?: string | SafeHtml
    changes?: [any, any]
    params?: { [key: string]: any }
}

type Changes<T> = { [P in keyof T]: [T[P] | null, T[P] | null] }

export interface HistoryResolveEvent {
    id: number
    resolved: boolean
}

@Component({
    selector: ".eur-order-history-item",
    templateUrl: "./history-item.component.pug",
    host: {
        "[attr.todo]": "history.is_todo ? '' : null",
        "[attr.resolved]": "history.resolved ? '' : null"
    }
})
export class OrderHistoryItemComponent implements OnChanges {
    @Input() public history: OrderHistory
    @Output() public resolve = new EventEmitter<HistoryResolveEvent>()

    public relevantChanges: RelevantChanges[] = []

    public constructor(
        @Inject(RenderJsonService) public readonly renderJson: RenderJsonService,
        @Inject(LocaleService) private readonly localeSvc: LocaleService,
        @Inject(FileDownloadService) private readonly fileDownloader: FileDownloadService,
        @Inject(FsService) private readonly fs: FsService,
        @Inject(ToastService) private readonly toast: ToastService
    ) {}

    public ngOnChanges(changes: SimpleChanges) {
        if ("history" in changes) {
            this.relevantChanges = this.computeRelevantChanges(changes.history.currentValue)
        }
    }

    public computeRelevantChanges(history: OrderHistory): RelevantChanges[] {
        let result: RelevantChanges[] = []

        if (history.type.value === "CREATE") {
            return result
        } else if (history.type.value === "ERROR") {
            return [
                {
                    type: "error",
                    message: history.message
                }
            ]
        } else if (history.type.value === "INVOICE") {
            result = [
                {
                    type: "invoice",
                    changes: history.changes,
                    params: {
                        id: history.changes.invoice.id,
                        type: history.changes.type,
                        serno: history.changes.invoice.serno,
                        outdated: history.changes.invoice.outdated
                    }
                }
            ]
            if (history.message) {
                result.unshift({ type: "message", message: history.message })
            }
            return result
        } else if (history.type.value === "SHIPPING") {
            const result: RelevantChanges = {
                type: "shipping",
                message: history.message
            }

            if (history.changes?.shipping) {
                result.changes = {
                    tracking_numbers: history.changes.shipping.tracking_numbers,
                    deleted_numbers: history.changes.shipping.deleted_numbers
                } as any
            }
            return [result]
        } else if (history.type.value === "COMPLAINT") {
            const complaint_chng = history.changes.complaint
            if (complaint_chng._removed) {
                const complaint = complaint_chng._removed
                return [
                    {
                        type: "complaint",
                        params: {
                            serno: complaint.serno,
                            type: complaint.type.label,
                            action: "Törölve"
                        }
                    }
                ]
            } else {
                const oldSerno = complaint_chng.serno[0]
                const changes = [{ type: "complaint", params: {} as any }]

                if (oldSerno) {
                    changes[0].params = {
                        serno: oldSerno,
                        type: complaint_chng.type[1].label,
                        action: "Módosítva"
                    }
                    // message = `Reklamáció <b>#${oldSerno} — ${complaint_chng.type[1].label}</b> — Módosítva`

                    for (const k of Object.keys(complaint_chng)) {
                        const v = complaint_chng[k]
                        switch (k) {
                            case "is_accounted":
                                changes[0].params["action"] = "Jóváírva"
                                continue

                            case "is_refunded":
                                changes[0].params["action"] = "Visszatérítve"
                                continue

                            case "is_shipped":
                                changes[0].params["action"] = "Szállítva"
                                continue

                            case "is_closed":
                                changes[0].params["action"] = "Lezárva"
                                continue
                        }

                        if (!["serno", "type", "pending_todo"].includes(k)) {
                            let changeMsg: string
                            if (k === "status") {
                                changeMsg = `${complaintFieldLabel(k)}: ${enumAsString(v[0])} 🠚 ${enumAsString(v[1])}`
                            } else {
                                changeMsg = `${complaintFieldLabel(k)}: ${valAsString(v[0])} 🠚 ${valAsString(v[1])}`
                            }
                            changes.push({ type: "change", message: changeMsg } as any)
                        }
                    }
                } else {
                    changes[0].params = {
                        serno: complaint_chng.serno[1],
                        type: complaint_chng.type[1].label,
                        action: "Létrehozva"
                    }
                }
                return changes as any
            }
        }

        if (history.message) {
            result.push({ type: "message", message: history.message })
        }

        if (history.changes) {
            const changes: Changes<Order> = history.changes as any

            if (changes.status) {
                result.push({ type: "status", changes: changes.status })
            }

            if (changes.payment_status) {
                result.push({
                    type: "change",
                    message: `Fizetési állapot: ${changes.payment_status[0].label} 🠚 ${changes.payment_status[1].label}`
                })
            }

            if (changes.cancellation_request) {
                const date = parseISO(changes.cancellation_request[1] as any)
                result.push({
                    type: "customer_cancel",
                    message: `Vásárlói elállás kezdeményezése: ${this.localeSvc.formatDate(date, "short+time-short")}`
                })
            }

            if (changes.cancellation_reason) {
                let whatReason: string = "Megszakítás"
                if (changes.status && changes.status[1] && changes.status[1].value === "RETURNED") {
                    whatReason = "Visszavétel"
                }

                result.push({
                    type: "change",
                    // eslint-disable-next-line max-len
                    message: `${whatReason} oka ${enumAsString(changes.cancellation_reason[0])} 🠚 ${enumAsString(changes.cancellation_reason[1])}`
                })
            }

            if (changes.items) {
                const items = changes.items as any as { [key: string]: Changes<OrderItem> }
                result = result.concat(
                    Object.keys(items)
                        .map(k => [Number(k), items[k as any]] as const)
                        .map(([line, item]) => computeLineChanges(line, item))
                        .reduce((entries, cv) => cv.concat(entries), [])
                )
            }
        }

        return result
    }

    public markResolved(resolved: boolean) {
        this.resolve.emit({ id: this.history.id, resolved })
        this.history.resolved = resolved
    }

    public doDownloadFile(file: File) {
        this.fileDownloader
            .download(this.fs.getDownloadUrl(file))
            .pipe(this.toast.handleFileDownload({ align: "bottom center" }))
            .subscribe()
    }
}

function computeLineChanges(line: number, changes: Changes<OrderItem>): RelevantChanges[] {
    const result: RelevantChanges[] = []

    if (changes.qty_ordered) {
        result.push(basicChangeMessage(`${line}. tétel megrendelve: `, changes.qty_ordered))
    }

    if (changes.qty_received) {
        result.push(basicChangeMessage(`${line}. tétel megérkezett: `, changes.qty_received))
    }

    if (changes.qty_cancelled) {
        result.push(basicChangeMessage(`${line}. tétel lemondva: `, changes.qty_cancelled))
    }

    if (changes.qty_refunded) {
        result.push(basicChangeMessage(`${line}. tétel visszatérítve: `, changes.qty_refunded))
    }

    if (changes.qty_shipped) {
        result.push(basicChangeMessage(`${line}. tétel szállításban: `, changes.qty_shipped))
    }

    if (changes.qty_invoiced) {
        result.push(basicChangeMessage(`${line}. tétel számlázva: `, changes.qty_invoiced))
    }

    return result
}

function basicChangeMessage(message: string, changes: [any, any]): RelevantChanges {
    return {
        type: "change",
        message: `${message} ${valAsString(changes[0])} 🠚 ${valAsString(changes[1])}`
    }
}

function valAsString(val: any): string {
    if (!val) {
        return "∅"
    } else {
        return val
    }
}

function enumAsString(val: any): string {
    if (!val) {
        return "∅"
    } else {
        return val.label
    }
}

function complaintFieldLabel(name: string): string {
    switch (name) {
        case "status":
            return "Állapot"

        case "other":
            return "Egyéb ok"

        case "complaint_date":
            return "Dátum"
    }
    return name
}
