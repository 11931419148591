import { Component, Directive, Inject, InjectionToken, OnInit } from "@angular/core"
import { FormControl, Validators } from "@angular/forms"

import { BehaviorSubject } from "rxjs"

import { LayerRef, ToastService } from "@anzar/core"

import { Price } from "@backend/__anzar_rpc_output"
import { OrderRepo } from "@backend/order.api"
import { SupplierOrderRepo } from "@backend/supplier.sorder.api"

export const PARAMS = new InjectionToken("PARAMS")
export type ItemActionTypes = "update-qty" | "update-supplier"

@Directive({})
export abstract class ItemActionComponent<P> {
    readonly busy = new BehaviorSubject(false)

    public constructor(
        @Inject(LayerRef) public readonly layerRef: LayerRef,
        @Inject(ToastService) protected readonly toast: ToastService,
        @Inject(OrderRepo) protected readonly orderRepo: OrderRepo,
        @Inject(SupplierOrderRepo) protected readonly sorderRepo: SupplierOrderRepo,
        @Inject(PARAMS) public readonly params: P
    ) {}

    doClose() {
        this.layerRef.hide()
    }
}

export type ItemUpdateQtyParams = { qty: number } & (
    | { type: "ordered"; orderItemId: number; orderId: number }
    | { type: "cancelled"; orderItemId: number; orderId: number }
    | { type: "received"; soItemId: number }
)

@Component({
    selector: "eur-order-item-update-qty",
    templateUrl: "./update-qty.component.pug"
})
export class ItemUpdateQtyComponent extends ItemActionComponent<ItemUpdateQtyParams> implements OnInit {
    readonly qtyInput = new FormControl(0, [Validators.required])
    label: string

    ngOnInit(): void {
        switch (this.params.type) {
            case "cancelled":
                this.label = "Lemondva"
                break

            case "ordered":
                this.label = "Megrendelt"
                break

            case "received":
                this.label = "Megérkezett"
                break
        }

        this.qtyInput.setValue(this.params.qty)
    }

    doSave() {
        this.busy.next(true)
        this._doSave()
            .pipe(this.toast.catchError(() => this.busy.next(false)))
            .subscribe(res => {
                this.layerRef.emit({ type: "done", data: res })
                this.layerRef.hide()
            })
    }

    _doSave() {
        switch (this.params.type) {
            case "cancelled":
                return this.orderRepo.update_order_item({
                    order_id: this.params.orderId,
                    order_item_id: this.params.orderItemId,
                    data: { qty_cancelled: this.qtyInput.value }
                })

            case "ordered":
                return this.orderRepo.update_order_item({
                    order_id: this.params.orderId,
                    order_item_id: this.params.orderItemId,
                    data: { qty_ordered: this.qtyInput.value }
                })

            case "received":
                return this.sorderRepo.update_received({
                    supplier_order_item_id: this.params.soItemId,
                    qty: this.qtyInput.value
                })
        }
    }

    doCancelOrderItem() {
        this.qtyInput.setValue(0)
        return this.doSave()
    }
}

export interface ItemChangeSupplierParams {
    type: "preorder" | "backorder" | "change-ordered"
    multi: boolean
    supplierIds: number[]
    orderId: number
    orderItemId: number
    productId: number
    conversionRate: number
    itemUnitPrice: Price
}

@Component({
    selector: "eur-order-item-change-supplier",
    templateUrl: "./change-supplier.component.pug"
})
export class ItemChangeSupplierComponent extends ItemActionComponent<ItemChangeSupplierParams> implements OnInit {
    readonly selectedSupplierIds = new FormControl()

    label: string

    ngOnInit(): void {
        this.selectedSupplierIds.setValue(this.params.supplierIds)
        switch (this.params.type) {
            case "preorder":
                this.label = "Előrendelés"
                return

            case "backorder":
                this.label = "Utánrendelés"
                return

            case "change-ordered":
                this.label = "Forgalmazó cseréje"
                return
        }
    }

    doSave() {
        this.busy.next(true)
        this._doSave()
            .pipe(this.toast.catchError(() => this.busy.next(false)))
            .subscribe(res => {
                this.layerRef.emit({ type: "done", data: res })
                this.layerRef.hide()
            })
    }

    _doSave() {
        switch (this.params.type) {
            case "preorder":
                return this.orderRepo.update_order_item({
                    order_id: this.params.orderId,
                    order_item_id: this.params.orderItemId,
                    data: { supplier_ids: this.selectedSupplierIds.value }
                })
            case "backorder":
                return this.orderRepo.update_order_item({
                    order_id: this.params.orderId,
                    order_item_id: this.params.orderItemId,
                    data: { supplier_ids: this.selectedSupplierIds.value }
                })
        }
    }
}

export interface ItemSupplyErrorParams {
    message: string
}

@Component({
    selector: "eur-order-item-supply-error",
    templateUrl: "./supply-error.component.pug"
})
export class ItemSupplyErrorComponent extends ItemActionComponent<ItemSupplyErrorParams> {}
