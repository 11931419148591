import { Component, Inject, ViewChild } from "@angular/core"

import { FileDownloadService, ToastService } from "@anzar/core"

import { BACKEND_BASE_URL } from "../../common.module"
import { ProductDiscountGridComponent } from "./product-discount-grid.component"
import { ProductDiscountUploaderService } from "./product-discount-uploader.component"

@Component({
    selector: "eur-product-discount-screen",
    templateUrl: "./product-discount-screen.component.pug",
    providers: [ProductDiscountUploaderService]
})
export class ProductDiscountScreenComponent {
    @ViewChild("grid", { static: false, read: ProductDiscountGridComponent })
    public readonly grid!: ProductDiscountGridComponent

    constructor(
        @Inject(ProductDiscountUploaderService) private readonly importer: ProductDiscountUploaderService,
        @Inject(FileDownloadService) private readonly fileDownloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {}

    doDownloadExcel(event: Event, partnerId: number) {
        this.fileDownloader
            .download(`${this.baseUrl}/get/merchant/${partnerId}/discount.xsls`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", message: "Akciók excel generálása" }))
            .subscribe()
    }

    doImportExcel(event: Event, partnerId: number) {
        this.importer.show(event.target as any, partnerId).subscribe(event => {
            if (event) {
                this.grid.reload()
            }
        })
    }
}
