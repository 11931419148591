import { NgModule } from "@angular/core"
import { Category, CategoryProduct, CategoryMargin, ExternalCategory, CategoryMap, CategoryRepo, ExternalCategoryRepo, CategoryMapRepo, CategoryRepoSource, ExternalCategoryRepoSource } from "./__anzar_rpc_output"

export { Category, CategoryProduct, CategoryMargin, ExternalCategory, CategoryMap, CategoryRepo, ExternalCategoryRepo, CategoryMapRepo, CategoryRepoSource, ExternalCategoryRepoSource }


@NgModule({
    providers: [
        CategoryRepoSource,
        ExternalCategoryRepoSource,
        CategoryMapRepo,
        CategoryRepo,
        ExternalCategoryRepo
    ],
})
export class CategoryApi {}