import { Component, Inject } from "@angular/core"

import { map } from "rxjs"

import { TaskService } from "@pyzar/task.module"

import { PartnerService } from "../common.module"

interface TaskDef {
    title: string
    name: string
    params: Record<string, any>
}

@Component({
    selector: ".eur-bgtask-list",
    templateUrl: "./bgtask-list.component.pug"
})
export class BgTaskListComponent {
    readonly suppliers$ = this.partnerSvc.suppliers$

    readonly tasks$ = this.suppliers$.pipe(
        map(suppliers =>
            suppliers
                .filter(s => s.is_active && this.partnerSvc.hasTrait(s, "SupplierProductTrait"))
                .map(
                    supplier =>
                        ({
                            title: `Termékek importálása ${supplier.name}`,
                            name: "supplier-import-full",
                            params: { supplier: supplier.internal_id }
                        }) as TaskDef
                )
        )
    )

    constructor(
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(TaskService) private readonly taskSvc: TaskService
    ) {}

    doRun(taskDef: TaskDef) {
        this.taskSvc.start(taskDef.name, taskDef.params).subscribe()
    }
}
