import { Component, ElementRef, Inject } from "@angular/core"

import { BehaviorSubject, combineLatest, forkJoin, map, Observable, of, shareReplay, switchMap } from "rxjs"

import { FocusGroup, LoadFields, LocaleService, ToastService } from "@anzar/core"

import { ComplaintItemHistory, ComplaintItemHistoryRepo, ComplaintItemStatus, ShippingItem } from "@backend/order.api"
import { PartnerRepoSource } from "@backend/partner.api"

import { ComplaintEntryComponent } from "./_abstract"
import { ComplaintItemForm, ComplaintService } from "./complaint.service"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DeepDiff = require("deep-diff")

const HISTORY_FIELDS: LoadFields<ComplaintItemHistory> = ["status_new", "created_time", { creator: ["name"] }]

const STATUS_COLORS = {
    ARRIVED: "confirm",
    NOT_ARRIVED: "critical",
    REFUNDABLE: "confirm",
    EXCHANGE: "accent",
    REPAIRED: "dark-info",
    REJECTED: "critical",
    COMPENSATION: "accent"
}

@Component({
    selector: ".eur-complaint-item",
    templateUrl: "./complaint-item.component.pug",
    host: {
        "[attr.tabindex]": "-1"
    },
    providers: [FocusGroup]
})
export class ComplaintItemComponent extends ComplaintEntryComponent<ComplaintItemForm> {
    public readonly statusColor = STATUS_COLORS as { [key: string]: any }

    private readonly reloadHistory = new BehaviorSubject<void>(null)

    public readonly history = combineLatest({ id: this.id$, reload: this.reloadHistory }).pipe(
        switchMap(({ id }) => {
            if (!id) {
                return of([] as ComplaintItemHistory[])
            }
            return this.historyRepo.search(
                { filter: { complaint_item_id: id }, order: { created_time: "asc" } },
                { loadFields: HISTORY_FIELDS }
            )
        }),
        shareReplay(1)
    )

    public readonly isArrived = this.history.pipe(
        map(history => !!history.find(v => v.status_new.value === "ARRIVED")),
        shareReplay(1)
    )

    public readonly isExchange = this.history.pipe(
        map(history => !!history.find(v => v.status_new.value === "EXCHANGE")),
        shareReplay(1)
    )

    public readonly hasCompensation = this.history.pipe(
        map(history => !!history.find(v => v.status_new.value === "COMPENSATION")),
        shareReplay(1)
    )

    public readonly isSyncSerial = this.values$.pipe(
        map(values => !!values.sync_serial),
        shareReplay(1)
    )

    public readonly selectedSItem = new BehaviorSubject<ShippingItem>(null)

    public dynamicFields = combineLatest({
        shipped_serial: combineLatest({
            no_serial: this.selectedSItem.pipe(map(v => v && !v.serial)),
            sync_serial: this.isSyncSerial
        }).pipe(map(({ no_serial, sync_serial }) => no_serial || sync_serial)),
        exchange_serial: this.isExchange,
        arrived_there: this.isArrived,
        credit_invoiceno: this.svc.complaintBasic.pipe(map(complaint => complaint.is_accounted))
    }).pipe(
        map(v => {
            if (v.shipped_serial || v.exchange_serial || v.arrived_there || v.credit_invoiceno) {
                return v
            } else {
                return null
            }
        }),
        shareReplay(1)
    )

    public readonly nextStatuses = combineLatest({ complaint: this.svc.complaintBasic, history: this.history }).pipe(
        map(({ complaint, history }) => {
            if (!history || history.length === 0) {
                return []
            }

            const complaintType = complaint.type.value
            const itemStatus = history[history.length - 1].status_new.value
            return this._possibleStatuses(complaintType, itemStatus).map(v => ComplaintItemStatus.DATA.getSync(v))
        }),
        shareReplay(1)
    )

    protected get debounce(): { [key: string]: number } | number {
        return {
            shipped_serial: 1000,
            exchange_serial: 1000,
            arrived_there_rma: 1000,
            credit_invoiceno: 1000
        }
    }

    public constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(ComplaintService) public readonly svc: ComplaintService,
        @Inject(FocusGroup) focusGroup: FocusGroup,
        @Inject(LocaleService) private localeSvc: LocaleService,
        @Inject(ComplaintItemHistoryRepo) private readonly historyRepo: ComplaintItemHistoryRepo,
        @Inject(PartnerRepoSource) public readonly partnerSrc: PartnerRepoSource,
        @Inject(ToastService) private readonly toast: ToastService
    ) {
        super()

        // ! kell, e nélkül nem megy
        this.destruct.subscription(this.id$).subscribe()
        this.destruct.subscription(this.dynamicFields).subscribe()

        this.destruct.subscription(focusGroup.changes).subscribe(focus => {
            if (focus.curr) {
                this.el.nativeElement.setAttribute("focused", focus.curr)
            } else {
                this.el.nativeElement.removeAttribute("focused")
            }
        })

        focusGroup.watch(el.nativeElement)

        // let first = true
        // this.destruct.subscription(this.values$).pipe(
        //     exhaustMap(values => {
        //         if (first) {
        //             first = false
        //             if (!values.id) {
        //                 return this.svc.saveItem(this.index, values)
        //             }
        //         } else {
        //             return forkJoin([
        //                 this.svc.saveItem(this.index, values),
        //                 of(null).pipe(map(() => this.reloadHistory.next()))
        //             ])
        //         }
        //         return of(null)
        //     }),
        // ).subscribe()
    }

    protected saveEntry(values: ComplaintItemForm): Observable<any> {
        if (!values.shipping_item_id) {
            return of(null)
        }

        const isNew = values.id == null

        return forkJoin([
            this.svc.saveItem(this.index, values),
            of(null).pipe(map(() => !isNew && this.reloadHistory.next()))
        ])
    }

    public onProductSelect(products: ShippingItem[]): void {
        this.selectedSItem.next(products[0])
    }

    public doRemove() {
        this.svc.delItem(this.index)
    }

    public doSetStatus(status: string) {
        this.form.get("status").setValue(status)
    }

    public doRefreshSerials() {
        this.toast.info("Fjlesztés alatt: Több informáci szükséges", { align: "bottom center", autohide: 1000 })
    }

    private _possibleStatuses(complaintType: string, status: string): string[] {
        const current = ComplaintItemStatus.DATA.getSync(status)
        const possibilities = current.next_status

        if (!possibilities) {
            return []
        }

        let next = possibilities[complaintType]
        if (next) {
            return next
        }

        next = possibilities["*"]
        if (next) {
            return next
        }

        return []
    }

    public getHistoryQtip(history: ComplaintItemHistory): string {
        return (
            history.status_new.label +
            "\n" +
            this.localeSvc.formatDate(history.created_time, "short+time") +
            " — " +
            history.creator.name.formatted
        )
    }
}
