import { Inject, Injectable } from "@angular/core"

import { Observable } from "rxjs"

import { LayerService, ModalLayer } from "@anzar/core"

import { Shipping } from "@backend/order.api"

import { SHIPPING, ShippingTrackingsComponent } from "./shipping-trackings.component"

@Injectable()
export class ShippingService {
    public constructor(@Inject(LayerService) private layerSvc: LayerService) {}

    public showTracking(shipping: Shipping) {
        return new Observable(subsciber => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: true },
                rounded: 3,
                elevation: 20,
                position: {
                    align: "top center",
                    constraint: {
                        ref: "viewport",
                        inset: "88 16 88 16"
                    }
                }
            })

            const layer = this.layerSvc.createFromComponent(ShippingTrackingsComponent, behavior, null, [
                { provide: SHIPPING, useValue: shipping }
            ])

            const s = layer.subscribe(event => {
                if (event.type === "changed") {
                    subsciber.next(true)
                } else if (event.type === "destroy") {
                    subsciber.complete()
                }
            })

            layer.show()
            return () => {
                layer.hide()
                s.unsubscribe()
            }
        })
    }
}
