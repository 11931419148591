import { Component, ContentChild, TemplateRef, ChangeDetectorRef, Inject, Input, ViewChild, ElementRef, AfterViewInit, Output } from "@angular/core"
import { BehaviorSubject, switchMap, of, distinctUntilChanged, shareReplay, map, tap, startWith } from "rxjs"

import { RectMutationService, Destructible } from "@anzar/core"

@Component({
    selector: ".pz-expandable-row",
    host: {
        "[attr.collapsed]": "collapsed ? '' : null",
        "[attr.expanded]": "!collapsed ? '' : null"
    },
    templateUrl: "./expandable-row.component.pug"
})
export class ExpandableRowComponent extends Destructible implements AfterViewInit {
    @ContentChild("content", { static: true, read: TemplateRef }) public readonly contentTpl: TemplateRef<any>
    @ContentChild("expanded", { static: true, read: TemplateRef }) public readonly expandedTpl: TemplateRef<any>
    @ViewChild("expandableEl", { static: true, read: ElementRef }) public readonly expandableEl: ElementRef<HTMLDivElement>
    @ViewChild("expandedEl", { static: true, read: ElementRef }) public readonly expandedEl: ElementRef<HTMLDivElement>

    @Input()
    public set collapsed(val: boolean) {
        if (this.collapsed$.value !== val) {
            this.collapsed$.next(val)
            this.cdr.detectChanges()
        }
    }
    public get collapsed(): boolean { return this.collapsed$.value }

    @Output("collapsed")
    public readonly collapsed$ = new BehaviorSubject(true)

    public readonly contentHeight$ = this.collapsed$.pipe(
        switchMap(collapsed => {
            if (collapsed) {
                return of(0)
            } else {
                return this.mutation.watchScrollDimension(this.expandedEl.nativeElement)
                    .pipe(map(dim => dim.height + 1))
            }
        }),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(RectMutationService) private readonly mutation: RectMutationService) {
        super()
    }

    public ngAfterViewInit(): void {
        this.destruct.subscription(this.contentHeight$).subscribe(height => {
            if (this.expandableEl) {
                this.expandableEl.nativeElement.style.height = `${height}px`
            }
        })
    }

    public toggle(event: Event) {
        if (event.defaultPrevented) {
            return
        }
        this.collapsed = !this.collapsed
    }
}
