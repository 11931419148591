import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"

import { OrderStatus } from "@backend/enum.api"

import { STATUS_COLORS } from "./status"

@Component({
    selector: ".eur-order-badge",
    host: {
        "[attr.color]": "color"
    },
    templateUrl: "./order-badge.component.pug"
})
export class OrderBadgeComponent implements OnChanges {
    @Input() public status: OrderStatus

    public color: string

    public ngOnChanges(changes: SimpleChanges) {
        if ("status" in changes) {
            this.color = STATUS_COLORS[changes.status.currentValue?.value] || STATUS_COLORS.other
        }
    }
}
