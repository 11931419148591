import { Attribute, Component, Inject } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import { map, Observable, of, shareReplay } from "rxjs"

import { Currency } from "@backend/__anzar_rpc_output"

import { customerFormModel } from "../customer-form.component"
import { OrderWizardService, StepHandler } from "./order-wizard.service"

@Component({
    selector: "eur-order-wizard-step-basics",
    templateUrl: "./step-basics.component.pug"
})
export class OrderWizardStepBasicsComponent implements StepHandler {
    readonly form = new FormGroup({
        billing_info: customerFormModel(true),
        shipping_info: customerFormModel(false),
        currency: new FormControl(null, [Validators.required]),
        created_time: new FormControl(new Date())
    })

    readonly stepIndex: number
    public readonly currencySrc = Currency.DATA

    readonly merchantId$ = this.svc.merchant$.pipe(
        map(merchant => merchant?.id),
        shareReplay(1)
    )

    constructor(
        @Inject(OrderWizardService) public readonly svc: OrderWizardService,
        @Attribute("stepIndex") stepIndex: string
    ) {
        this.stepIndex = Number(stepIndex)
        svc.setStepHandler(this)
    }

    // public save(orderId: number | null, form: FormGroup): Observable<number> {
    //     return this.orderRepo.save_wizard({ step: this.stepIndex + 1, data: form.value, order_id: orderId })
    // }

    public dataForSave(form: FormGroup): Observable<object> {
        return of(form.value)
    }

    public doCopyBillingInfo() {
        this.form.get("shipping_info").patchValue(this.form.get("billing_info").value)
    }

    public doCopyShippingInfo() {
        this.form.get("billing_info").patchValue(this.form.get("shipping_info").value)
    }
}
