import { Component, Inject, InjectionToken } from "@angular/core"

import { BehaviorSubject, map, shareReplay, switchMap } from "rxjs"

import { LayerRef, StaticSource } from "@anzar/core"

import { PartnerService } from "../common.module"
// import { PartnerType } from "src/common.module"
import { PartnerEntityIdField } from "./sheet/products.component"

export const PARTNER_ID = new InjectionToken("PARTNER_ID")

const SUPPLIER_PRODUCT_FIELDS = new StaticSource(PartnerEntityIdField, [
    { name: "manufacturer_sku", label: "Gyártói cikkszám" },
    { name: "source_sku", label: "Forgalmazói cikkszám 1" },
    { name: "source_sku2", label: "Forgalmazói cikkszám 2" }
])

const MERCHANT_PRODUCT_FIELDS = new StaticSource(PartnerEntityIdField, [
    { name: "partner_entity_id", label: "Partner azonosító mező" }
])

@Component({
    selector: "eur-partner-sheet",
    templateUrl: "./partner-sheet.component.pug"
})
export class PartnerSheetComponent {
    public readonly supplierPartnerEntityIdSrc = SUPPLIER_PRODUCT_FIELDS
    public readonly merchantPartnerEntityIdSrc = MERCHANT_PRODUCT_FIELDS

    public readonly partnerId$ = new BehaviorSubject<number>(null)

    public readonly partner$ = this.partnerId$.pipe(
        switchMap(partnerId => this.partnerSvc.get(partnerId)),
        shareReplay(1)
    )

    public readonly haveMerchantProduct$ = this.partner$.pipe(
        map(
            partner =>
                this.partnerSvc.hasTrait(partner, "MerchantProductTrait") &&
                !this.partnerSvc.hasTrait(partner, "MerchantJointProductTrait")
        ),
        shareReplay(1)
    )

    public readonly haveAdvertProduct$ = this.partner$.pipe(
        map(partner => this.partnerSvc.hasTrait(partner, "AdvertProductTrait")),
        shareReplay(1)
    )

    public readonly haveCarriers$ = this.partner$.pipe(
        map(partner => this.partnerSvc.hasTrait(partner, "SupplierOrderTrait")),
        shareReplay(1)
    )

    public name: string

    public constructor(
        @Inject(PARTNER_ID) partnerId: number,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(PartnerService) private partnerSvc: PartnerService
    ) {
        this.partnerId$.next(partnerId)
    }

    public close() {
        this.layerRef.hide()
    }
}
