import { NgModule } from "@angular/core"
import { SupplierOrderTrait, SupplierOrderPossibilities, SupplierOrderRepo, SupplierOrderRepoSource } from "./__anzar_rpc_output"

export { SupplierOrderTrait, SupplierOrderPossibilities, SupplierOrderRepo, SupplierOrderRepoSource }


@NgModule({
    providers: [
        SupplierOrderRepoSource,
        SupplierOrderRepo
    ],
})
export class SupplierSorderApi {}