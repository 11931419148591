import { NgModule } from "@angular/core"
import { EmagApi, EmagCarrierTrait, EmagMirrorCategoryTrait, EmagVatRate, EmagEan, EmagLocality, EmagOrderSyncTrait, EmagLocalityRepo, EmagLocalityRepoSource } from "./__anzar_rpc_output"

export { EmagApi, EmagCarrierTrait, EmagMirrorCategoryTrait, EmagVatRate, EmagEan, EmagLocality, EmagOrderSyncTrait, EmagLocalityRepo, EmagLocalityRepoSource }


@NgModule({
    providers: [
        EmagLocalityRepoSource,
        EmagLocalityRepo
    ],
})
export class MerchantEmagApi {}