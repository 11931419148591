import { NgModule } from "@angular/core"
import { Manufacturer, Product, Stock, ProductImageQueue, ProductImageData, ManufacturerRepo, ProductRepo, StockRepo, ManufacturerRepoSource, ProductRepoSource, StockRepoSource } from "./__anzar_rpc_output"

export { Manufacturer, Product, Stock, ProductImageQueue, ProductImageData, ManufacturerRepo, ProductRepo, StockRepo, ManufacturerRepoSource, ProductRepoSource, StockRepoSource }


@NgModule({
    providers: [
        ManufacturerRepoSource,
        ProductRepoSource,
        StockRepoSource,
        ManufacturerRepo,
        ProductRepo,
        StockRepo
    ],
})
export class ProductApi {}