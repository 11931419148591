import { Component, Inject } from "@angular/core"

import { PartnerService } from "../../common.module"

@Component({
    selector: ".eur-payments-upload",
    templateUrl: "./payments-upload.component.pug"
})
export class PaymentsUploadComponent {
    public readonly partners$ = this.partnerSvc.partnersByTrait("UploadPaymentsTrait")

    public constructor(@Inject(PartnerService) private readonly partnerSvc: PartnerService) {}
}
