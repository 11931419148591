import { Routes } from "@angular/router"

import { AuthorizedOnly, PROTECTED_ROUTES, PUBLIC_ROUTES } from "@pyzar/auth.module"
import { NotFoundPageComponent } from "@pyzar/common.module"

import { ROUTES as ACCOUNTING_ROUTES } from "./accounting.module"
import { ROUTES as BGTASK_ROUTES } from "./bgtask.module"
import { ROUTES as CATEGORY_ROUTES } from "./category.module"
import { ROUTES as DASHBOARD_ROUTES } from "./dashboard.module"
import { ROUTES as MANUFACTURER_ROUTES } from "./manufacturer.module"
import { ROUTES as ORDER_ROUTES } from "./order.module"
import { ROUTES as PARTNER_ROUTES } from "./partner.module"
import { ROUTES as PRODUCT_ERROR_ROUTES } from "./product-error.module"
import { ROUTES as PRODUCT_ROUTES } from "./product.module"
import { ViewportComponent } from "./viewport/viewport.component"

export const ROUTES: Routes = [
    ...PUBLIC_ROUTES,
    {
        path: "",
        canActivate: [AuthorizedOnly],
        canActivateChild: [AuthorizedOnly],
        component: ViewportComponent,
        children: [
            ...PROTECTED_ROUTES,
            ...DASHBOARD_ROUTES,
            ...CATEGORY_ROUTES,
            ...PRODUCT_ROUTES,
            ...PRODUCT_ERROR_ROUTES,
            ...ORDER_ROUTES,
            ...ACCOUNTING_ROUTES,
            ...PARTNER_ROUTES,
            ...MANUFACTURER_ROUTES,
            ...BGTASK_ROUTES
        ]
    },
    {
        path: "**",
        component: NotFoundPageComponent
    }
]
