import { Component, Inject } from "@angular/core"

import { DataSourceDirective } from "@anzar/core"

import { SimilarProduct } from "@backend/supplier.api"

import { SimilarProductState } from "./similar-product.component"

@Component({
    selector: ".eur-similar-products",
    templateUrl: "./similar-products.component.pug"
})
export class SimilarProductsComponent {
    public readonly states: SimilarProductState[] = []

    public constructor(@Inject(DataSourceDirective) public readonly source: DataSourceDirective) {}

    public getState(idx: number, similar: SimilarProduct) {
        if (this.states[idx] != null && this.states[idx].similar.id === similar.id) {
            return this.states[idx]
        } else {
            return (this.states[idx] = new SimilarProductState(similar))
        }
    }

    public reload() {
        this.source.reload()
    }
}
