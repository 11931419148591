import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"

import { Shipping } from "@backend/order.api"

const STATUS_COLORS = {
    PREPARED: "blue",
    PENDING: "common",
    PROGRESS: "info",
    SUBMITTED: "info",
    OUT_FOR_DELIVERY: "info",
    IN_TRANSIT: "info",
    SUCCESS: "confirm",
    FAILURE: "critical",
    CANCELLED: "warn"
}

@Component({
    selector: ".eur-shipping-badge",
    host: {
        "[attr.color]": "color"
    },
    templateUrl: "./shipping-badge.component.pug"
})
export class ShippingBadgeComponent implements OnChanges {
    @Input() shipping: Shipping
    public color: string

    public ngOnChanges(changes: SimpleChanges) {
        if ("shipping" in changes) {
            this.color = (STATUS_COLORS as any)[changes.shipping.currentValue?.delivery_status?.value] || "common"
        }
    }
}
