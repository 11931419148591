import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { AppModule } from "./app.module"

// if (__ENV__ === "production") {
enableProdMode()
// }

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err))
