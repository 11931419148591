import { Injectable } from "@angular/core"

import { ButtonOption, DefaultTaskDetailsComponent, TaskDetailsFactory } from "@pyzar/task.module"

@Injectable({ providedIn: "root" })
export class InvoiceAndShippingTaskDetailsFactory extends TaskDetailsFactory {
    public readonly name = "order-invoice-shipping"
    public readonly title = "Számlázás & Szállítás"
    public readonly component = DefaultTaskDetailsComponent
    public readonly buttons: ButtonOption[] = [
        { label: "ADATOK LETÖLTÉSE", role: "download_pacakge", color: "confirm", variant: "filled" }
    ]
}
