import { NgModule } from "@angular/core"
import { AdvertProduct, AdvertProductRepo, AdvertProductRepoSource } from "./__anzar_rpc_output"

export { AdvertProduct, AdvertProductRepo, AdvertProductRepoSource }


@NgModule({
    providers: [
        AdvertProductRepoSource,
        AdvertProductRepo
    ],
})
export class AdvertApi {}