import { Component, Inject, Input } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import {
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    ReplaySubject,
    shareReplay,
    Subscription,
    switchMap,
    tap
} from "rxjs"

import { LoadFields } from "@anzar/core"

import { OrderItem } from "@backend/order.api"
import { Product, ProductRepo, ProductRepoSource } from "@backend/product.api"

import { PartnerService, PriceInputComponent } from "../common.module"
import { ProductSelectorWndService } from "../product.module/product-selector-wnd.component"

const PRODUCT_FULL_FIELDS: LoadFields<Product> = [
    "id",
    "name",
    "sku",
    "eans",
    { stocks: ["partner_id", "available", "wslp", { partner: ["id", "name", "sequence"] }] }
]

export function productFormModel(item?: OrderItem) {
    return new FormGroup({
        action: new FormControl(item && item.id ? "UPDATE" : "CREATE"),
        ref: new FormControl(item && item.id ? { id: item.id } : null),
        product_id: new FormControl(item?.product_id, [Validators.required]),
        name: new FormControl(item?.name),
        sku: new FormControl(item?.sku),
        qty_ordered: new FormControl(item?.qty_ordered, [Validators.required]),
        unit_price: PriceInputComponent.formModel(item?.unit_price, "Egységár megadása kötelező"),
        // TODO: preorder
        supplier_ids: new FormControl(item?.supplier_ids || []),
        supply_type: new FormControl(item?.supply_type.value)
    })
}

@Component({
    selector: "eur-order-product-form",
    templateUrl: "./product-form.component.pug",
    providers: [ProductSelectorWndService]
})
export class OrderProductFormComponent {
    @Input()
    public set form(val: FormGroup) {
        if (this._form !== val) {
            this._form = val
            const productIdCtrl = this._form.get("product_id")
            this._sub?.unsubscribe()
            this._sub = productIdCtrl.valueChanges.subscribe(this.productId$)
            this.productId$.next(productIdCtrl.value)
        }
    }
    public get form(): FormGroup {
        return this._form
    }
    private _form: FormGroup
    private _sub: Subscription

    readonly productFields: LoadFields<Product> = ["id", "name"]

    readonly productId$ = new ReplaySubject<number>()

    // readonly selectedProduct$ = new ReplaySubject<Product>()

    readonly product$ = this.productId$.pipe(
        filter(productId => !!productId),
        distinctUntilChanged(),
        switchMap(productId => this.productRepo.get({ id: productId }, { loadFields: PRODUCT_FULL_FIELDS })),
        tap(c => console.log(c)),
        shareReplay(1)
    )

    readonly stocks$ = combineLatest({ product: this.product$, partners: this.partnerSvc.allPartners$ }).pipe(
        map(({ product, partners }) =>
            product.stocks.filter(stock => {
                const partner = partners.find(p => p.id === stock.partner_id)
                return partner && partner.is_active && stock.wslp
            })
        ),
        map(stocks =>
            stocks.sort((a, b) => {
                if (a.wslp && b.wslp) {
                    return a.wslp.net - b.wslp.net
                }
            })
        ),
        shareReplay(1)
    )

    public constructor(
        @Inject(ProductRepo) public readonly productRepo: ProductRepo,
        @Inject(ProductRepoSource) public readonly productSrc: ProductRepoSource,
        @Inject(PartnerService) private partnerSvc: PartnerService,
        @Inject(ProductSelectorWndService) private productSelector: ProductSelectorWndService
    ) {}

    calcMargin(stock_net: number, net: number): number {
        return Math.round(((net - stock_net) / net) * 100.0)
    }

    doSelectProduct() {
        this.productSelector
            .show([this.form.get("product_id").value], "single")
            .subscribe(selectedIds => this.form.get("product_id").setValue(selectedIds[0]))
    }
}
