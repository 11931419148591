import { PortalModule } from "@angular/cdk/portal"
import { CommonModule, registerLocaleData } from "@angular/common"
import { HttpClientXsrfModule } from "@angular/common/http"
import localeHu from "@angular/common/locales/hu"
import { LOCALE_ID, NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { RouterModule } from "@angular/router"

import dateFnsLocaleHU from "date-fns/locale/hu"

import { DEFAULT_ERROR_MESSAGES, LOCALE_DATE, NzModule } from "@anzar/core"
import { HTTPClient, HTTPTransport, RpcInterceptor } from "@anzar/rpc"

import { AuthModule } from "@pyzar/auth.module"
import { PyzarCommonModule } from "@pyzar/common.module"
import {
    FILE_DOWNLOAD_ENDPOINT,
    FILE_IMAGE_ENDPOINT,
    FILE_INLINE_ENDPOINT,
    FILE_UPLOAD_ENDPOINT,
    FsModule
} from "@pyzar/fs.module"

import { AccountingModule } from "./accounting.module"
import { AppComponent } from "./app.component"
import { BgTaskModule } from "./bgtask.module"
import { CategoryModule } from "./category.module"
import { BACKEND_BASE_URL, EuropeerCommonModule, RpcErrorHandler } from "./common.module"
import { DashboardModule } from "./dashboard.module"
import { ManufacturerModule } from "./manufacturer.module"
import { OrderModule } from "./order.module"
import { PartnerModule } from "./partner.module"
import { ProductErrorModule } from "./product-error.module"
import { ProductModule } from "./product.module"
import { ROUTES } from "./routes"
import { ShippingModule } from "./shipping.module"
import { ViewportComponent } from "./viewport/viewport.component"

const loc = window.location
const _BACKEND_BASE = __DEBUG__
    ? `${DEBUG_BACKEND_BASE}`
    : `${loc.protocol}//${loc.hostname}${loc.port?.length ? ":" + loc.port : ""}`

registerLocaleData(localeHu, "hu")

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, {
            enableTracing: false,
            onSameUrlNavigation: "reload",
            relativeLinkResolution: "corrected",
            useHash: false
        }),
        HttpClientXsrfModule.withOptions({
            cookieName: "Asampy-Xsrf-Token",
            headerName: "Asampy-Xsrf-Token"
        }),
        PortalModule,
        NzModule.forRoot(),
        AuthModule,
        PyzarCommonModule,
        EuropeerCommonModule,
        CategoryModule,
        ProductModule,
        ProductErrorModule,
        OrderModule,
        PartnerModule,
        AccountingModule,
        FsModule,
        DashboardModule,
        ShippingModule,
        ManufacturerModule,
        BgTaskModule
    ],
    declarations: [AppComponent, ViewportComponent],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: "hu" },
        HTTPTransport,
        HTTPClient,
        {
            provide: HTTPTransport.ENDPOINT,
            useValue: `${_BACKEND_BASE}/rpc`
        },
        {
            provide: BACKEND_BASE_URL,
            useValue: _BACKEND_BASE
        },
        {
            provide: FILE_UPLOAD_ENDPOINT,
            useValue: `${_BACKEND_BASE}/fs/upload`
        },
        {
            provide: FILE_DOWNLOAD_ENDPOINT,
            useValue: `${_BACKEND_BASE}/fs/download`
        },
        {
            provide: FILE_IMAGE_ENDPOINT,
            useValue: `${_BACKEND_BASE}/fs/image`
        },
        {
            provide: FILE_INLINE_ENDPOINT,
            useValue: `${_BACKEND_BASE}/fs/inline`
        },
        { provide: RpcInterceptor, useClass: RpcErrorHandler, multi: true },
        { provide: LOCALE_DATE, useValue: dateFnsLocaleHU },
        {
            provide: DEFAULT_ERROR_MESSAGES,
            useValue: {
                required: "A mező kitöltése kötelező",
                invalidDatetime: "Hibás időpont"
            }
        }
    ],
    entryComponents: []
})
export class AppModule {}
