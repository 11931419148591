import { Component, Inject, Injectable, InjectionToken, ViewChild } from "@angular/core"

import { Observable } from "rxjs"

import {
    LayerRef,
    LayerService,
    ModalLayer,
    MultiSelection,
    SelectionModel,
    SelectOrigin,
    SingleSelection
} from "@anzar/core"

import { Product } from "@backend/product.api"

import { ProductCreateWndService } from "./product-create-wnd.component"
import type { ProductGridComponent } from "./product-grid.component"

export const SELECTED_IDS = new InjectionToken<number[]>("SELECTED_IDS")

@Injectable()
export class ProductSelectorWndService {
    constructor(@Inject(LayerService) private layerSvc: LayerService) {}

    show(selectedIds: number[], selection: "single" | "multi"): Observable<number[]> {
        return new Observable(observer => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: false },
                elevation: 15,
                rounded: 3,
                trapFocus: true,
                position: {
                    align: "stretch",
                    constraint: {
                        ref: "viewport",
                        inset: 32
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(ProductSelectorWndComponent, behavior, null, [
                { provide: SELECTED_IDS, useValue: selectedIds },
                {
                    provide: SelectionModel,
                    useValue: selection === "single" ? new SingleSelection() : new MultiSelection()
                }
            ])

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    observer.complete()
                } else if (event.type === "select") {
                    observer.next(event.data)
                }
            })

            ref.show()

            return () => {
                ref?.hide()
            }
        })
    }
}

@Component({
    selector: "eur-product-selector-wnd",
    templateUrl: "./product-selector-wnd.component.pug",
    providers: [ProductCreateWndService]
})
export class ProductSelectorWndComponent {
    @ViewChild("grid", { static: true }) public readonly grid: ProductGridComponent
    public readonly selection

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ProductCreateWndService) private createProduct: ProductCreateWndService,
        @Inject(SELECTED_IDS) selectedIds: number[],
        @Inject(SelectionModel) selection?: SelectionModel<Product>
    ) {
        if (!selection) {
            this.selection = new MultiSelection<Product>()
        } else {
            this.selection = selection
        }

        const selected: { [key: string]: SelectOrigin } = {}
        for (const id of selectedIds) {
            if (id != null) {
                selected[String(id)] = "program"
            }
        }
        this.selection.update(selected)
    }

    public doCancel() {
        this.layerRef.hide()
    }

    public doSelect() {
        const data = this.selection.items.map(item => Number(item.id))
        this.layerRef.emit({ type: "select", data })
        this.layerRef.hide()
    }

    public isSlectionFiltered(): boolean {
        const filter = this.grid.source.filter as any
        return !!filter.selected
    }

    public doFilterSelected() {
        const productIds = this.selection.items.map(v => Number(v.id))
        this.grid.source.filter = { ...this.grid.source.filter, selected: productIds } as any
    }

    public doClearFilter() {
        const filter = this.grid.source.filter as any
        delete filter.selected
        this.grid.source.filter = filter
    }

    public doCreateProduct() {
        this.createProduct.show().subscribe(supplierProduct => {
            if (supplierProduct) {
                this.grid.source.filter = { id: supplierProduct.product_id } as any
                this.selection.update({ [String(supplierProduct.product_id)]: "program" })
            }
        })
    }
}
