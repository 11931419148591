import { Component, Inject, Injectable } from "@angular/core"

import { Observable } from "rxjs"

import { LayerRef, LayerService, LoadFields, ModalLayer, Rect } from "@anzar/core"

import { Product, ProductRepo } from "@backend/product.api"

export const PRODUCT_FIELDS: LoadFields<Product> = [
    "id",
    "name",
    "sku",
    "eans",
    "kind",
    { manufacturer: ["id", "name"] },
    { categories: ["id", "full_name"] }
]

@Injectable({ providedIn: "root" })
export class ProductSheetService {
    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(ProductRepo) private readonly productRepo: ProductRepo
    ) {}

    public show(productId: number): Observable<any> {
        return new Observable(() => {
            const vp = Rect.viewport()
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: true },
                closeable: true,
                rounded: 5,
                elevation: 10,
                position: {
                    align: {
                        vertical: "top",
                        horizontal: "center"
                    },
                    constraint: {
                        ref: "viewport",
                        inset: "32 16 36 16"
                    }
                }
            })

            let ref: LayerRef

            this.productRepo.get({ id: productId }, { loadFields: PRODUCT_FIELDS }).subscribe(product => {
                ref = this.layerSvc.createFromComponent(ProductSheetComponent, behavior, null, [
                    { provide: Product, useValue: product }
                ])

                ref.show()
            })

            return () => {
                ref.hide()
            }
        })
    }
}

@Component({
    selector: "eur-product-sheet",
    templateUrl: "./product-sheet.component.pug",
    host: {
        "[style.width.px]": "900"
    }
})
export class ProductSheetComponent {
    public constructor(
        @Inject(Product) public readonly product: Product,
        @Inject(LayerRef) public readonly layerRef: LayerRef
    ) {}

    public close() {
        this.layerRef.close()
    }
}
