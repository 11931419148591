import { Component, Inject, Input } from "@angular/core"
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"

import { BehaviorSubject, shareReplay, switchMap } from "rxjs"

import { ToastService } from "@anzar/core"

import {
    Country,
    DeliveryType,
    PartnerRepoSource,
    ShippingMethod,
    ShippingMethodRepo
} from "@backend/__anzar_rpc_output"

@Component({
    selector: ".eur-partner-carriers",
    templateUrl: "./carriers.component.pug"
})
export class PartnerCarriersComponent {
    @Input() set dstPartnerId(value: number) {
        this.dstPartnerId$.next(value)
    }
    get dstPartnerId() {
        return this.dstPartnerId$.value
    }
    readonly dstPartnerId$ = new BehaviorSubject<number>(0)

    readonly deliveryTypeSrc = DeliveryType.DATA
    readonly countrySrc = Country.DATA

    readonly form = new FormGroup({
        carriers: new FormArray([])
    })

    get carriersArray() {
        return this.form.get("carriers") as FormArray
    }

    readonly shippingMethods$ = this.dstPartnerId$.pipe(
        switchMap(dstPartnerId => this.shippingMethodRepo.search({ filter: { partner_id: dstPartnerId } })),
        shareReplay({ bufferSize: 1, refCount: true })
    )

    constructor(
        @Inject(PartnerRepoSource) public readonly partnerSrc: PartnerRepoSource,
        @Inject(ShippingMethodRepo) public readonly shippingMethodRepo: ShippingMethodRepo,
        @Inject(ToastService) private readonly toast: ToastService
    ) {
        this.shippingMethods$.subscribe(shippingMethods => {
            this.carriersArray.clear()
            for (const sp of shippingMethods) {
                this.carriersArray.push(this._carrierModel(sp))
            }
        })
    }

    private _carrierModel(shippingMethod?: ShippingMethod) {
        return new FormGroup({
            id: new FormControl(shippingMethod?.id),
            is_active: new FormControl(shippingMethod ? shippingMethod.is_active : true),
            description: new FormControl(shippingMethod?.description, [Validators.required]),
            handle: new FormControl(shippingMethod?.handle, [Validators.required]),
            carrier_id: new FormControl(shippingMethod?.carrier_id, [Validators.required]),
            delivery_type: new FormControl(shippingMethod?.delivery_type, [Validators.required]),
            countries: new FormControl(shippingMethod?.countries)
        })
    }

    doAddNew() {
        this.carriersArray.push(this._carrierModel())
    }

    doRemove(index: number) {
        this.carriersArray.removeAt(index)
    }

    doSave() {
        this.shippingMethodRepo
            .save_partner_shipping_methods({
                partner_id: this.dstPartnerId,
                methods: this.carriersArray.value
            })
            .pipe(this.toast.catchError())
            .subscribe(() => {
                this.dstPartnerId$.next(this.dstPartnerId)
            })
    }
}
