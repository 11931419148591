import { Component, Inject, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"

import { BehaviorSubject, debounceTime } from "rxjs"

import { Destructible, LoadFields, ToastService } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"
import { TaskService } from "@pyzar/task.module"

import { SimilarProduct, SimilarProductRepo, SimilarProductRepoSource } from "@backend/supplier.api"

import type { SimilarProductsComponent } from "./similar-products.component"

const FIELDS: LoadFields<SimilarProduct> = [
    "id",
    {
        similars: [
            {
                supplier_product: [
                    "id",
                    "product_id",
                    "partner_entity_id",
                    "name",
                    "manufacturer_sku",
                    "source_sku",
                    "source_sku2",
                    "eans",
                    { partner: ["name", "sequence"] }
                ]
            },
            { ignore: ["fields", "totally"] }
        ]
        // products: [
        //     "id", "partner_entity_id", "name", "manufacturer_sku", "source_sku", "source_sku2", "eans",
        //     { partner: ["name", "sequence"] }
        // ]
    }
]

@Component({
    selector: "eur-similar-products-screen",
    templateUrl: "./similar-products.screen.pug"
})
export class SimilarProductsScreen extends Destructible {
    @ViewChild("products", { static: true })
    public readonly productsComponent: SimilarProductsComponent

    public readonly canManageProducts$ = this.authSvc.hasPermission("product.manage")
    public readonly filter$ = new BehaviorSubject(null)
    public readonly fields = FIELDS
    public readonly filterInput = new FormControl()

    public constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(SimilarProductRepo) public readonly repo: SimilarProductRepo,
        @Inject(SimilarProductRepoSource) public readonly source: SimilarProductRepoSource,
        @Inject(TaskService) private readonly taskSvc: TaskService
    ) {
        super()

        this.destruct
            .subscription(this.filterInput.valueChanges)
            .pipe(debounceTime(500))
            .subscribe(filter => {
                this.filter$.next({ text: filter })
            })
    }

    public doRebuild() {
        this.taskSvc.start("similar-rebuild").subscribe(event => {
            if (event.type === "success") {
                this.reload()
            }
        })
    }

    public reload() {
        this.productsComponent.reload()
    }

    public doResetIgnores() {}
}
