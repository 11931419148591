import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"

import { take } from "rxjs/operators"

import { LoadFields, TreeComponent } from "@anzar/core"

import { ExternalCategory, ExternalCategoryRepo, ExternalCategoryRepoSource } from "@backend/category.api"

const EXT_CAT_FIELDS: LoadFields<ExternalCategory> = [
    "id",
    "parent_id",
    "name",
    "is_active",
    "is_allowed",
    "is_leaf",
    { mapped_categories: ["id"] }
]

@Component({
    selector: ".eur-external-category-tree",
    templateUrl: "./external-category-tree.component.pug",
    exportAs: "eurExternalCategoryTree"
})
export class ExternalCategoryTreeComponent implements OnChanges {
    @ViewChild("tree", { read: TreeComponent, static: true }) public readonly treeCmp: TreeComponent

    @Input() public partnerId: number

    public root: ExternalCategory
    public readonly fields = EXT_CAT_FIELDS

    @Output()
    public readonly selection = new EventEmitter<ExternalCategory[]>()

    public constructor(
        @Inject(ExternalCategoryRepoSource) public readonly categorySrc: ExternalCategoryRepoSource,
        @Inject(ExternalCategoryRepo) public readonly categoryRepo: ExternalCategoryRepo,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef
    ) {}

    public ngOnChanges(changes: SimpleChanges) {
        if ("partnerId" in changes) {
            this.reload()
        }
    }

    public reload() {
        this.categoryRepo
            .search({ filter: { partner_id: this.partnerId, partner_entity_id: "__ROOT__" }, count: 1 })
            .pipe(take(1))
            .subscribe(root => {
                this.root = root[0]
                this.cdr.detectChanges()
            })
    }
}
