import { Component, Inject, InjectionToken } from "@angular/core"

import { BehaviorSubject, map, Observable } from "rxjs"
import { shareReplay, switchMap } from "rxjs/operators"

import { LayerRef } from "@anzar/core"

import { OrderRepo, Shipping, ShippingRepo, ShippingTracking } from "@backend/order.api"

export const SHIPPING = new InjectionToken<number>("SHIPPING")

@Component({
    selector: "eur-shipping-trackings-wnd",
    templateUrl: "./shipping-trackings.component.pug"
})
export class ShippingTrackingsComponent {
    public readonly shipping$ = new BehaviorSubject<Shipping>(null)
    public readonly trackings$: Observable<ShippingTracking[]> = this.shipping$.pipe(
        switchMap(shipping => {
            if (shipping) {
                return this.shippingRepo.get_trackings({ shipping_id: shipping.id })
            } else {
                return []
            }
        }),
        shareReplay(1)
    )

    public readonly canSuccess$ = this.shipping$.pipe(
        map(shipping => shipping.delivery_status.value === "PROGRESS"),
        shareReplay(1)
    )

    public readonly canFailure$ = this.shipping$.pipe(
        map(shipping => shipping.delivery_status.value === "PROGRESS"),
        shareReplay(1)
    )

    public constructor(
        @Inject(SHIPPING) private readonly shipping: Shipping,
        @Inject(ShippingRepo) public readonly shippingRepo: ShippingRepo,
        @Inject(OrderRepo) public readonly orderRepo: OrderRepo,
        @Inject(LayerRef) private readonly layerRef: LayerRef
    ) {
        this.shipping$.next(shipping)
    }

    public doClose(changed: boolean = false) {
        if (changed) {
            this.layerRef.emit({ type: "changed" })
        }
        this.layerRef.close()
    }

    public doSetSuccess() {
        // this.orderRepo.update_delivery_status({ shipping_id: this.shipping.id, status: "SUCCESS" })
        //     .subscribe(this.doClose.bind(this, true))
    }

    public doSetFailure() {
        // this.orderRepo.update_delivery_status({ shipping_id: this.shipping.id, status: "FAILURE" })
        //     .subscribe(this.doClose.bind(this, true))
    }
}
