import { Component } from "@angular/core"

import { SelectedPartner } from "../common.module"

@Component({
    selector: ".eur-products-error",
    templateUrl: "./products-error.component.pug"
})
export class ProductsErrorComponent {
    public constructor() {}

    public onPartnerChange(partner: SelectedPartner) {
        console.log("onPartnerChange", partner)
    }
}
