import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { MerchantApi } from "@backend/merchant.api"
import { SupplierApi } from "@backend/supplier.api"

import { EuropeerCommonModule } from "../common.module"
import { ProductModule } from "../product.module"
import { MerchantProductErrorsComponent } from "./merchant-errors.component"
import { ProductErrorScreen } from "./product-error.screen"
import { ProductsErrorComponent } from "./products-error.component"
import { SupplierProductErrorsComponent } from "./supplier-errors.component"

export const ROUTES: Route[] = [{ path: "product-error", component: ProductErrorScreen }]

@NgModule({
    imports: [CommonModule, NzModule, EuropeerCommonModule, ProductModule, MerchantApi, SupplierApi],
    declarations: [
        ProductErrorScreen,
        ProductsErrorComponent,
        SupplierProductErrorsComponent,
        MerchantProductErrorsComponent
    ],
    exports: [ProductErrorScreen, ProductsErrorComponent],
    providers: [],
    entryComponents: []
})
export class ProductErrorModule {}
