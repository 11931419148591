import { Component, Inject } from "@angular/core"

import { DataSourceDirective, FileDownloadService, LoadFields, ToastService } from "@anzar/core"

import { Manufacturer, ManufacturerRepoSource } from "@backend/product.api"

import { BACKEND_BASE_URL } from "../common.module"
import { ManufacturerUploaderService } from "./manufacturer-uploader.component"

const MANUFACTURER_FIELDS: LoadFields<Manufacturer> = ["id", "name", { renamed: ["id", "name"] }]

@Component({
    selector: ".eur-manufacturers",
    templateUrl: "./manufacturers.component.pug",
    providers: [
        ManufacturerUploaderService,
        {
            provide: DataSourceDirective,
            deps: [ManufacturerRepoSource],
            useFactory: function (source: ManufacturerRepoSource) {
                const result = new DataSourceDirective()
                result.dataSource = source
                return result
            }
        }
    ]
})
export class ManufacturersComponent {
    public readonly fields = MANUFACTURER_FIELDS

    public constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(DataSourceDirective) public readonly dataSource: DataSourceDirective,
        @Inject(FileDownloadService) private readonly fileDownload: FileDownloadService,
        @Inject(ManufacturerUploaderService) private readonly uploader: ManufacturerUploaderService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {}

    public export() {
        this.fileDownload
            .download(`${this.baseUrl}/get/manufacturers`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Gyártók excel letöltése" }))
            .subscribe()
    }

    public import(event: Event) {
        this.uploader.show(event.target as HTMLElement).subscribe(() => this.dataSource.reload())
    }
}
