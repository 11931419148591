import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

// import { ChartsModule } from "ng2-charts"
import { EuropeerCommonModule } from "../common.module"
import { DashboardScreen } from "./dashboard.screen"
import { TestChartComponent } from "./test-chart.component"

export const ROUTES: Route[] = [{ path: "", component: DashboardScreen }]

@NgModule({
    imports: [
        CommonModule,
        NzModule,
        EuropeerCommonModule
        // ChartsModule
    ],
    declarations: [DashboardScreen, TestChartComponent],
    exports: [DashboardScreen, TestChartComponent],
    providers: []
})
export class DashboardModule {}
