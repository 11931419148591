import { Inject, Injectable } from "@angular/core"

import {
    BehaviorSubject,
    catchError,
    combineLatest,
    map,
    NEVER,
    Observable,
    of,
    ReplaySubject,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    take,
    tap
} from "rxjs"

import { DataSourceDirective, Destructible, LoadFields, ProgressEvent, ToastService } from "@anzar/core"

import { RunningTask } from "@pyzar/task.module/running-task"

import { TaskManager } from "@backend/__anzar_rpc_output"
import { Order, OrderRepo } from "@backend/order.api"

export interface PagerData {
    prevId?: number | null
    currentId: number
    nextId?: number | null
}

export interface OrderNav {
    direction: "prev" | "next"
    order: Order
}

@Injectable()
export class OrderDetailsService extends Destructible {
    public readonly orderId = new ReplaySubject<number>(1)
    public readonly orderFields = new BehaviorSubject<LoadFields<Order>>(null)

    private readonly srcChanges = combineLatest({
        filter: this.orderSrc.filterChanges.pipe(
            startWith(null),
            map(_ => this.orderSrc.filter)
        ),
        sorter: this.orderSrc.sorterChanges.pipe(
            startWith(null),
            map(_ => this.orderSrc.sort)
        )
    }).pipe(shareReplay(1))

    public readonly pager$ = this.destruct
        .subscription(
            combineLatest({
                src: this.srcChanges,
                orderId: this.orderId
            })
        )
        .pipe(
            switchMap(({ src, orderId }) =>
                this.orderRepo.position({ id: orderId, filter: src.filter, order: src.sorter }).pipe(
                    map(position => {
                        return { src, orderId, position }
                    })
                )
            ),
            switchMap(({ src, orderId, position }) =>
                this.orderRepo
                    .search(
                        {
                            filter: src.filter,
                            order: src.sorter,
                            begin: Math.max(0, position - 1),
                            count: 3
                        },
                        { loadFields: ["id"] }
                    )
                    .pipe(
                        map(orders => {
                            return { src, orderId, position, orderIds: orders.map(v => v.id) }
                        })
                    )
            ),
            map(({ orderId, orderIds }) => {
                const currentIdx = orderIds.indexOf(orderId)
                if (currentIdx === 0) {
                    return { nextId: orderIds[1], currentId: orderId }
                }

                if (currentIdx >= 0) {
                    orderIds.splice(currentIdx, 1)
                }

                const [prevId, nextId] = orderIds
                return { prevId, nextId, currentId: orderId }
            }),
            shareReplay(1)
        )

    public readonly navigation = new ReplaySubject<OrderNav>()

    public constructor(
        @Inject(DataSourceDirective) public readonly orderSrc: DataSourceDirective<Order>,
        @Inject(OrderRepo) public readonly orderRepo: OrderRepo,
        @Inject(ToastService) public readonly toast: ToastService,
        @Inject(TaskManager) public readonly taskMgr: TaskManager
    ) {
        super()
    }

    public prevOrder() {
        return this.loadOrder("prevId").pipe(take(1))
    }

    public nextOrder() {
        return this.loadOrder("nextId").pipe(take(1))
    }

    private loadOrder(key: keyof PagerData) {
        return this.pager$.pipe(
            take(1),
            switchMap(pager => {
                if (pager[key]) {
                    this.orderId.next(pager[key])
                    return this.orderFields.pipe(
                        take(1),
                        switchMap(loadFields => this.orderRepo.get({ id: pager[key] }, { loadFields })),
                        tap(order => this.navigation.next({ direction: key === "nextId" ? "next" : "prev", order }))
                    )
                } else {
                    return of(null)
                }
            })
        )
    }

    public supplierSendOrders() {
        return new Observable(dst => {
            const progress = new Subject<ProgressEvent>()
            this.toast.progress({ align: "bottom center", progress })
            progress.next({ message: "Megrendelések beküldése..." })

            return this.taskMgr
                .start({ name: "supplier-send-orders" })
                .pipe(
                    catchError(err => {
                        dst.error(err)
                        return NEVER
                    }),
                    map(result => new RunningTask(this.taskMgr, this.toast, result.id)),
                    switchMap(running => running.info),
                    tap(console.log)
                )
                .subscribe(event => {
                    if (event.status === "success" || event.status === "failure") {
                        dst.next(event)
                        dst.complete()
                        progress.complete()
                    }
                })
        })
    }
}
