import { Inject, Injectable } from "@angular/core"

import { Observable, Subject, tap } from "rxjs"

import { LayerService, ModalLayer } from "@anzar/core"

import { CREDITING_ID, CreditingFormComponent } from "./form.component"

@Injectable()
export class OrderCreditingService {
    public readonly changes = new Subject<number | null>()

    public constructor(@Inject(LayerService) private layer: LayerService) {}

    public add() {
        return this._showForm().pipe(tap(res => res && this.changes.next(null)))
    }

    public mod(id: number) {
        return this._showForm(id).pipe(tap(res => res && this.changes.next(res)))
    }

    private _showForm(id?: number): Observable<number | null> {
        return new Observable(observer => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: true },
                closeable: true,
                rounded: 3,
                elevation: 15,
                trapFocus: true,
                position: {
                    align: {
                        vertical: "top",
                        horizontal: "center"
                    },
                    constraint: {
                        ref: "viewport",
                        inset: "32 16 16 16"
                    }
                }
            })

            const ref = this.layer.createFromComponent(CreditingFormComponent, behavior, null, [
                { provide: CREDITING_ID, useValue: id }
            ])

            ref.show()
            ref.subscribe(event => {
                if (event.type === "change") {
                    observer.next(event.data as number)
                } else if (event.type === "hiding") {
                    observer.complete()
                }
            })

            return () => {
                if (ref) {
                    ref.hide()
                }
            }
        })
    }
}
