import { Component, Input } from "@angular/core"

import { Price } from "@backend/common.api"

@Component({
    selector: ".eur-price",
    templateUrl: "./price.component.pug"
})
export class PriceComponent {
    @Input() public price: Price
}
