import { Component, Inject, Injectable, InjectionToken } from "@angular/core"

import { Observable } from "rxjs"

import { LayerRef, LayerService, ModalLayer, ToastService } from "@anzar/core"

import { SupplierProductRepo } from "@backend/supplier.api"

import { ProductSheetService } from "./product-sheet.component"
import { SupplierProductFormComponent } from "./supplier-product-form.component"

export interface ProductOfferWndParams {
    supplier_id: number
    product_id: number
}

const PARAMS = new InjectionToken<ProductOfferWndParams>("PARAMS")

@Injectable()
export class ProductOfferWndService {
    constructor(@Inject(LayerService) private layerSvc: LayerService) {}

    show(params: ProductOfferWndParams): Observable<number | undefined> {
        return new Observable(observer => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: false },
                elevation: 15,
                rounded: 3,
                trapFocus: true,
                position: {
                    align: "top center",
                    constraint: {
                        ref: "viewport",
                        inset: 32
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(ProductOfferWndComponent, behavior, null, [
                { provide: PARAMS, useValue: params }
            ])

            ref.subscribe(event => {
                console.log(event)
                if (event.type === "hiding") {
                    observer.complete()
                } else if (event.type === "done") {
                    observer.next(event.data)
                }
            })

            ref.show()

            return () => {
                ref?.hide()
            }
        })
    }
}

@Component({
    selector: "eur-product-offer-wnd",
    templateUrl: "./product-offer-wnd.component.pug",
    providers: [ProductSheetService]
})
export class ProductOfferWndComponent {
    readonly offerForm = SupplierProductFormComponent.formModel(this.params)

    // TODO: attach product offer, when receiving productIn
    constructor(
        @Inject(PARAMS) private readonly params: ProductOfferWndParams,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(SupplierProductRepo) private readonly supplierProductRepo: SupplierProductRepo,
        @Inject(ToastService) private readonly toast: ToastService
    ) {}

    doCancel() {
        this.layerRef.hide()
    }

    doSave() {
        this.supplierProductRepo
            .save_offer({ data: this.offerForm.value })
            .pipe(this.toast.catchError())
            .subscribe(supplierProductId => {
                this.layerRef.emit({ type: "done", data: supplierProductId })
                this.layerRef.hide()
            })
    }
}
