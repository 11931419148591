import { coerceBooleanProperty } from "@angular/cdk/coercion"
import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core"

import { LoadFields } from "@anzar/core"

import { Invoice } from "@backend/accounting.api"

import { AccountingService } from "./accounting.service"

export const INVOICE_BADGE_FIELDS: LoadFields<Invoice> = ["id", "serno", "type", "outdated"]

const INVOICE_COLORS = {
    STORNO: "critical",
    INVOICE: "confirm",
    MODIFY: "blue",
    PROFORMA: "common",
    WAYBILL: "common",
    OFFER: "common"
}

@Component({
    selector: "eur-invoice-badge",
    templateUrl: "./invoice-badge.component.pug"
})
export class InvoiceBageComponent implements OnChanges {
    /***
     * required values: id, serno, type, outdated?
     */
    @Input()
    public invoice: Invoice

    @Input()
    public set iconOnly(val: boolean) {
        val = coerceBooleanProperty(val)
        if (this._iconOnly !== val) {
            this._iconOnly = val
        }
    }
    public get iconOnly(): boolean {
        return this._iconOnly
    }
    private _iconOnly: boolean

    public color: string
    public type: string
    public outdated: boolean

    public constructor(@Inject(AccountingService) private readonly accounting: AccountingService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ("invoice" in changes) {
            const invoice = this.invoice
            if (invoice) {
                if (typeof invoice.type === "string") {
                    this.type = invoice.type
                } else {
                    this.type = invoice.type?.value
                }

                this.outdated = invoice.outdated != null ? invoice.outdated : false
            } else {
                this.type = null
                this.outdated = false
            }

            this.color = (INVOICE_COLORS as any)[this.type as any] || "warn"
            if (this.outdated) {
                this.color = `light-${this.color}`
            }
        }
    }

    public download() {
        this.accounting.donwloadInvoiceById(this.invoice.id).subscribe()
    }
}
