import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"

import { Destructible, Field, FileDownloadService, Model, StaticSource, ToastService } from "@anzar/core"

import { CategoryRepoSource } from "@backend/category.api"
import { PartnerRepo, PartnerRepoSource } from "@backend/partner.api"
import { ProductQueryLinkRepo, ProductQueryRepo, ProductQuerySource } from "@backend/partner.api"
import { ManufacturerRepoSource } from "@backend/product.api"

import { BACKEND_BASE_URL, PartnerCardinality, PartnerService } from "../../common.module"
import { ProductLinkUploaderService } from "./product-uploader.component"

export class PartnerEntityIdField extends Model {
    @Field({ primary: true }) name: string
    @Field() label: string
}

@Component({
    selector: ".eur-partner-products",
    templateUrl: "./products.component.pug",
    providers: [ProductLinkUploaderService]
})
export class PartnerProductsComponent extends Destructible implements OnChanges {
    @Input() public dstPartnerId: number
    @Input() public partnerEntityIdSrc: StaticSource<PartnerEntityIdField>
    @Input() public partnerType: PartnerCardinality

    public readonly filters = new FormArray([])
    public get filterControls() {
        return this.filters.controls
    }
    public readonly manualProductAttach = new FormControl()

    public linkStat: { total: number }
    public partnerTypeLabel: string
    public srcLabel: string
    public srcType: string
    public canProvidePartnerEntityId: boolean

    public constructor(
        @Inject(PartnerRepo) public readonly partnerRepo: PartnerRepo,
        @Inject(PartnerRepoSource) public readonly partnerSrc: PartnerRepoSource,
        @Inject(PartnerService) public readonly partnerSvc: PartnerService,
        @Inject(ManufacturerRepoSource) public readonly manufacturerSrc: ManufacturerRepoSource,
        @Inject(CategoryRepoSource) public readonly categorySrc: CategoryRepoSource,
        @Inject(ProductQueryRepo) private readonly productQueryRepo: ProductQueryRepo,
        @Inject(ProductQueryLinkRepo) private readonly productQueryLinkRepo: ProductQueryLinkRepo,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ProductLinkUploaderService) private readonly linkUploader: ProductLinkUploaderService
    ) {
        super()
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.dstPartnerId) {
            if (changes.dstPartnerId.currentValue) {
                this._reload()
            }
        }

        if ("partnerType" in changes) {
            const partnerType: PartnerCardinality = changes.partnerType.currentValue

            switch (partnerType) {
                case PartnerCardinality.Merchant:
                    this.partnerTypeLabel = "kereskedő"
                    this.srcLabel = "Forgalmazó"
                    this.srcType = "SUPPLIER"
                    break

                case PartnerCardinality.Advert:
                    this.partnerTypeLabel = "reklámozó"
                    this.srcLabel = "Kereskedő"
                    this.srcType = "MERCHANT"
                    break

                default:
                    this.partnerTypeLabel = "<UNDEFINED>"
                    break
            }
        }
    }

    public addFilter(data?: ProductQuerySource) {
        const group = new FormGroup({
            id: new FormControl(data?.id),
            src_partner_id: new FormControl(data?.src_partner_id),
            product_filter: new FormControl(data?.product_filter),
            partner_entity_id_field: new FormControl(data?.partner_entity_id_field),
            is_exclusive: new FormControl(data?.is_exclusive || false),
            is_exclude: new FormControl(data?.is_exclude || false)
        })

        this.destruct.subscription(group.get("is_exclusive").valueChanges).subscribe(is_exclusive => {
            if (is_exclusive === true) {
                group.get("is_exclude").setValue(false)
            }
        })

        this.destruct.subscription(group.get("is_exclude").valueChanges).subscribe(is_exclude => {
            if (is_exclude === true) {
                group.get("is_exclusive").setValue(false)
            }
        })

        this.filters.push(group)
    }

    public doSave() {
        this.productQueryRepo
            .save_filters({
                dst_partner_id: this.dstPartnerId,
                filters: this.filters.value,
                manual_product_attach: this.manualProductAttach.value
            })
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Termék beállítások mentése" }))
            .subscribe(result => {
                result && this._reload()
            })
    }

    public delFilter(idx: number) {
        this.filters.removeAt(idx)
    }

    public reset() {
        this._reload()
    }

    private _reload() {
        this.partnerSvc.get(this.dstPartnerId).subscribe(partner => {
            const merchant = this.partnerSvc.getTrait(partner, "MerchantProductTrait")
            const advert = this.partnerSvc.getTrait(partner, "AdvertProductTrait")
            if (merchant) {
                this.manualProductAttach.setValue(merchant.manual_product_attach)
                this.canProvidePartnerEntityId = merchant.can_provide_partner_entity_id
            } else if (advert) {
                this.manualProductAttach.setValue(false)
                this.canProvidePartnerEntityId = true
            }
        })

        this.productQueryRepo.get_filters({ dst_partner_id: this.dstPartnerId }).subscribe(filters => {
            this.filters.clear()
            for (const filter of filters) {
                this.addFilter(filter)
            }
        })

        this.productQueryLinkRepo.get_stat({ dst_partner_id: this.dstPartnerId }).subscribe(stat => {
            this.linkStat = stat as any
        })
    }

    public downloadExcel() {
        this.downloader
            .download(`${this.baseUrl}/get/product-link/${this.dstPartnerId}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", message: "Termékek letöltése folyamatban" }))
            .subscribe()
    }

    public uploadExcel(event: Event) {
        this.linkUploader.show(event.target as any, this.dstPartnerId).subscribe(result => {
            if (result) {
                this._reload()
            }
        })
    }
}
