import { Component, Inject, InjectionToken, Optional } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

import { take } from "rxjs"

import { LayerRef, ToastService } from "@anzar/core"

import { OrderCreditingRepo } from "@backend/order.api"
import { PartnerRepoSource } from "@backend/partner.api"
import { ManufacturerRepoSource } from "@backend/product.api"

export const CREDITING_ID = new InjectionToken<number>("CREDITING_ID")

@Component({
    selector: "eur-order-credting-form",
    templateUrl: "./form.component.pug"
})
export class CreditingFormComponent {
    public readonly form = new FormGroup({
        id: new FormControl(),
        manufacturer_id: new FormControl(),
        merchant_id: new FormControl(),
        supplier_id: new FormControl(),
        credit_date: new FormControl(),
        amount: new FormControl(),
        note: new FormControl()
    })

    public constructor(
        @Inject(LayerRef) private readonly layer: LayerRef,
        @Inject(ToastService) public readonly toast: ToastService,
        @Inject(ManufacturerRepoSource) public readonly manafucturerSrc: ManufacturerRepoSource,
        @Inject(PartnerRepoSource) public readonly partnerSrc: PartnerRepoSource,
        @Inject(OrderCreditingRepo) public readonly creditingRepo: OrderCreditingRepo,
        @Inject(CREDITING_ID) @Optional() public readonly creditingId?: number
    ) {
        if (creditingId) {
            this.creditingRepo
                .get({ id: creditingId })
                .pipe(
                    take(1),
                    this.toast.catchError(() => this.layer.close())
                )
                .subscribe(crediting => {
                    this.form.setValue({
                        id: crediting.id,
                        manufacturer_id: crediting.manufacturer_id,
                        merchant_id: crediting.merchant_id,
                        supplier_id: crediting.supplier_id,
                        credit_date: crediting.credit_date,
                        amount: crediting.amount.gross,
                        note: crediting.note
                    })
                })
        }
    }

    public doSave() {
        this.creditingRepo
            .save({ data: this.form.value })
            .pipe(this.toast.handleSave({ beginMsg: "Jóváírás mentése", align: "bottom center" }))
            .subscribe(crediting => {
                this.layer.emit({ type: "change", data: crediting.id })
                this.layer.close()
            })
    }

    public doCancel() {
        this.layer.close()
    }
}
