import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"
import { FsModule } from "@pyzar/fs.module"

import { AdvertApi } from "@backend/advert.api"
import { MerchantApi } from "@backend/merchant.api"
import { ProductApi } from "@backend/product.api"

import { EuropeerCommonModule } from "../common.module"
import { ProductDiscountGridComponent } from "./discount/product-discount-grid.component"
import { ProductDiscountScreenComponent } from "./discount/product-discount-screen.component"
import { ProductDiscountUploaderWndComponent } from "./discount/product-discount-uploader.component"
import { ProductBasicsComponent } from "./product-basics.component"
import { ProductCreateWndComponent, ProductCreateWndService } from "./product-create-wnd.component"
import { ProductGridComponent } from "./product-grid.component"
import { ProductOfferWndComponent, ProductOfferWndService } from "./product-offer-wnd.component"
import { ProductSelectorWndComponent } from "./product-selector-wnd.component"
import { ProductSelectorComponent } from "./product-selector.component"
import { ProductSheetComponent, ProductSheetService } from "./product-sheet.component"
import { ProductScreen } from "./product.screen"
import { SimilarProductComponent } from "./similar/similar-product.component"
import { SimilarProductsComponent } from "./similar/similar-products.component"
import { SimilarProductsScreen } from "./similar/similar-products.screen"
import { SupplierProductFormComponent } from "./supplier-product-form.component"

// import { ProductUploaderService, ProductUploaderWndComponent } from "./product-uploader.component"
// import { ProductUploadResultService, ProductUploadResultComponent } from "./product-upload-result.component"
export { ProductSheetService, ProductCreateWndService, ProductOfferWndService }

export const ROUTES: Route[] = [
    { path: "product-list", component: ProductScreen },
    { path: "similar-products", component: SimilarProductsScreen },
    { path: "product-discount", component: ProductDiscountScreenComponent }
]

@NgModule({
    imports: [
        CommonModule,
        NzModule,
        EuropeerCommonModule,
        PyzarCommonModule,
        ProductApi,
        MerchantApi,
        AdvertApi,
        FsModule
    ],
    declarations: [
        ProductScreen,
        ProductGridComponent,
        ProductSheetComponent,
        ProductBasicsComponent,
        ProductSelectorComponent,
        ProductSelectorWndComponent,
        // ProductUploaderWndComponent,
        // ProductUploadResultComponent,

        SimilarProductsScreen,
        SimilarProductsComponent,
        SimilarProductComponent,

        SupplierProductFormComponent,
        ProductCreateWndComponent,
        ProductOfferWndComponent,

        ProductDiscountGridComponent,
        ProductDiscountScreenComponent,
        ProductDiscountUploaderWndComponent
    ],
    exports: [
        ProductScreen,
        ProductGridComponent,
        ProductSheetComponent,
        ProductBasicsComponent,
        ProductSelectorComponent,
        ProductSelectorWndComponent,
        // ProductUploaderWndComponent,
        // ProductUploadResultComponent,

        SimilarProductsScreen,
        SimilarProductsComponent,
        SimilarProductComponent,

        ProductDiscountGridComponent,
        ProductDiscountScreenComponent,
        ProductDiscountUploaderWndComponent
    ],
    providers: [
        ProductSheetService
        // ProductUploaderService,
        // ProductUploadResultService
    ],
    entryComponents: [
        ProductSheetComponent,
        ProductSelectorWndComponent,
        ProductCreateWndComponent,
        ProductOfferWndComponent,
        ProductDiscountUploaderWndComponent
        // ProductUploaderWndComponent,
        // ProductUploadResultComponent
    ]
})
export class ProductModule {}
