import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from "@angular/core"

import { merge, Observable, tap } from "rxjs"

import { FocusGroup, ToastService } from "@anzar/core"

import { Complaint, ComplaintRepo, ComplaintType } from "@backend/order.api"

import { ComplaintEntryComponent } from "./_abstract"
import { ComplaintForm, ComplaintService } from "./complaint.service"

@Component({
    selector: ".eur-order-complaint",
    templateUrl: "./complaint.component.pug",
    providers: [ComplaintService, FocusGroup]
})
export class ComplaintComponent extends ComplaintEntryComponent<ComplaintForm> implements OnChanges {
    // ! dont use, only for imput, use svc.complaintBasic instead
    @Input() public complaint: Complaint

    protected get debounce(): { [key: string]: number } | number {
        return { other: 1000 }
    }

    // public get form() { return this.svc.form }
    public get itemsArray() {
        return this.svc.itemsArray
    }
    public get notesArray() {
        return this.svc.notesArray
    }
    public get filesArray() {
        return this.svc.filesArray
    }

    public readonly typeValues = ComplaintType.DATA
    public refundBusy: boolean = false
    public busy: string | null = null

    @Output() public updated = new EventEmitter<void>()
    @Output() public removed = new EventEmitter<void>()
    @Output() public reloadOrder = new EventEmitter<void>()

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ComplaintRepo) private readonly complaintRepo: ComplaintRepo,
        @Inject(ComplaintService) public readonly svc: ComplaintService,
        @Inject(FocusGroup) public readonly fg: FocusGroup
    ) {
        super()

        this.form = svc.form

        this.destruct
            .subscription(
                merge(this.itemsArray.valueChanges, this.notesArray.valueChanges, this.filesArray.valueChanges)
            )
            .subscribe(items => {
                cdr.detectChanges()
            })

        this.destruct.subscription(svc.reload).subscribe(reload => {
            if (!reload) {
                return
            }
            if (reload.includes("all") || reload.includes("basic")) {
                this.updated.next()
            }
        })
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("complaint" in changes) {
            const complaint: Complaint = changes.complaint.currentValue
            this.svc.initial.next(complaint)
        }
    }

    public onCollapsedChange(collapsed: boolean) {
        if (!collapsed) {
            this.svc.reload.next(["all"])
        }
    }

    public doAccount() {
        this.busy = "account"
        this.svc.accountComplaint().subscribe(() => {
            this.busy = null
        })
    }

    public doRefund() {
        this.busy = "refund"
        this.svc.refundComplaint().subscribe(() => {
            this.reloadOrder.next()
            this.busy = null
        })
    }

    public doClose() {
        this.busy = "close"
        this.svc.closeComplaint().subscribe(() => {
            this.reloadOrder.next()
            this.busy = null
        })
    }

    public doShip() {
        this.busy = "ship"
        this.svc.shipComplaint().subscribe(() => {
            this.reloadOrder.next()
            this.busy = null
        })
    }

    public doRemove() {
        this.svc.delComplaint().subscribe(() => {
            this.removed.next(null)
        })
    }

    // public doSetStatus(status: string) {
    //     this.svc.setStatus(status)
    // }

    public doAddItem() {
        this.svc.newItem().subscribe()
    }

    public doAddNote() {
        this.svc.newNote().subscribe()
    }

    public doAddFile() {
        this.svc.newFile().subscribe()
    }

    protected saveEntry(values: ComplaintForm): Observable<any> {
        return this.svc.saveComplaint(values).pipe(tap(() => this.updated.next()))
    }
}
