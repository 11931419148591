import { Component, Inject, Input, ViewChild } from "@angular/core"

import { GridComponent, LoadFields } from "@anzar/core"

import { MerchantProductDiscount, MerchantProductDiscountRepoSource } from "@backend/merchant.api"

import { ProductSheetService } from "../product-sheet.component"

const FIELDS: LoadFields<MerchantProductDiscount> = [
    "merchant_id",
    "product_id",
    "begin",
    "end",
    "type",
    "amount",
    { product: ["id", "name", "sku"] }
]

@Component({
    selector: "eur-product-discount-grid",
    templateUrl: "./product-discount-grid.component.pug"
})
export class ProductDiscountGridComponent {
    @ViewChild("grid", { static: true, read: GridComponent })
    public readonly grid!: GridComponent

    @Input() public merchantId: number
    readonly fields = FIELDS

    constructor(
        @Inject(MerchantProductDiscountRepoSource) public readonly source: MerchantProductDiscountRepoSource,
        @Inject(ProductSheetService) private readonly psheet: ProductSheetService
    ) {}

    reload() {
        this.grid.reaload()
    }

    onRowTap(discount: MerchantProductDiscount) {
        this.psheet.show(discount.product_id).subscribe()
    }
}
