import { Component, Input } from "@angular/core"

import { ShippingTracking } from "@backend/order.api"

@Component({
    selector: ".eur-shipping-tracking",
    templateUrl: "./shipping-tracking.component.pug"
})
export class ShippingTrackingComponent {
    @Input() public tracking: ShippingTracking
}
