import { AfterViewInit, Component, Inject, Injectable } from "@angular/core"

import { Observable, of, switchMap, take } from "rxjs"

import {
    ClosingGuarded,
    Destructible,
    DialogService,
    LayerEvent,
    LayerRef,
    LayerService,
    ModalLayer
} from "@anzar/core"

import { OrderWizardService } from "./order-wizard.service"

@Injectable()
export class OrderWizardWndService {
    public constructor(@Inject(LayerService) private layerSvc: LayerService) {}

    public show() {
        return new Observable(subscriber => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: false },
                closeable: false,
                rounded: 3,
                elevation: 15,
                trapFocus: true,
                position: {
                    align: {
                        vertical: "top",
                        horizontal: "center"
                    },
                    constraint: {
                        ref: "viewport",
                        inset: "32 16 32 16"
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(OrderWizardWndComponent, behavior, null, [])

            ref.show()
            ref.subscribe(event => {
                if (event.type === "done") {
                    subscriber.next(event.data.id as number)
                } else if (event.type === "hiding") {
                    subscriber.complete()
                }
            })

            return () => {
                if (ref) {
                    ref.hide()
                }
            }
        })
    }
}

@Component({
    selector: "eur-order-wizard-wnd",
    templateUrl: "./order-wizard-wnd.component.pug",
    providers: [OrderWizardService]
})
export class OrderWizardWndComponent extends Destructible implements ClosingGuarded, AfterViewInit {
    // @ViewChild("dynStack") public readonly dynStack: DynStackDirective<number>

    public constructor(
        @Inject(DialogService) private readonly dialogSvc: DialogService,
        @Inject(OrderWizardService) public readonly svc: OrderWizardService,
        @Inject(LayerRef) private readonly layerRef: LayerRef
    ) {
        super()
    }

    public ngAfterViewInit(): void {
        // this.dynStack.next(0)
    }

    public canClose(layerRef: LayerRef<LayerEvent<any>>): Observable<boolean> {
        return this.svc.dirty$.pipe(
            take(1),
            switchMap(dirty => {
                if (dirty) {
                    return this.dialogSvc.confirmClose(
                        "Elmentetlen változások",
                        // eslint-disable-next-line max-len
                        "A rendelés elmentetlen változásokat tartlamaz. Biztos bezárod az ablakot? Az összes változatás elveszik!"
                    )
                } else {
                    return of(true)
                }
            })
        )
    }

    public doClose() {
        this.layerRef.close()
    }

    public doBack() {
        // this.svc.prevStep().subscribe(stepIndex => this.dynStack.prev(stepIndex))
        this.svc.prevStep().subscribe()
    }

    public doNext() {
        // this.svc.nextStep().subscribe(stepIndex => this.dynStack.next(stepIndex))
        this.svc.nextStep().subscribe()
    }

    public doFinalize() {
        this.svc.save().subscribe(orderId => {
            this.layerRef.emit({ type: "done", data: { id: orderId } })
            this.layerRef.hide()
        })
    }

    public doDelete() {
        this.layerRef.hide()
    }
}
