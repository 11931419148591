import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import { Destructible, LayerService, ToastService } from "@anzar/core"

import { Currency, Language } from "@backend/enum.api"
import { Partner, PartnerRepo } from "@backend/partner.api"

import { BACKEND_BASE_URL, PartnerService } from "../../common.module"

@Component({
    selector: ".eur-partner-basic-data",
    templateUrl: "./basic-data.component.pug"
})
export class PartnerBasicDataComponent extends Destructible implements OnChanges {
    @Input() public partner: Partner
    @Output() public readonly partnerChanged = new EventEmitter()
    @ViewChild("tokenInput", { static: false, read: ElementRef })
    public readonly tokenInput: ElementRef<HTMLInputElement>

    // public readonly partnerTypeSrc = PartnerType.DATA
    // public readonly merchantSrc = MerchantInterface.DATA
    // public readonly supplierSrc = SupplierInterface.DATA
    // public readonly carrierSrc = CarrierInterface.DATA
    // public readonly accountingSrc = AccountingInterface.DATA
    // public readonly advertSrc = AdvertInterface.DATA
    // public readonly paymentSrc = PaymentGWInterface.DATA
    public readonly currencySrc = Currency.DATA
    public readonly languageSrc = Language.DATA

    public readonly form = new FormGroup({
        id: new FormControl(),
        // type: new FormControl(undefined, [Validators.required]),
        // interface: new FormControl(undefined, [Validators.required]),
        name: new FormControl(undefined, [Validators.required]),
        internal_id: new FormControl(undefined, [Validators.required]),
        // currency: new FormControl(undefined, [Validators.required]),
        sequence: new FormControl(undefined, [Validators.required]),
        is_active: new FormControl(),
        invoice_send: new FormControl(),
        invoice_language: new FormControl(),
        invoice_comment: new FormControl(),
        order_changed_url: new FormControl(),
        pickup_address_id: new FormControl()
    })

    public hasApiAccess = false
    public hasMerchantOrder = false

    public readonly jwt = new FormControl()

    public constructor(
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(PartnerRepo) private readonly partnerRepo: PartnerRepo,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(PartnerService) public readonly partnerSvc: PartnerService,
        @Inject(BACKEND_BASE_URL) public readonly backendBase: string
    ) {
        super()
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.partner) {
            this.reset()
        }
    }

    // public showPickupAddress(address?: PickupAddress): Observable<number | null> {
    //     return new Observable(observer => {
    //         const behavior = new ModalLayer({
    //             backdrop: { type: "filled", hideOnClick: false },
    //             rounded: 3,
    //             elevation: 15,
    //             position: {
    //                 align: "center",
    //                 constraint: {
    //                     ref: "viewport",
    //                     inset: 16
    //                 }
    //             }
    //         })

    //         const ref = this.layerSvc.createFromComponent(PickupAddressComponent, behavior, null, [
    //             { provide: PickupAddress, useValue: address }
    //         ])

    //         ref.subscribe(event => {
    //             if (event.type === "hiding") {
    //                 observer.complete()
    //             } else if (event.type === "saved") {
    //                 observer.next(event.data)
    //                 observer.complete()
    //             }
    //         })

    //         ref.show()

    //         return () => {
    //             ref.hide()
    //         }
    //     })
    // }

    // public newPickupAddress() {
    //     return this.showPickupAddress().subscribe(id => {
    //         this.form.get("pickup_address_id").setValue(id)
    //     })
    // }

    // public editPickupAddress() {
    //     return this.showPickupAddress()
    // }

    public regenToken() {
        this.partnerRepo.regen_token({ partner_id: this.partner.id }).subscribe(token => {
            this.jwt.setValue(token)
            this.toastSvc.info("Új token létrehozva", { align: "bottom center", autohide: 2000 })
        })
    }

    public tokenToClipboard() {
        const el = this.tokenInput.nativeElement
        el.select()
        el.setSelectionRange(0, 99999)
        document.execCommand("copy")
        el.setSelectionRange(0, 0)
        this.toastSvc.info("Vágólapra másolva", { align: "bottom center", autohide: 2000 })
    }

    public reset() {
        const partner = this.partner
        const apiTrait = this.partnerSvc.getTrait(partner, "ApiAccess")

        this.hasApiAccess = !!apiTrait
        this.hasMerchantOrder = this.partnerSvc.hasTrait(partner, "MerchantOrderTrait")

        const data: { [key: string]: any } = {
            id: partner.id,
            name: partner.name,
            internal_id: partner.internal_id,
            is_active: partner.is_active,
            sequence: partner.sequence,
            invoice_send: null,
            invoice_comment: null,
            invoice_language: null,
            order_changed_url: null,
            pickup_address_id: null
        }

        // if (partner instanceof Carrier) {
        //     data.type = "CARRIER"
        //     data.interface = partner.interface.value
        // } else if (partner instanceof Merchant) {
        //     data.type = "MERCHANT"
        //     data.interface = partner.interface.value

        //     data.invoice_send = partner.invoice_send
        //     data.invoice_comment = partner.invoice_comment
        //     data.invoice_language = partner.invoice_language.value
        //     data.order_changed_url = partner.order_changed_url
        // } else if (partner instanceof Supplier) {
        //     data.type = "SUPPLIER"
        //     data.interface = partner.interface.value

        //     data.pickup_address_id = partner.pickup_address_id
        // } else if (partner instanceof Accounting) {
        //     data.type = "ACCOUNTING"
        //     data.interface = partner.interface.value
        // } else if (partner instanceof Advert) {
        //     data.type = "ADVERT"
        //     data.interface = partner.interface.value
        // } else if (partner instanceof PaymentGW) {
        //     data.type = "PAYMENTGW"
        //     data.interface = partner.interface.value
        // }

        this.form.setValue(data)
        this.jwt.setValue(apiTrait?.jwt)
    }

    public doSave() {
        const formData = this.form.value
        const data = { ...formData }

        data[formData.type] = formData.interface

        this.partnerRepo
            .save({ data })
            .pipe(this.toastSvc.handleSave({ align: "bottom center", beginMsg: "Partner adatainak mentése" }))
            .subscribe(partner => {
                this.partner = partner
                this.reset()
            })
    }
}
