import { Component, Inject, Injectable, InjectionToken } from "@angular/core"
import { FormControl } from "@angular/forms"

import { Observable } from "rxjs"

import { DropdownLayer, LayerRef, LayerService, ToastService } from "@anzar/core"

import { Complaint, ComplaintRepo, ComplaintType } from "@backend/order.api"

import { ComplaintProducts } from "./complaint-products.service"

const ORDER_ID = new InjectionToken<number>("ORDER_ID")

@Component({
    selector: "eur-new-complaint",
    templateUrl: "./new-complaint.component.pug"
})
export class NewComplaintComponent {
    public readonly typeSrc = ComplaintType.DATA

    public readonly type = new FormControl()
    public busy = false

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ComplaintRepo) private readonly complaintRepo: ComplaintRepo,
        @Inject(ORDER_ID) private readonly orderId: number
    ) {}

    public doCancel() {
        this.layerRef.hide()
    }

    public doCreate() {
        this.busy = true
        this.complaintRepo
            .save({ data: { order_id: this.orderId, type: this.type.value } })
            .pipe(this.toast.catchError(() => this.layerRef.hide()))
            .subscribe(complaint => {
                this.layerRef.emit({ type: "create", data: complaint })
            })
    }
}

@Injectable()
export class NewComplaintService {
    public constructor(
        @Inject(LayerService) private layerSvc: LayerService,
        @Inject(ComplaintProducts) private readonly complaintProducts: ComplaintProducts
    ) {}

    // public newComplaint(orderId: number, target?: HTMLElement): Observable<Complaint> {
    //     return this._show(target).pipe(
    //         switchMap(subject => {
    //             return this.repo.save({ data: { order_id: orderId, subject: subject.value } })
    //         })
    //     )
    // }

    public show(orderId: number, target?: HTMLElement): Observable<Complaint> {
        return new Observable(subject => {
            const behavior = new DropdownLayer({
                backdrop: { type: "empty", hideOnClick: true },
                rounded: 3,
                elevation: 10,
                menuLike: true,
                position: {
                    align: "top center",
                    anchor: {
                        align: "bottom center",
                        ref: target
                    }
                }
            })
            const ref = this.layerSvc.createFromComponent(NewComplaintComponent, behavior, null, [
                { provide: ORDER_ID, useValue: orderId }
            ])

            const s = ref.output.subscribe(event => {
                if (event.type === "create") {
                    this.complaintProducts.reload()
                    subject.next(event.data)
                    subject.complete()
                } else if (event.type === "hiding") {
                    subject.complete()
                }
            })

            ref.show()

            return () => {
                s?.unsubscribe()
                ref.hide()
            }
        })
    }
}
