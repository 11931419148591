import { Component, Inject } from "@angular/core"

import { OrderCreditingService } from "./crediting.service"

@Component({
    templateUrl: "./screen.component.pug"
})
export class CreditingScreenComponent {
    public constructor(@Inject(OrderCreditingService) private readonly crediting: OrderCreditingService) {}

    public doAddCrediting(event: Event) {
        this.crediting.add().subscribe()
    }
}
