import { Component, Inject, Input } from "@angular/core"
import { FormControl } from "@angular/forms"

import { BehaviorSubject, combineLatest, filter, map, Observable, shareReplay, switchMap } from "rxjs"

import { Price } from "@backend/common.api"
import { Partner } from "@backend/partner.api"
import { StockRepo } from "@backend/product.api"

import { convertPrice, marginOf, PartnerService } from "../common.module"

export interface ProductOffer {
    partner: Partner
    price: Price
    qty: number
    margin?: number
}

@Component({
    selector: "eur-order-item-supplier-select",
    templateUrl: "./item-supplier-select.component.pug"
})
export class OrderItemSupplierSelectComponent {
    @Input()
    set productId(id: number) {
        if (this.productId$.value !== id) {
            this.productId$.next(id)
        }
    }
    public productId$ = new BehaviorSubject<number | undefined>(undefined)

    @Input()
    set relativePrice(val: Price | undefined) {
        const current = this.relativePrice$.value
        if (!current || !val || current.net !== val.net || current.gross !== val.gross) {
            this.relativePrice$.next(val)
        }
    }
    public relativePrice$ = new BehaviorSubject<Price | undefined>(undefined)

    @Input()
    set conversionRate(id: number) {
        if (this.conversionRate$.value !== id) {
            this.conversionRate$.next(id)
        }
    }
    public conversionRate$ = new BehaviorSubject<number | undefined>(undefined)

    @Input()
    set currency(id: string) {
        if (this.currency$.value !== id) {
            this.currency$.next(id)
        }
    }
    public currency$ = new BehaviorSubject<string | undefined>(undefined)

    @Input() public control: FormControl
    @Input() public multi: boolean = true

    readonly stocks$ = this.productId$.pipe(
        filter(productId => !!productId),
        switchMap(productId => this.stockRepo.search({ filter: { product_id: productId } })),
        shareReplay(1)
    )

    readonly offers$: Observable<ProductOffer[]> = combineLatest({
        stocks: this.stocks$,
        partners: this.partnerSvc.allPartners$,
        relative: this.relativePrice$,
        conversionRate: this.conversionRate$,
        currency: this.currency$
    }).pipe(
        map(({ stocks, partners, relative, conversionRate, currency }) =>
            stocks
                .map(stock => {
                    const partner = partners.find(p => p.id === stock.partner_id)
                    if (!partner || !partner.is_active || !stock.wslp || !stock.wslp.net) {
                        const exists =
                            this.control &&
                            Array.isArray(this.control.value) &&
                            // eslint-disable-next-line eqeqeq
                            this.control.value.find(v => v == partner.id)
                        if (!exists) {
                            return null
                        }
                    }

                    const dstCurrency = currency && conversionRate ? { currency, rate: conversionRate } : null
                    const stockPrice = dstCurrency ? convertPrice(stock.wslp, dstCurrency) : stock.wslp
                    const relativePrice = dstCurrency ? convertPrice(relative, dstCurrency) : relative
                    const margin = marginOf(relativePrice, stockPrice)

                    return {
                        partner,
                        price: stockPrice,
                        qty: stock.available,
                        margin: margin
                    } as ProductOffer
                })
                .filter(offer => !!offer)
                .sort((a, b) => {
                    if (a.price && b.price) {
                        return a.price.net - b.price.net
                    }
                })
        ),
        shareReplay(1)
    )

    constructor(
        @Inject(PartnerService) private partnerSvc: PartnerService,
        @Inject(StockRepo) private stockRepo: StockRepo
    ) {}
}

export function calcMargin() {}
