import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"

import { NzModule } from "@anzar/core"

import { ShippingBadgeComponent } from "./shipping-badge.component"
import { ShippingTrackingComponent } from "./shipping-tracking.component"
import { ShippingTrackingsComponent } from "./shipping-trackings.component"
import { ShippingService } from "./shipping.service"

export { ShippingService }

@NgModule({
    imports: [CommonModule, NzModule],
    declarations: [ShippingBadgeComponent, ShippingTrackingsComponent, ShippingTrackingComponent],
    exports: [ShippingBadgeComponent, ShippingTrackingsComponent, ShippingTrackingComponent],
    providers: [ShippingService],
    entryComponents: [ShippingTrackingsComponent]
})
export class ShippingModule {}
