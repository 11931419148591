import { ChangeDetectorRef, Inject, Injectable } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"

import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    merge,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    take,
    tap
} from "rxjs"

import { Destructible, Field, LayerService, LoadFields, Model, ToastService, UploadedFile } from "@anzar/core"

import { FsService } from "@pyzar/fs.module"

import {
    Complaint,
    ComplaintFile,
    ComplaintFileRepo,
    ComplaintInvoice,
    ComplaintItem,
    ComplaintItemRepo,
    ComplaintNote,
    ComplaintNoteRepo,
    ComplaintRepo,
    OrderItemRepo,
    ShippingItemRepo
} from "@backend/order.api"

import { INVOICE_BADGE_FIELDS } from "../../accounting.module/invoice-badge.component"
import { ComplaintProducts } from "./complaint-products.service"

export const ITEM_FIELDS: LoadFields<ComplaintItem> = [
    "id",
    "status",
    "shipping_item_id",
    "shipped_serial",
    "exchange_serial",
    "arrived_there_id",
    "arrived_there_rma",
    "sync_serial",
    "credit_invoiceno"
]
export const NOTE_FIELDS: LoadFields<ComplaintNote> = ["id", "text", "is_todo"]
export const FILE_FIELDS: LoadFields<ComplaintFile> = ["id", "file"]
export const INVOICE_FIELDS: LoadFields<ComplaintInvoice> = [
    { invoice: INVOICE_BADGE_FIELDS },
    { related_invoice: INVOICE_BADGE_FIELDS }
]

export const BASIC_COMPLAINT_FIELDS: LoadFields<Complaint> = [
    "id",
    "order_id",
    "type",
    "complaint_date",
    "serno",
    "pending_todo",
    "other",
    "created_time",
    "is_closed",
    "is_accounted",
    "is_refunded",
    "is_shipped",
    { invoices: INVOICE_FIELDS }
]

const FULL_COMPLAINT_FIELDS: LoadFields<Complaint> = [
    ...BASIC_COMPLAINT_FIELDS,
    { items: ITEM_FIELDS },
    { notes: NOTE_FIELDS },
    { files: FILE_FIELDS }
]

// const ORDER_ITEM_FIELDS: LoadFields<OrderItem> = [
//     "id", "name", "product_id",
//     {
//         so_items: [
//             "id", "serial_numbers",
//             {
//                 "order": [
//                     { "partner": ["name"] }
//                 ]
//             }
//         ]
//     }
// ]

export class ComplaintItemProduct extends Model {
    @Field({ primary: true }) public productId: number
    @Field() public name: string
    @Field() public supplier: string
    @Field() public serial: string
}

interface ViewState {
    collapsed: boolean
    color: string
    isEdit: boolean
    isEditable: boolean
    statusText: string
}

const CHECK_ITEM_FORM_FIELDS = ["id", "status", "sync_serial"]
const CHECK_NOTE_FORM_FIELDS = ["id", "is_todo"]
const CHECK_FILE_FORM_FIELDS = ["id"]

export interface ComplaintForm {
    id?: number
    order_id?: number
    complaint_date?: Date
    type?: string
    other?: string
    status?: string
}

export interface ComplaintItemForm {
    id?: number
    shipping_item_id?: number
    shipped_serial?: string
    exchange_serial?: string
    status?: string
    arrived_there_id?: number
    arrived_there_rma?: string
    sync_serial?: boolean
    credit_invoiceno?: string
}

export interface ComplaintNoteForm {
    id?: number
    text?: string
    is_todo?: boolean
}

export interface ComplaintFileForm {
    id?: number
    file?: UploadedFile
}

export interface StatusText {
    content: string
    color: "pending" | "success" | "error"
}

interface SaveEntry<T> {
    index: number
    data: { [key: string]: any }
    fields: string[]
    save: (data: { [key: string]: any }) => Observable<any>
    onFailure: (data: T) => void
    onSuccess: (data: T, changes: { [key: string]: any }) => void
}

type ReloadPart = "possibilities" | "all" | "basic"
type ReloadParts = Array<ReloadPart>

@Injectable()
export class ComplaintService extends Destructible {
    public readonly form = new FormGroup({
        id: new FormControl(),
        order_id: new FormControl(),
        complaint_date: new FormControl(new Date()),
        type: new FormControl(),
        other: new FormControl()
    })

    public readonly itemsArray = new FormArray([])
    public readonly notesArray = new FormArray([])
    public readonly filesArray = new FormArray([])

    public readonly initial = new Subject<Complaint>()

    private readonly _id = this.initial.pipe(
        map(v => v.id),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public reload = new BehaviorSubject<ReloadParts | null>(null)

    public readonly possibilities = this._reloadable(["possibilities", "all"]).pipe(
        switchMap(({ id }) => {
            if (id) {
                return this.repo.possibilities({ id })
            } else {
                return of({}) as ReturnType<ComplaintRepo["possibilities"]>
            }
        }),
        shareReplay(1)
    )

    public readonly complaint = this._reloadable(["all"]).pipe(
        switchMap(({ id }) => {
            if (id) {
                return this.repo.get({ id }, { loadFields: FULL_COMPLAINT_FIELDS })
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    private readonly _complaintBasic = this.reload.pipe(
        filter(reload => reload && reload.includes("basic")),
        switchMap(() => this._id),
        switchMap(id => {
            if (id) {
                return this.repo.get({ id }, { loadFields: BASIC_COMPLAINT_FIELDS })
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    public readonly complaintBasic = merge(this.initial, this._complaintBasic, this.complaint).pipe(shareReplay(1))

    public readonly canRefund = this.possibilities.pipe(
        map(p => p.refund),
        shareReplay(1)
    )
    public readonly canAccount = this.possibilities.pipe(
        map(p => p.account),
        shareReplay(1)
    )
    public readonly canShip = this.possibilities.pipe(
        map(p => p.ship),
        shareReplay(1)
    )
    public readonly canClose = this.possibilities.pipe(
        map(p => p.close),
        shareReplay(1)
    )
    public readonly canRemove = this.possibilities.pipe(
        map(p => p.remove),
        shareReplay(1)
    )
    public readonly canAddNote = this.possibilities.pipe(
        map(p => p.add_note),
        shareReplay(1)
    )
    public readonly canAddFile = this.possibilities.pipe(
        map(p => p.add_file),
        shareReplay(1)
    )
    public readonly canAddItem = this.possibilities.pipe(
        map(p => p.add_item),
        switchMap(canAddItem => {
            if (canAddItem) {
                return this.complaintProducts.selectable.pipe(map(items => items.length > 0))
            } else {
                return of(false)
            }
        }),
        shareReplay(1)
    )
    public readonly canDelItem = this.possibilities.pipe(
        map(p => p.del_item),
        shareReplay(1)
    )

    private readonly _viewState: Observable<ViewState> = merge(this.complaint, this.complaintBasic).pipe(
        map(complaint => {
            if (complaint) {
                const isClosed = complaint.is_closed
                const collapsed = complaint.pending_todo === 0 && isClosed
                const color = collapsed ? "dark-warn" : "accent"

                return {
                    collapsed: collapsed,
                    isEdit: true,
                    isEditable: !isClosed,
                    color: color,
                    statusText: this._statusText(complaint)
                } as ViewState
            } else {
                return {
                    collapsed: false,
                    isEdit: false,
                    isEditable: true,
                    color: "dark-info",
                    statusText: "Ismeretlne"
                } as ViewState
            }
        }),
        shareReplay(1)
    )

    public readonly invoices = this.complaintBasic.pipe(
        map(complaint => complaint.invoices),
        shareReplay(1)
    )

    public readonly isCollapsed = this._viewState.pipe(
        map(v => v.collapsed),
        shareReplay(1)
    )
    public readonly headColor = this._viewState.pipe(
        map(v => v.color),
        shareReplay(1)
    )
    public readonly isEditable = this._viewState.pipe(
        map(v => v.isEditable),
        shareReplay(1)
    )
    public readonly complaintStatusLabel = this._viewState.pipe(
        map(v => v.statusText),
        shareReplay(1)
    )

    public readonly allItemsSrc = this.complaintProducts.allSrc
    public readonly selectableItemsSrc = this.complaintProducts.selectableSrc

    public readonly statusText = new BehaviorSubject<StatusText | null>(null)

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(OrderItemRepo) private readonly orderItemRepo: OrderItemRepo,
        @Inject(ComplaintRepo) private readonly repo: ComplaintRepo,
        @Inject(ComplaintItemRepo) private readonly itemRepo: ComplaintItemRepo,
        @Inject(ComplaintNoteRepo) private readonly noteRepo: ComplaintNoteRepo,
        @Inject(ComplaintFileRepo) private readonly fileRepo: ComplaintFileRepo,
        @Inject(ShippingItemRepo) private readonly shippingItemRepo: ShippingItemRepo,
        @Inject(FsService) private readonly fsService: FsService,
        @Inject(ComplaintProducts) private readonly complaintProducts: ComplaintProducts
    ) {
        super()

        this.destruct.subscription(this.complaint).subscribe(complaint => {
            this.resetFormFull(complaint)
        })

        this.destruct.subscription(this.complaintBasic).subscribe(complaint => {
            this.resetFormMinimal(complaint)
        })

        // ! ez kell, e nélük nem megy
        // this.destruct.subscription(this.selectedShippingItemIds).subscribe()

        // TODO: remove
        this.complaintProducts.all.subscribe(value => console.log("ALL", value))
        this.complaintProducts.selected.subscribe(value => console.log("SELECTED", value))
    }

    public newItem() {
        // this._forceReloadOthers.next()

        return this.complaintProducts.selectable.pipe(
            take(1),
            map(selectableItems => {
                console.log({ selectableItems })
                const shippingItemId = selectableItems.length === 1 ? selectableItems[0].id : null
                return this._createItemFormGroup({ shipping_item_id: shippingItemId } as any)
            }),
            tap(this.itemsArray.push.bind(this.itemsArray))
        )
    }

    public delItem(index: number) {
        const itemCtrl = this.itemsArray.at(index)
        if (!itemCtrl) {
            return
        }
        const itemId = itemCtrl.get("id").value
        if (!itemId) {
            this.itemsArray.removeAt(index)
            return
        }

        this.setStatusText({ content: "Termék törlése ...", color: "pending" })
        this.itemRepo
            .remove({ id: itemId })
            .pipe(
                this.toast.catchError(() =>
                    this.setStatusText({ content: "Termék törlése sikertelen", color: "error" })
                )
            )
            .subscribe(() => {
                this.setStatusText({ content: "Termék törlése sikeres", color: "success" })
                this.itemsArray.removeAt(index)
            })
        this.complaintProducts.reload()
        this.reload.next(["possibilities"])
    }

    public saveItem(index: number, data: { [key: string]: any }) {
        return this._saveEntry<ComplaintItemForm>(this.itemsArray, {
            index,
            data,
            fields: CHECK_ITEM_FORM_FIELDS,
            save: data => this.itemRepo.save({ data }),
            onFailure: data => {
                if (data.id == null) {
                    this.delItem(index)
                }
                this.setStatusText({ content: "Termék mentése sikertelen", color: "error" })
            },
            onSuccess: (data, changes) => {
                this.reload.next(["possibilities"])
                this.complaintProducts.reload()
                this.setStatusText({ content: "Termék mentése sikeres", color: "success" })
            }
        })
    }

    private _createItemFormGroup(data?: ComplaintItem) {
        if (!data) {
            data = {} as any
        }

        return new FormGroup({
            id: new FormControl(data.id),
            shipping_item_id: new FormControl(data.shipping_item_id),
            shipped_serial: new FormControl(data.shipped_serial),
            exchange_serial: new FormControl(data.exchange_serial),
            status: new FormControl(data.status?.value || "PENDING"),
            arrived_there_id: new FormControl(data.arrived_there_id),
            arrived_there_rma: new FormControl(data.arrived_there_rma),
            sync_serial: new FormControl(data.sync_serial || false),
            credit_invoiceno: new FormControl(data.credit_invoiceno)
        })
    }

    public newNote(): Observable<FormGroup> {
        return new Observable(subject => {
            const group = this._createNoteFromGroup()
            this.notesArray.push(group)
            subject.next(group)
            subject.complete()
        })
    }

    public delNote(index: number) {
        const noteCtrl = this.notesArray.at(index)
        if (!noteCtrl) {
            return
        }
        const noteItemId = noteCtrl.get("id").value
        if (!noteItemId) {
            this.notesArray.removeAt(index)
            return
        }

        this.setStatusText({ content: "Megjegyzés törlése ...", color: "pending" })
        this.noteRepo
            .remove({ id: noteItemId })
            .pipe(
                this.toast.catchError(() =>
                    this.setStatusText({ content: "Megjegyzés törlése sikertelen", color: "error" })
                )
            )
            .subscribe(() => {
                this.setStatusText({ content: "Megjegyzés törlése sikeres", color: "success" })
                this.notesArray.removeAt(index)
                this.reload.next(["basic"])
            })
    }

    public saveNote(index: number, data: { [key: string]: any }) {
        return this._saveEntry<ComplaintNoteForm>(this.notesArray, {
            index,
            data,
            fields: CHECK_NOTE_FORM_FIELDS,
            save: data => this.noteRepo.save({ data }),
            onFailure: data => {
                if (data.id == null) {
                    this.delNote(index)
                }
                this.setStatusText({ content: "Megjegyzés mentése sikertelen", color: "error" })
            },
            onSuccess: (data, changes) => {
                this.reload.next(["basic"])
                this.setStatusText({ content: "Megjegyzés mentése sikeres", color: "success" })
            }
        })
    }

    private _createNoteFromGroup(data?: ComplaintNoteForm) {
        if (!data) {
            data = {} as any
        }

        return new FormGroup({
            id: new FormControl(data.id),
            text: new FormControl(data.text),
            is_todo: new FormControl(data.is_todo == null ? false : data.is_todo)
        })
    }

    public newFile(): Observable<any> {
        const group = this._createFileGroup()
        this.filesArray.push(group)
        return of(group)
    }

    public delFile(index: number) {
        const fileCtrl = this.filesArray.at(index)
        if (!fileCtrl) {
            return
        }
        const fileItemId = fileCtrl.get("id").value
        if (!fileItemId) {
            this.filesArray.removeAt(index)
            return
        }

        this.setStatusText({ content: "Csatolmány törlése ...", color: "pending" })
        this.fileRepo
            .remove({ id: fileItemId })
            .pipe(
                this.toast.catchError(() =>
                    this.setStatusText({ content: "Csatolmány törlése sikertelen", color: "error" })
                )
            )
            .subscribe(() => {
                this.setStatusText({ content: "Csatolmány törlése sikeres", color: "success" })
                this.filesArray.removeAt(index)
            })
    }

    public saveFile(index: number, data: { [key: string]: any }) {
        return this._saveEntry<ComplaintNoteForm>(this.filesArray, {
            index,
            data,
            fields: CHECK_FILE_FORM_FIELDS,
            save: data => this.fileRepo.save({ data }),
            onFailure: data => {
                if (data.id == null) {
                    this.delFile(index)
                }
                this.setStatusText({ content: "Csatolmány mentése sikertelen", color: "error" })
            },
            onSuccess: (data, changes) => {
                this.setStatusText({ content: "Csatolmány mentése sikeres", color: "success" })
            }
        })
    }

    private _createFileGroup(data?: ComplaintFileForm) {
        if (!data) {
            data = {} as any
        }

        return new FormGroup({
            id: new FormControl(data.id),
            file: new FormControl(data.file)
        })
    }

    public saveComplaint(data: { [key: string]: any }) {
        return this.__saveComplaint("Reklamáció mentése", ["possibilities", "basic"], id =>
            this.repo.save({ data: { ...data, id } })
        )
    }

    public delComplaint() {
        return this.__saveComplaint("Reklamáció törlése", [], id =>
            this.repo.remove({ id }).pipe(tap(() => this.complaintProducts.reload()))
        )

        // return this._id.pipe(
        //     filter(id => !!id),
        //     take(1),
        //     switchMap(id => {
        //         return this.repo.remove({ id }).pipe(
        //             this.toast.catchError(),
        //         )
        //     })
        // )
    }

    public accountComplaint() {
        return this.__saveComplaint("Jóváírás", ["possibilities", "basic"], id =>
            this.repo.save({ data: { id, is_accounted: true } })
        )
    }

    public refundComplaint() {
        return this.__saveComplaint("Visszatérítés", ["possibilities", "basic"], complaint_id =>
            this.repo.refund({ complaint_id })
        )
    }

    public closeComplaint() {
        return this.__saveComplaint("Lezárás", ["possibilities", "basic"], id =>
            this.repo.save({ data: { id, is_closed: true } })
        )
    }

    public shipComplaint() {
        return this.__saveComplaint("Szállítás", ["possibilities", "basic"], complaint_id =>
            this.repo.ship({ complaint_id })
        )
    }

    private __saveComplaint(msg: string, reload: ReloadParts, operation: (id: number) => Observable<any>) {
        this.setStatusText({ content: `${msg} ...`, color: "pending" })
        return this._id.pipe(
            filter(id => !!id),
            take(1),
            switchMap(id =>
                operation(id).pipe(
                    this.toast.catchError(() => this.setStatusText({ content: `${msg} sikertelen`, color: "error" })),
                    tap(() => reload && reload.length && this.reload.next(reload)),
                    tap(() => this.setStatusText({ content: `${msg} sikeres`, color: "success" }))
                )
            )
        )
    }

    private resetFormMinimal(complaint?: Complaint) {
        if (complaint) {
            this.form.setValue(
                {
                    id: complaint.id,
                    order_id: complaint.order_id,
                    complaint_date: complaint.complaint_date,
                    type: complaint.type?.value,
                    other: complaint.other
                },
                { emitEvent: false }
            )
        }
    }

    private resetFormFull(complaint?: Complaint) {
        if (complaint) {
            this.resetFormMinimal(complaint)

            this.itemsArray.clear()
            for (const item of complaint.items) {
                this.itemsArray.push(this._createItemFormGroup(item))
            }

            this.notesArray.clear()
            for (const note of complaint.notes) {
                this.notesArray.push(this._createNoteFromGroup(note))
            }

            this.filesArray.clear()
            for (const file of complaint.files) {
                this.filesArray.push(
                    this._createFileGroup({
                        id: file.id,
                        file: this.fsService.newUploadedFile(file.file)
                    })
                )
            }
        }
    }

    public setStatusText(value: StatusText) {
        this.statusText.next(value)
    }

    private _saveEntry<T>(itemsArray: FormArray, params: SaveEntry<T>) {
        const entryControl = itemsArray.at(params.index)
        if (!entryControl) {
            return of(null)
        }

        const collectData = () => {
            const entryId = entryControl.get("id").value || null
            const dataForSave = { ...params.data }

            if (entryId != null) {
                dataForSave.id = entryId
                return of(dataForSave)
            } else {
                return this._id.pipe(
                    map(id => {
                        if (id) {
                            dataForSave.complaint_id = id
                            return dataForSave
                        } else {
                            return null
                        }
                    }),
                    take(1)
                )
            }
        }
        return collectData().pipe(
            switchMap(dataForSave => {
                this.setStatusText({ content: "Mentés ...", color: "pending" })
                return params.save(dataForSave).pipe(
                    this.toast.catchError(() => params.onFailure(dataForSave as T)),
                    take(1),
                    tap(result => {
                        const changes: { [key: string]: any } = {}

                        for (const field of params.fields) {
                            let value = (result as any)[field]
                            if (value != null && value.value != null) {
                                value = value.value
                            }

                            const ctrl = entryControl.get(field)
                            if (ctrl.value !== value) {
                                changes[field] = value
                            }
                        }

                        const changesLength = Object.keys(changes).length
                        if (changesLength > 0) {
                            entryControl.patchValue(changes)
                        }
                        params.onSuccess(dataForSave as T, changes)
                    })
                )
            })
        )
    }

    private _reloadable(when: ReloadParts) {
        return combineLatest({
            id: this._id,
            reload: this.reload.pipe(filter(v => v && v.some(rv => when.includes(rv))))
        })
    }

    private _statusText(complaint: Complaint) {
        if (complaint.is_closed) {
            return "Lezárva"
        } else {
            const texts = []
            if (complaint.is_refunded) {
                texts.push("Visszatérítve")
            } else if (complaint.is_accounted) {
                texts.push("Jóváírva")
            }

            if (complaint.is_shipped) {
                texts.push("Szállítva")
            }

            if (texts.length === 0) {
                return "Folyamatban"
            } else {
                return texts.join(" / ")
            }
        }
    }
}
