import { Component, Inject, Injectable } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

import { BehaviorSubject, Observable, ReplaySubject, shareReplay, switchMap, take, tap } from "rxjs"

import { LayerRef, LayerService, LoadFields, ModalLayer, ToastService } from "@anzar/core"

import { Product, ProductRepo } from "@backend/product.api"
import { SupplierProduct, SupplierProductRepo } from "@backend/supplier.api"

import { PartnerService } from "../common.module"
import { ProductSheetService } from "./product-sheet.component"
import { SupplierProductFormComponent } from "./supplier-product-form.component"

@Injectable()
export class ProductCreateWndService {
    constructor(@Inject(LayerService) private layerSvc: LayerService) {}

    show(): Observable<SupplierProduct | undefined> {
        return new Observable(observer => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: false },
                elevation: 15,
                rounded: 3,
                trapFocus: true,
                position: {
                    align: "top center",
                    constraint: {
                        ref: "viewport",
                        inset: 32
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(ProductCreateWndComponent, behavior, null, [])

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    observer.complete()
                } else if (event.type === "done") {
                    observer.next(event.data)
                }
            })

            ref.show()

            return () => {
                ref?.hide()
            }
        })
    }
}

const PRODUCT_FIELDS: LoadFields<Product> = ["id", "name", "sku", "eans"]

@Component({
    selector: "eur-product-create-wnd",
    templateUrl: "./product-create-wnd.component.pug",
    providers: [ProductSheetService]
})
export class ProductCreateWndComponent {
    readonly currentStep$ = new BehaviorSubject(0)

    readonly searchForm = new FormGroup({
        name: new FormControl(),
        sku: new FormControl(),
        ean: new FormControl()
    })

    readonly createForm = SupplierProductFormComponent.formModel()

    readonly searchParams$ = new ReplaySubject<{ [key: string]: any }>(1)

    readonly searchResult$ = this.searchParams$.pipe(
        switchMap(filter =>
            this.productRepo.search(
                { filter, order: { name: "asc" }, begin: 0, count: 10 },
                { loadFields: PRODUCT_FIELDS }
            )
        ),
        tap(() => (this.busy$.value === "search" ? this.busy$.next(null) : null)),
        shareReplay(1)
    )

    readonly busy$ = new BehaviorSubject<string>(null)

    // TODO: attach product offer, when receiving productIn
    constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ProductRepo) private readonly productRepo: ProductRepo,
        @Inject(ProductSheetService) private readonly productSheet: ProductSheetService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(SupplierProductRepo) private readonly supplierProductRepo: SupplierProductRepo,
        @Inject(ToastService) private readonly toast: ToastService
    ) {}

    doCancel() {
        this.layerRef.hide()
    }

    goBack() {
        this.currentStep$.next(this.currentStep$.value - 1)
    }

    doSearch() {
        this.busy$.next("search")
        this.searchParams$.next(this.getSearchParams())
    }

    searchParamsIsEmpty() {
        return this.getSearchParams() == null
    }

    getSearchParams() {
        const result: any[] = []

        const values = this.searchForm.value
        if (values.name != null) {
            result.push({ name: { contains: values.name } })
        }

        if (values.sku != null) {
            result.push({ sku: values.sku })
        }

        if (values.ean != null) {
            result.push({ eans: { contains: values.ean } })
        }

        if (result.length > 0) {
            return { or: result }
        }

        return null
    }

    doSave() {
        this.supplierProductRepo
            .save_offer({ data: this.createForm.value })
            .pipe(this.toast.catchError())
            .subscribe(prod => {
                this.layerRef.emit({ type: "done", data: prod })
                this.layerRef.hide()
            })
    }

    doShowProductInfo(product: Product) {
        this.productSheet.show(product.id).subscribe()
    }

    doCreateNewOffer(product: Product) {
        this.partnerSvc.europeer$.pipe(take(1)).subscribe(europeer => {
            this.createForm.reset({
                supplier_id: europeer.id,
                product_id: product.id,
                is_active: true
            })
            this.currentStep$.next(1)
        })
    }

    doNewProduct() {
        this.partnerSvc.europeer$.pipe(take(1)).subscribe(europeer => {
            const search = this.searchForm.value
            this.createForm.reset({
                supplier_id: europeer.id,
                name: search.name,
                manufacturer_sku: search.sku,
                eans: search.ean,
                is_active: true
            })
            this.currentStep$.next(1)
        })
    }

    _eans(eans: string[]) {
        return eans.length === 0 ? "—" : eans.join(", ")
    }
}
