import { ChangeDetectorRef, Component, Inject, Injectable, InjectionToken } from "@angular/core"
import { FormControl } from "@angular/forms"

import { Observable } from "rxjs"
import { filter, switchMap } from "rxjs/operators"

import { DropdownLayer, LayerRef, LayerService, ToastService, UploadedFile } from "@anzar/core"

import { FileUploaderService } from "@pyzar/fs.module"

import { MerchantProductDiscountRepo } from "@backend/__anzar_rpc_output"
import { FileBackend } from "@backend/pyzar.api"

const MERCHANT_ID = new InjectionToken<number>("MERCHANT_ID")

@Injectable()
export class ProductDiscountUploaderService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {}

    public show(target: HTMLElement, merchantId: number): Observable<true> {
        return new Observable(subscriber => {
            const behavior = new DropdownLayer({
                backdrop: { type: "filled", hideOnClick: false },
                closeable: false,
                menuLike: true,
                elevation: 10,
                rounded: 3,
                position: {
                    align: "top center",
                    anchor: {
                        ref: target,
                        align: "bottom center"
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(ProductDiscountUploaderWndComponent, behavior, null, [
                { provide: MERCHANT_ID, useValue: merchantId }
            ])

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    subscriber.complete()
                } else if (event.type === "import-done") {
                    subscriber.next(true)
                }
            })

            ref.show()

            return () => {
                ref.hide()
            }
        })
    }
}

@Component({
    selector: "eur-product-discount-uploader-wnd",
    templateUrl: "./product-discount-uploader.component.pug",
    providers: [FileUploaderService]
})
export class ProductDiscountUploaderWndComponent {
    public readonly file = new FormControl()

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.detectChanges()
        }
    }
    public get isBusy(): boolean {
        return this._isBusy
    }
    private _isBusy: boolean = false

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FileUploaderService) private readonly fsUploader: FileUploaderService,
        @Inject(ToastService) private readonly toastService: ToastService,
        @Inject(FileBackend) private readonly fileBackend: FileBackend,
        @Inject(MerchantProductDiscountRepo) private readonly discountRepo: MerchantProductDiscountRepo,
        @Inject(MERCHANT_ID) private readonly merchantId: number
    ) {}

    public cancel() {
        this.layerRef.hide()
    }

    public doImport() {
        this.isBusy = true

        this.fsUploader
            .upload(`/discounts/${this.merchantId}`)
            .pipe(
                filter(e => e.state === "done"),
                switchMap(event => {
                    const data = this.file.value as UploadedFile

                    return this.discountRepo.import_discounts({ file_id: data.id, merchant_id: this.merchantId }).pipe(
                        this.toastService.handleSave({
                            align: "bottom center",
                            beginMsg: "Akciók importálása...",
                            successMsg: "Akciók sikeresen importálva",
                            onError: error => {
                                this.isBusy = false
                                this.file.reset()
                            }
                        })
                    )
                })
            )
            .subscribe(event => {
                this.layerRef.emit({ type: "import-done" })
                this.layerRef.hide()
            })
    }
}
