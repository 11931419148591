import { Component, EventEmitter, Inject, Input, Output } from "@angular/core"
import { FormControl } from "@angular/forms"

import {
    BehaviorSubject,
    combineLatest,
    concat,
    filter,
    map,
    Observable,
    shareReplay,
    startWith,
    switchMap,
    take
} from "rxjs"

import { LoadFields, ToastService } from "@anzar/core"

import {
    SupplierInvoiceItem,
    SupplierInvoiceItemRepo,
    SupplierInvoiceItemRepoSource,
    SupplierOrder,
    SupplierOrderItem
} from "@backend/supplier.api"
import { SupplierOrderRepo } from "@backend/supplier.sorder.api"

import { OrderDetailsService } from "./order-details.service"

export const INITIAL_FIELDS: LoadFields<SupplierOrder> = [
    "id",
    "status",
    "orderno",
    "error",
    "is_cancelled",
    "is_opened",
    "created_time",
    "updated_time",
    { partner: ["name"] }
]

export const FULL_FIELDS: LoadFields<SupplierOrder> = [
    ...INITIAL_FIELDS,
    { warehouse: ["name"] },
    {
        items: [
            "id",
            "qty",
            "qty_ordered",
            "error",
            "serial_numbers",
            "product_id",
            { product: ["name"] },
            {
                invoice_items: ["unit_price", "qty", { supplier_invoice: ["invoiceno"] }]
            }
        ]
    },
    {
        transactions: [
            "id",
            "action",
            "status",
            "error_reason",
            "retry_count",
            "error",
            "created_time",
            "last_sent_time",
            {
                items: ["qty_change_send", { sorder_item: [{ product: ["name"] }] }]
            }
        ]
    }
]

const INVOICE_ITEM_FIELDS: LoadFields<SupplierInvoiceItem> = [
    "id",
    "name",
    "qty",
    "serial_numbers",
    "unit_price",
    {
        supplier_invoice: ["invoiceno"]
    }
]

@Component({
    selector: ".eur-order-supply",
    templateUrl: "./supply.component.pug"
})
export class OrderSupplyComponent {
    @Input()
    public set initial(val: SupplierOrder) {
        if (this._initial.value !== val) {
            this._initial.next(val)
        }
    }
    public get initial(): SupplierOrder {
        return this._initial.value
    }
    private _initial = new BehaviorSubject<SupplierOrder>(null)

    @Input()
    public set orderId(val: number) {
        if (this._orderId.value !== val) {
            this._orderId.next(val)
        }
    }
    public get orderId(): number {
        return this._orderId.value
    }
    private _orderId = new BehaviorSubject<number>(null)

    @Output()
    public readonly changes = new EventEmitter<void>()

    public _isCollapsed = new BehaviorSubject<boolean>(true)

    public readonly showAllItems = new FormControl(false)

    private _reload = new BehaviorSubject<void>(undefined)

    public readonly possibilities$ = combineLatest({
        sorderId: this._initial.pipe(map(initial => initial.id)),
        reload: this._reload
    }).pipe(
        switchMap(({ sorderId }) => this.supplierOrderRepo.possibilities({ sorder_id: sorderId })),
        shareReplay(1)
    )

    public readonly canCancel$ = this.possibilities$.pipe(
        map(p => p.cancel),
        shareReplay(1)
    )
    public readonly canResend$ = this.possibilities$.pipe(
        map(p => p.resend),
        shareReplay(1)
    )
    public readonly multipleOrders$ = this.possibilities$.pipe(
        map(p => p.multiple_orders),
        shareReplay(1)
    )

    private readonly _all = combineLatest({
        multi: this.multipleOrders$,
        showAllItems: this.showAllItems.valueChanges.pipe(
            startWith(null),
            map(_ => this.showAllItems.value)
        ) as Observable<boolean>
    }).pipe(
        map(({ multi, showAllItems }) => !multi || showAllItems),
        shareReplay(1)
    )

    // TODO: filter items by order
    private readonly full = combineLatest({
        initial: this._initial,
        orderId: this._orderId,
        reload: this._reload,
        all: this._all
    }).pipe(
        filter(({ initial, orderId }) => !!(initial && orderId)),
        switchMap(({ initial, orderId, all }) =>
            this.supplierOrderRepo.get_specialized(
                { id: initial.id, customer_order_id: orderId, all },
                { loadFields: FULL_FIELDS }
            )
        ),
        shareReplay(1)
    )

    public readonly sorder$ = this._isCollapsed.pipe(
        switchMap(isCollapsed => {
            if (isCollapsed) {
                return this._initial
            } else {
                return this.full
            }
        }),
        shareReplay(1)
    )

    public readonly status$ = this.sorder$.pipe(
        map(sorder => sorder.status.value),
        shareReplay(1)
    )

    public readonly collapsed$ = this.sorder$.pipe(
        map(sorder => {
            const expanded = !!sorder.error || sorder.status.value === "INTERVENTION"
            return !expanded
        }),
        shareReplay(1)
    )

    public readonly transactions$ = this.sorder$.pipe(
        map(sorder => {
            if (sorder.transactions) {
                return sorder.transactions.slice(0).sort((a, b) => a.created_time.getTime() - b.created_time.getTime())
            }
            return []
        }),
        shareReplay(1)
    )

    public readonly buttonBarVisible$ = combineLatest({
        canCancel: this.canCancel$,
        canResend: this.canResend$
    }).pipe(
        map(({ canCancel, canResend }) => canCancel || canResend),
        shareReplay(1)
    )

    private filter = this.svc.orderSrc.filterChanges.pipe(
        startWith(null),
        map(_ => this.svc.orderSrc.filter)
    )
    public readonly isFiltered$ = combineLatest({
        filter: this.filter,
        initial: this._initial
    }).pipe(map(({ filter, initial }) => filter && (filter as any)["sorders.id"] === initial.id))

    public readonly busy$ = new BehaviorSubject(false)
    public readonly selectedSupplierInvoiceItemId = new FormControl()
    public pairingInvoiceItemId: number
    public invoiceItemFilter: any
    public transactionDetailsId: number
    public readonly invoiceItemFields = INVOICE_ITEM_FIELDS

    public constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(SupplierOrderRepo) private readonly supplierOrderRepo: SupplierOrderRepo,
        @Inject(SupplierInvoiceItemRepo) private readonly supplierInvoiceItemRepo: SupplierInvoiceItemRepo,
        @Inject(SupplierInvoiceItemRepoSource) public readonly supplierInvoiceItemSrc: SupplierInvoiceItemRepoSource,
        @Inject(OrderDetailsService) private readonly svc: OrderDetailsService
    ) {}

    public doStartInvoicePairing(event: Event, soitem: SupplierOrderItem) {
        this.selectedSupplierInvoiceItemId.setValue(null)
        this.invoiceItemFilter = {
            supplier_order_item_id: null,
            supplier_product_id: soitem.product_id
        }
        this.pairingInvoiceItemId = soitem.id
    }

    public doStopInvoicePairing(event: Event, soitem: SupplierOrderItem) {
        this.pairingInvoiceItemId = null
        this.selectedSupplierInvoiceItemId.setValue(null)
    }

    public doSaveInvoicePairing(event: Event, soitem: SupplierOrderItem) {
        this.busy$.next(true)
        this.supplierInvoiceItemRepo
            .save({ data: { id: this.selectedSupplierInvoiceItemId.value, supplier_order_item_id: soitem.id } })
            .pipe(this.toast.catchError(() => this.busy$.next(false)))
            .subscribe(() => {
                this._reload.next()
                this.doStopInvoicePairing(event, soitem)
                this.busy$.next(false)
            })
    }

    public doCancel() {
        this.toast.error("Fejlesztés folyamatban")
    }

    public doResend() {
        this._initial
            .pipe(
                take(1),
                switchMap(initial =>
                    this.supplierOrderRepo.resend_order({ sorder_id: initial.id }).pipe(this.toast.catchError())
                )
            )
            .subscribe(() => {
                this._reload.next()
            })
    }

    public doFilterGridBySorder(sorder: SupplierOrder) {
        this.svc.orderSrc.filter = { ...this.svc.orderSrc.filter, "sorders.id": sorder.id } as any
    }

    public doRemoveGridFilterBySorder(sorder: SupplierOrder) {
        const filter = { ...this.svc.orderSrc.filter } as any
        if (filter["sorders.id"] === sorder.id) {
            delete filter["sorders.id"]
        }
        this.svc.orderSrc.filter = filter
    }

    public toggleTransDetails(transId: number) {
        if (this.transactionDetailsId === transId) {
            this.transactionDetailsId = null
        } else {
            this.transactionDetailsId = transId
        }
    }

    public doResolveIntervention(transId: number) {
        concat(
            this.supplierOrderRepo.resolve_intervention({ transaction_id: transId }).pipe(this.toast.catchError()),
            this.svc.supplierSendOrders()
        ).subscribe(() => {
            this._reload.next()
            this.changes.next()
        })
    }
}
