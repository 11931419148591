import { Inject, Injectable } from "@angular/core"
import { FormGroup } from "@angular/forms"

import {
    BehaviorSubject,
    combineLatest,
    map,
    Observable,
    of,
    shareReplay,
    startWith,
    Subscriber,
    switchMap,
    take,
    tap
} from "rxjs"

import { ToastService } from "@anzar/core"

import { OrderRepo } from "@backend/order.api"

import { PartnerService } from "../../common.module"

const MAX_STEP = 2

export interface StepHandler {
    stepIndex: number
    form: FormGroup
    // save(orderId: number | null, form: FormGroup): Observable<number>
    dataForSave(form: FormGroup): Observable<object>
}

@Injectable()
export class OrderWizardService {
    public readonly busy$ = new BehaviorSubject<boolean>(false)

    public readonly handler$ = new BehaviorSubject<StepHandler | null>(null)

    public readonly form$ = this.handler$.pipe(map(handler => handler?.form))

    public readonly merchant$ = this.partnerSvc.allPartners$.pipe(
        map(partners => partners.filter(p => p.internal_id === "europeer")[0])
    )

    public readonly dirty$ = combineLatest({ form: this.form$, busy: this.busy$ }).pipe(
        switchMap(({ form, busy }) => {
            if (!form || busy) {
                return of(true)
            } else {
                return form.valueChanges.pipe(
                    startWith(null),
                    map(() => form.dirty)
                )
            }
        }),
        shareReplay(1)
    )

    public readonly valid$ = this.form$.pipe(
        switchMap(form => {
            if (!form) {
                return of(false)
            } else {
                return form.statusChanges.pipe(
                    startWith(null),
                    map(() => form.valid)
                )
            }
        }),
        shareReplay(1)
    )

    public readonly currentStep$ = new BehaviorSubject<number>(0)

    public readonly hasNext$ = combineLatest({ form: this.form$, currentStep: this.currentStep$ }).pipe(
        map(({ form, currentStep }) => form && currentStep < MAX_STEP),
        shareReplay(1)
    )

    public readonly hasPrev$ = combineLatest({ form: this.form$, currentStep: this.currentStep$ }).pipe(
        map(({ form, currentStep }) => form && currentStep > 0),
        shareReplay(1)
    )

    public readonly orderId$ = new BehaviorSubject<number | null>(null)

    public canDelete$ = this.orderId$.pipe(map(orderId => !!orderId))

    public readonly handlers: StepHandler[] = []

    private dataForSave: object = {}

    constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService
    ) {}

    public save() {
        this.busy$.next(true)

        return this.storeStepData().pipe(
            switchMap(() => this.orderRepo.create({ data: this.dataForSave })),
            this.toast.catchError(() => this.busy$.next(false)),
            tap(orderId => {
                if (this.orderId$.value !== orderId) {
                    this.orderId$.next(orderId)
                }
                this.busy$.next(false)
            })
        )
    }

    public storeStepData() {
        return this.handler$.pipe(
            take(1),
            switchMap(handler => handler.dataForSave(handler.form).pipe(take(1))),
            this.toast.catchError(),
            tap(data => (this.dataForSave = { ...this.dataForSave, ...data }))
        )
    }

    public nextStep() {
        return this.storeStepData().pipe(map(() => this.go(1)))
        // return this.save().pipe(map(() => this.go(1)))
    }

    public prevStep() {
        return new Observable((sub: Subscriber<number>) => {
            sub.next(this.go(-1))
            sub.complete()
        })
    }

    private go(dir: 1 | -1) {
        const current = this.currentStep$.value
        const next = current + dir
        if (next >= 0 && next <= MAX_STEP) {
            this.handler$.next(this.handlers[next])
            this.currentStep$.next(next)
        }
        return this.currentStep$.value
    }

    public setStepHandler(handler: StepHandler) {
        this.handlers[handler.stepIndex] = handler
        this.handler$.next(handler)
    }
}
