import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarTaskModule } from "@pyzar/task.module"

import { EuropeerCommonModule } from "../common.module"
import { BgTaskListComponent } from "./bgtask-list.component"
import { BgTaskScreen } from "./bgtask.screen"

export const ROUTES: Route[] = [{ path: "bgtask", component: BgTaskScreen }]

@NgModule({
    imports: [CommonModule, NzModule, PyzarTaskModule, EuropeerCommonModule],
    declarations: [BgTaskScreen, BgTaskListComponent],
    exports: [BgTaskScreen, BgTaskListComponent]
})
export class BgTaskModule {}
