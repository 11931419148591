import { Component, Inject, ViewChild } from "@angular/core"

import { SelectionEvent, ToastService } from "@anzar/core"

import { Category, CategoryMapRepo, ExternalCategory } from "@backend/category.api"
import { Partner } from "@backend/partner.api"

import { PartnerService } from "../../common.module/partner.service"
import { CategoryTreeComponent } from "../category-tree.component"
import { ExternalCategoryTreeComponent } from "./external-category-tree.component"
import { ManualImportService } from "./manual-import.component"

@Component({
    selector: ".eur-external-categories",
    templateUrl: "./external-categories.component.pug"
})
export class ExternalCategoriesComponent {
    @ViewChild("extTree", { static: false, read: ExternalCategoryTreeComponent })
    public readonly extTree: ExternalCategoryTreeComponent
    @ViewChild("intTree", { static: false, read: CategoryTreeComponent }) public readonly intTree: CategoryTreeComponent

    public _extCategory: ExternalCategory
    public _intCategory: Category

    public statusText: string
    private preventSelectionChange: number = 0

    public constructor(
        @Inject(CategoryMapRepo) private readonly catMapRepo: CategoryMapRepo,
        @Inject(ManualImportService) private readonly manualImportSvc: ManualImportService,
        @Inject(ToastService) private readonly toastSvc: ToastService,
        @Inject(PartnerService) public readonly partnerSvc: PartnerService
    ) {}

    public isCategoryUpload(partner: Partner) {
        return this.partnerSvc.hasTrait(partner, "MerchantCategoryUploadTrait")
    }

    public onExternalCategorySelect(category: ExternalCategory) {
        this._extCategory = category
        // this.cdr.markForCheck()

        this._intCategory = null
        if (!category) {
            return
        }

        const selection = this.intTree.treeCmp.selection
        if (selection.items.length > 0) {
            this.preventSelectionChange += 1
            selection.clear()
        }

        this.catMapRepo.get_mapped_paths({ external_id: category.id }).subscribe(paths => {
            const expanded: { [key: number]: any } = {}
            const selected: number[] = []

            for (const path of paths) {
                selected.push(path[path.length - 1])
                let root = expanded
                for (const entry of path) {
                    root[entry] = root[entry] || {}
                    root = root[entry]
                }
            }

            if (selected.length) {
                this.intTree.treeCmp.expandItems(expanded, true).subscribe(v => {
                    this.intTree.treeCmp.selection.setSelected(selected, "program")
                })
            } else {
                // this.intTree.treeCmp.collapseAll().subscribe()
            }
        })
    }

    public onCategorySelect(categories: SelectionEvent<Category>) {
        if (this.preventSelectionChange > 0) {
            this.preventSelectionChange -= 1
            return
        }

        if (categories.origin.includes("mouse") || categories.removed.length > 0) {
            const categoryIds = categories.map(v => Number(v.id))
            this.catMapRepo.assign({ category_ids: categoryIds, external_id: this._extCategory.id }).subscribe(() => {
                this._extCategory.mapped_categories = categories
                for (const assign of categories) {
                    this.toastSvc.info(
                        `<span style="color:#00bcd4">[${this._extCategory.name}] = [${assign.name}]</span>`,
                        {
                            align: "bottom left",
                            autohide: 5
                        }
                    )
                }

                for (const remove of categories.removed) {
                    this.toastSvc.info(
                        `<span style="color:#e53935">[${this._extCategory.name}] ≠ [${remove.name}]</span>`,
                        {
                            align: "bottom left",
                            autohide: 5
                        }
                    )
                }
            })
        }
    }

    public beginManualCategoryImport(event: Event, partner: Partner) {
        const target: HTMLElement = (event.target as HTMLElement).parentElement.parentElement
        this.manualImportSvc.show(target, partner).subscribe(_ => {
            this.extTree.reload()
        })
    }
}
