import { Inject, Injectable } from "@angular/core"

import { switchMap, take } from "rxjs/operators"

import { FileDownloadService, ToastService } from "@anzar/core"

import { Invoice, InvoiceRepo } from "@backend/accounting.api"

import { BACKEND_BASE_URL } from "../common.module"

@Injectable({ providedIn: "root" })
export class AccountingService {
    public constructor(
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(InvoiceRepo) private readonly invoiceRepo: InvoiceRepo,
        @Inject(ToastService) private readonly toast: ToastService
    ) {}

    public donwloadInvoiceById(id: number) {
        return this.invoiceRepo.get({ id: id }, { loadFields: ["id", "secret"] }).pipe(
            this.toast.catchError(),
            switchMap((invoice: Invoice) =>
                this.downloader
                    .download(this._url(invoice.secret))
                    .pipe(this.toast.handleFileDownload({ align: "bottom center" }))
            )
        )
    }

    public downloadInvoiceBySecret(secret: string) {
        this.downloader
            .download(this._url(secret))
            .pipe(this.toast.handleFileDownload({ align: "bottom center" }), take(1))
            .subscribe()
    }

    private _url(secret: string): string {
        return `${this.baseUrl}/get/invoice/${secret}.pdf`
    }
}
