import { NgModule } from "@angular/core"
import { Invoice, InvoiceSynced, InvoiceDesired, InvoiceItem, InvoiceItemShippingItem, InvoiceAncestor, InvoiceHistory, Payment, InvoiceNotification, Spending, CurrencyConversionRate, UploadPaymentsTrait, InvoicingTrait, AccountingRepo, InvoiceRepo, InvoiceRepoSource } from "./__anzar_rpc_output"

export { Invoice, InvoiceSynced, InvoiceDesired, InvoiceItem, InvoiceItemShippingItem, InvoiceAncestor, InvoiceHistory, Payment, InvoiceNotification, Spending, CurrencyConversionRate, UploadPaymentsTrait, InvoicingTrait, AccountingRepo, InvoiceRepo, InvoiceRepoSource }


@NgModule({
    providers: [
        InvoiceRepoSource,
        AccountingRepo,
        InvoiceRepo
    ],
})
export class AccountingApi {}