import { NgModule } from "@angular/core"
import { SupplierProduct, Warehouse, WarehouseStock, WarehouseReservation, ProductInput, ProductImportError, SupplierOrder, SupplierOrder_CustomerOrder, SupplierOrderItem, TransactionAction, TransactionErrorReason, SupplierOrderTransaction, SupplierOrderTransactionItem, SupplierOrderTransactionLog, SupplierInvoice, SupplierInvoiceItem, SimilarProduct, SimilarProductEntry, SimilarProductIgnore, SupplierProductsByEan, SupplierProductsBySku, WarehouseRepo, WarehouseStockRepo, SupplierInvoiceRepo, SupplierInvoiceItemRepo, SimilarProductRepo, SupplierProductRepo, ProductImportErrorRepo, ProductImportErrorRepoSource, SimilarProductRepoSource, SupplierInvoiceItemRepoSource, SupplierInvoiceRepoSource, SupplierProductRepoSource, WarehouseRepoSource, WarehouseStockRepoSource } from "./__anzar_rpc_output"

export { SupplierProduct, Warehouse, WarehouseStock, WarehouseReservation, ProductInput, ProductImportError, SupplierOrder, SupplierOrder_CustomerOrder, SupplierOrderItem, TransactionAction, TransactionErrorReason, SupplierOrderTransaction, SupplierOrderTransactionItem, SupplierOrderTransactionLog, SupplierInvoice, SupplierInvoiceItem, SimilarProduct, SimilarProductEntry, SimilarProductIgnore, SupplierProductsByEan, SupplierProductsBySku, WarehouseRepo, WarehouseStockRepo, SupplierInvoiceRepo, SupplierInvoiceItemRepo, SimilarProductRepo, SupplierProductRepo, ProductImportErrorRepo, ProductImportErrorRepoSource, SimilarProductRepoSource, SupplierInvoiceItemRepoSource, SupplierInvoiceRepoSource, SupplierProductRepoSource, WarehouseRepoSource, WarehouseStockRepoSource }


@NgModule({
    providers: [
        ProductImportErrorRepoSource,
        SimilarProductRepoSource,
        SupplierInvoiceItemRepoSource,
        SupplierInvoiceRepoSource,
        SupplierProductRepoSource,
        WarehouseRepoSource,
        WarehouseStockRepoSource,
        ProductImportErrorRepo,
        SimilarProductRepo,
        SupplierInvoiceItemRepo,
        SupplierInvoiceRepo,
        SupplierProductRepo,
        WarehouseRepo,
        WarehouseStockRepo
    ],
})
export class SupplierApi {}