import { Component, ContentChild, Input, TemplateRef } from "@angular/core"

import { TraitSelector } from "../../partner.service"
import { PartnerWithCardinality } from "./partner-switch.component"

@Component({
    selector: ".eur-partner-switch-panel",
    templateUrl: "./partner-switch-panel.component.pug"
})
export class PartnerSwitchPanelComponent {
    @Input() public readonly traits: TraitSelector[]

    @ContentChild("content", { static: true, read: TemplateRef })
    public readonly content: TemplateRef<PartnerWithCardinality>

    public selectedPartner: PartnerWithCardinality
}
