import { Component, EventEmitter, Inject, Input, Output, Self, ViewChild } from "@angular/core"

import { take } from "rxjs/operators"

import { SelectionModel, TreeComponent } from "@anzar/core"

import { Category, CategoryRepo, CategoryRepoSource } from "@backend/category.api"

@Component({
    selector: ".eur-category-tree",
    templateUrl: "./category-tree.component.pug",
    exportAs: "eurCategoryTree"
})
export class CategoryTreeComponent {
    @ViewChild("tree", { read: TreeComponent, static: true })
    public readonly treeCmp: TreeComponent

    @Input() public directSelect: boolean = false

    @Output()
    public readonly selectionChange = new EventEmitter<Category[]>()

    public root: Category

    public constructor(
        @Inject(CategoryRepoSource) public readonly categorySrc: CategoryRepoSource,
        @Inject(CategoryRepo) public readonly categoryRepo: CategoryRepo,
        @Inject(SelectionModel) @Self() public readonly selection: SelectionModel
    ) {
        categoryRepo
            .search({ filter: { parent_id: null }, count: 1 })
            .pipe(take(1))
            .subscribe(root => {
                this.root = root[0]
            })
    }

    public reload() {
        this.treeCmp.reload()
    }
}
