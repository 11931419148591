import { Attribute, Component, Inject } from "@angular/core"
import { FormArray, FormGroup } from "@angular/forms"

import { Observable, of } from "rxjs"

import { extraItemFormModel } from "../extra-item-form.component"
import { OrderWizardService, StepHandler } from "./order-wizard.service"

@Component({
    selector: "eur-order-wizard-step-extra-items",
    templateUrl: "./step-extra-items.component.pug"
})
export class OrderWizardStepExtraItemsComponent implements StepHandler {
    readonly form = new FormGroup({
        extra_items: new FormArray([])
    })

    public get itemsControl() {
        return this.form.get("extra_items") as FormArray
    }

    readonly stepIndex: number

    constructor(
        @Inject(OrderWizardService) public readonly svc: OrderWizardService,
        @Attribute("stepIndex") stepIndex: string
    ) {
        this.stepIndex = Number(stepIndex)
        svc.setStepHandler(this)
    }

    // public save(orderId: number | null, form: FormGroup): Observable<number> {
    //     return this.orderRepo.save_wizard({ step: this.stepIndex + 1, order_id: orderId, data: form.value })
    // }
    public dataForSave(form: FormGroup): Observable<object> {
        return of(form.value)
    }

    public doAddExtra() {
        this.itemsControl.push(extraItemFormModel())
    }

    public doDeleteExtra(index: number) {
        this.itemsControl.removeAt(index)
    }
}
