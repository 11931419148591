import { Component, Inject } from "@angular/core"
import { NavigationStart, Router } from "@angular/router"

@Component({
    selector: "app",
    templateUrl: "./app.template.pug"
})
export class AppComponent {
    public constructor(@Inject(Router) router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart && event.navigationTrigger === "imperative" && event.url === "/") {
                router.navigate(["/orders"])
            }
        })
    }
}
