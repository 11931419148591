import { NgModule } from "@angular/core"
import { MerchantProduct, MerchantDiscountType, MerchantProductDiscount, MerchantOrderTrait, MerchantProductTrait, MerchantJointProductTrait, MerchantProductRepo, MerchantProductDiscountRepo, MerchantProductDiscountRepoSource, MerchantProductRepoSource } from "./__anzar_rpc_output"

export { MerchantProduct, MerchantDiscountType, MerchantProductDiscount, MerchantOrderTrait, MerchantProductTrait, MerchantJointProductTrait, MerchantProductRepo, MerchantProductDiscountRepo, MerchantProductDiscountRepoSource, MerchantProductRepoSource }


@NgModule({
    providers: [
        MerchantProductDiscountRepoSource,
        MerchantProductRepoSource,
        MerchantProductDiscountRepo,
        MerchantProductRepo
    ],
})
export class MerchantApi {}