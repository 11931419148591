import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"
import { FsModule } from "@pyzar/fs.module"

import { CategoryApi } from "@backend/category.api"

import { EuropeerCommonModule } from "../common.module"
import { PartnerModule } from "../partner.module"
import { CategoryEditorComponent } from "./category-editor.component"
import { CategoryFormComponent } from "./category-form.component"
import { CategoryTreeComponent } from "./category-tree.component"
import { CategoryUploaderService, CategoryUploaderWndComponent } from "./category-uploader.component"
import { CategoryScreen } from "./category.screen"
import { ExternalCategoriesComponent } from "./pairing/external-categories.component"
import { ExternalCategoryTreeComponent } from "./pairing/external-category-tree.component"
import { ManualImportService, ManualImportWndComponent } from "./pairing/manual-import.component"

// import { AssignCategoryTreeComponent } from "./pairing/category-tree.component"

export const ROUTES: Route[] = [{ path: "category", component: CategoryScreen }]

@NgModule({
    imports: [CommonModule, NzModule, EuropeerCommonModule, PyzarCommonModule, CategoryApi, PartnerModule, FsModule],
    declarations: [
        CategoryScreen,
        CategoryEditorComponent,
        CategoryFormComponent,
        CategoryTreeComponent,

        ExternalCategoriesComponent,
        ExternalCategoryTreeComponent,
        // AssignCategoryTreeComponent,
        ManualImportWndComponent,

        CategoryUploaderWndComponent
    ],
    exports: [
        CategoryScreen,
        CategoryEditorComponent,
        CategoryFormComponent,
        CategoryTreeComponent,

        ExternalCategoriesComponent,
        ExternalCategoryTreeComponent,

        ManualImportWndComponent,
        CategoryUploaderWndComponent
    ],
    providers: [ManualImportService, CategoryUploaderService],
    entryComponents: [ManualImportWndComponent, CategoryUploaderWndComponent]
})
export class CategoryModule {}
