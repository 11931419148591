import { Component, Inject, Input } from "@angular/core"

import { LoadFields } from "@anzar/core"

import { ProductImportError, ProductImportErrorRepoSource } from "@backend/supplier.api"

const FIELDS: LoadFields<ProductImportError> = ["partner_entity_id", "message"]

@Component({
    selector: ".eur-supplier-product-errors",
    templateUrl: "./supplier-errors.component.pug"
})
export class SupplierProductErrorsComponent {
    @Input() public partnerId: number

    public readonly fields = FIELDS

    public constructor(@Inject(ProductImportErrorRepoSource) public readonly source: ProductImportErrorRepoSource) {}
}
