import { Price, VatRate } from "@backend/common.api"

export interface DstCurrency {
    currency: string
    rate: number
}

export function convertPrice(price: Price, to: DstCurrency): Price {
    price = normalizePrice(price)
    if (!price) {
        return null
    }

    if (price.currency === to.currency) {
        return price
    } else {
        return new Price({
            net: price.net / to.rate,
            gross: price.gross / to.rate,
            currency: to.currency,
            vat_id: price.vat_id
        })
    }
}

export function normalizePrice(price: Price) {
    if (!price) {
        return null
    }

    if (!price.vat_id) {
        return price
    }

    if (!price.net) {
        if (!price.gross) {
            return null
        }

        const vat = VatRate.DATA.data.find(v => v.value === price.vat_id)

        return new Price({
            net: netFromGross(price.gross, vat.amount),
            gross: price.gross,
            currency: price.currency,
            vat_id: vat.value
        })
    }

    if (!price.gross) {
        if (!price.net) {
            return null
        }

        const vat = VatRate.DATA.data.find(v => v.value === price.vat_id)

        return new Price({
            net: price.net,
            gross: grossFromNet(price.net, vat.amount),
            currency: price.currency,
            vat_id: vat.value
        })
    }

    return price
}

function grossFromNet(amount: number, vat: number): number {
    return amount * ((vat + 100.0) / 100.0)
}

function netFromGross(amount: number, vat: number): number {
    return amount / ((vat + 100.0) / 100.0)
}

export function marginOf(sellPrice: Price, supplyPrice: Price): number {
    sellPrice = normalizePrice(sellPrice)
    supplyPrice = normalizePrice(supplyPrice)

    if (!sellPrice || !supplyPrice) {
        return 0
    }

    return sellPrice.net ? Math.round(((sellPrice.net - supplyPrice.net) / sellPrice.net) * 100.0) : 0
}
