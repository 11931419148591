import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core"
import { FormControl } from "@angular/forms"

import { BehaviorSubject, Subscription } from "rxjs"
import { debounceTime } from "rxjs/operators"

import { ToastService } from "@anzar/core"

import { SimilarProduct, SimilarProductIgnore, SimilarProductRepo, SupplierProduct } from "@backend/supplier.api"

import { ProductSheetService } from "../product-sheet.component"

export class SimilarProductState {
    public merged: boolean = false
    public products: ProductState[] = []
    public selected = new FormControl()

    public constructor(public readonly similar: SimilarProduct) {
        const similars = similar.similars.slice(0)
        similars.sort((a, b) => {
            const a_sp = a.supplier_product
            const b_sp = b.supplier_product
            if (a_sp.product_id === b_sp.product_id) {
                if (a_sp.partner.sequence === b_sp.partner.sequence) {
                    return a_sp.id - b_sp.id
                } else {
                    return a_sp.partner.sequence - b_sp.partner.sequence
                }
            } else {
                return a_sp.product_id - b_sp.product_id
            }
        })
        this.products = similars.map(s => new ProductState(s.supplier_product, s.ignore))
    }
}

export class ProductState {
    public selectedIndex?: number = null
    public ignoredFields: { [key: string]: boolean } = {}
    public totallyIgnored: boolean = false

    public constructor(
        public readonly product: SupplierProduct,
        ignore?: SimilarProductIgnore
    ) {
        if (ignore) {
            if (ignore.fields) {
                for (const k of ignore.fields) {
                    this.ignoredFields[k] = true
                }
            }
            this.totallyIgnored = ignore.totally
        }
    }

    public toggleTab(idx: number) {
        if (this.selectedIndex === idx) {
            this.selectedIndex = null
        } else {
            this.selectedIndex = idx
        }
    }
}

class CheckedField {
    public constructor(
        public readonly name: string,
        public readonly label: string
    ) {}
}

const CHECKED_FIELDS: CheckedField[] = [
    new CheckedField("manufacturer_sku", "Gyártói cikkszám"),
    new CheckedField("source_sku", "Partner cikkszám 1"),
    new CheckedField("source_sku2", "Partner cikkszám 2")
]

@Component({
    selector: ".eur-similar-product",
    templateUrl: "./similar-product.component.pug"
})
export class SimilarProductComponent implements OnChanges, OnDestroy {
    @Input() public state: SimilarProductState

    public readonly checkedFields = CHECKED_FIELDS

    public similar: SimilarProduct
    public merged: boolean = false
    public mergedProductId: number | null = null
    public products: ProductState[] = []
    public selected = new FormControl()

    public readonly merging$ = new BehaviorSubject(false)
    private _selectedSub?: Subscription
    private _selected: Array<number>

    public constructor(
        @Inject(SimilarProductRepo) private readonly repo: SimilarProductRepo,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ProductSheetService) private readonly productSheet: ProductSheetService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ("state" in changes) {
            const state = changes.state.currentValue as SimilarProductState
            this.similar = state.similar
            this.merged = state.merged
            this.products = state.products
            this.selected = state.selected
            this._selected = state.selected.value || []

            if (this._selectedSub) {
                this._selectedSub.unsubscribe()
            }
            this._selectedSub = this.selected.valueChanges
                .pipe(debounceTime(100))
                .subscribe(this._selectionChanges.bind(this))

            if (this.selected.value == null || this.selected.value.length === 0) {
                const counts: { [key: number]: { id: number; c: number } } = {}
                for (const prod of state.products) {
                    if (counts[prod.product.product_id] == null) {
                        counts[prod.product.product_id] = { id: prod.product.id, c: 0 }
                    } else {
                        counts[prod.product.product_id].c++
                    }
                }

                const initial = Object.values(counts).sort((a, b) => b.c - a.c)[0]
                if (initial && initial.c > 1) {
                    this.selected.setValue([initial.id])
                }
            }
        }
    }

    public toggleTab(state: ProductState, tabIdx: number) {
        state.toggleTab(tabIdx)
    }

    public showProductSheet(productId: number) {
        this.productSheet.show(productId).subscribe()
    }

    public doMerge() {
        this.merging$.next(true)
        this.repo
            .merge({ supplier_product_ids: this.selected.value })
            .pipe(this.toast.catchError(() => this.merging$.next(false)))
            .subscribe(mergedProductId => {
                this.merging$.next(false)
                for (const prod of this.products) {
                    prod.selectedIndex = null
                }
                this.merged = true
                this.mergedProductId = mergedProductId
            })
    }

    public doCancel() {
        for (const prod of this.products) {
            prod.selectedIndex = null
        }
        this.selected.setValue(null)
    }

    public doIgnoreField(state: ProductState, field: string) {
        this.repo
            .ignore_field({ supplier_product_id: state.product.id, field: field })
            .pipe(this.toast.catchError())
            .subscribe(() => {
                state.ignoredFields[field] = true
            })
    }

    public doAllowField(state: ProductState, field: string) {
        this.repo
            .allow_field({ supplier_product_id: state.product.id, field: field })
            .pipe(this.toast.catchError())
            .subscribe(() => {
                delete state.ignoredFields[field]
            })
    }

    public doIgnoreProduct(state: ProductState) {
        const totally = !state.totallyIgnored
        this.repo
            .ignore({ supplier_product_id: state.product.id, totally })
            .pipe(this.toast.catchError())
            .subscribe(() => {
                state.totallyIgnored = totally
            })
    }

    private _selectionChanges(values: Array<number>) {
        values = values || []
        const added_sp_id = values.filter(v => !this._selected.includes(v))
        const removed_sp_id = this._selected.filter(v => !values.includes(v))
        const added_prod_id: Array<number> = []
        const removed_prod_id: Array<number> = []

        for (const sp of this.products) {
            if (!sp.product.product_id) {
                continue
            }

            if (added_sp_id.includes(sp.product.id) && !added_prod_id.includes(sp.product.product_id)) {
                added_prod_id.push(sp.product.product_id)
            }

            if (removed_sp_id.includes(sp.product.id) && !removed_prod_id.includes(sp.product.product_id)) {
                removed_prod_id.push(sp.product.product_id)
            }
        }

        const missing_sp_id: Array<number> = []
        for (const sp of this.products) {
            if (!sp.product.product_id) {
                continue
            }

            if (
                added_prod_id.includes(sp.product.product_id) &&
                !values.includes(sp.product.id) &&
                !missing_sp_id.includes(sp.product.id)
            ) {
                missing_sp_id.push(sp.product.id)
            }
        }

        const extra_sp_id: Array<number> = []
        for (const sp of this.products) {
            if (!sp.product.product_id) {
                continue
            }

            if (
                removed_prod_id.includes(sp.product.product_id) &&
                values.includes(sp.product.id) &&
                !extra_sp_id.includes(sp.product.id)
            ) {
                extra_sp_id.push(sp.product.id)
            }
        }

        if (missing_sp_id.length > 0 || extra_sp_id.length > 0) {
            const newValue = values.concat(missing_sp_id).filter(v => !extra_sp_id.includes(v))
            this._selected = newValue
            this.selected.setValue(newValue)
        } else {
            this._selected = values
        }
    }

    public ngOnDestroy(): void {
        this._selectedSub?.unsubscribe()
    }
}
