import { HttpErrorResponse } from "@angular/common/http"
import { Inject, Injectable } from "@angular/core"

import { EMPTY, Observable, of, Subject } from "rxjs"
import { catchError, filter, switchMap, tap } from "rxjs/operators"

import { DialogService } from "@anzar/core"
import { RpcInterceptor, Transaction } from "@anzar/rpc"

@Injectable()
export class RpcErrorHandler<T = any> extends RpcInterceptor<T> {
    private readonly errorFeed = new Subject<HttpErrorResponse>()
    private errorVisible = false

    public constructor(@Inject(DialogService) private readonly dialog: DialogService) {
        super()

        this.errorFeed
            .pipe(
                filter(() => !this.errorVisible),
                switchMap(error => {
                    this.errorVisible = true
                    switch (error.status) {
                        case 0:
                        case 404:
                        case 503:
                            return this.dialog.error(
                                "Hálózati hiba",
                                "A szerver nem elérhető, próbáld meg később újra."
                            ).onClose

                        case 400:
                            return this.dialog.error("Hálózati hiba", "Hiba történt a hívás közben.").onClose

                        case 401:
                            return this.dialog.error(
                                "Jogosultsági hiba",
                                "A rendszer idő közben kijelentkeztett. Kérlek indítsd újra a folytatáshoz."
                            ).onClose

                        default:
                            return this.dialog.error("Hálózati hiba", `${error.status}: ${error.statusText}`).onClose
                    }
                }),
                tap(_ => {
                    this.errorVisible = false
                })
            )
            .subscribe()
    }

    public intercept(transaction: Transaction<T>, source: Observable<T>): Observable<T> {
        return source.pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    this.errorFeed.next(err)
                    return EMPTY
                }

                return of(err)
            })
        )
    }
}
