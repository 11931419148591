import { NgModule } from "@angular/core"
import { PartnerTraitInfo, PartnerTrait, Partner, AuthToken, ProductQuerySource, ProductQueryLink, PartnerRepo, ProductQueryRepo, ProductQueryLinkRepo, PartnerRepoSource, ProductQueryLinkRepoSource } from "./__anzar_rpc_output"

export { PartnerTraitInfo, PartnerTrait, Partner, AuthToken, ProductQuerySource, ProductQueryLink, PartnerRepo, ProductQueryRepo, ProductQueryLinkRepo, PartnerRepoSource, ProductQueryLinkRepoSource }


@NgModule({
    providers: [
        PartnerRepoSource,
        ProductQueryLinkRepoSource,
        PartnerRepo,
        ProductQueryLinkRepo,
        ProductQueryRepo
    ],
})
export class PartnerApi {}