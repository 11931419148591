import { Component, Inject } from "@angular/core"

import { delay, filter, of, switchMap, tap } from "rxjs"

import { FileUploaderService, FsService } from "@pyzar/fs.module"

import { ComplaintEntryComponent } from "./_abstract"
import { ComplaintService } from "./complaint.service"

@Component({
    selector: ".eur-complaint-file",
    templateUrl: "./complaint-file.component.pug",
    providers: [FileUploaderService]
})
export class ComplaintFileComponent extends ComplaintEntryComponent<any> {
    public constructor(
        @Inject(ComplaintService) private readonly svc: ComplaintService,
        @Inject(FileUploaderService) private readonly fileUploader: FileUploaderService,
        @Inject(FsService) private readonly fs: FsService
    ) {
        super()
    }

    public doRemove() {
        this.svc.delFile(this.index)
    }

    protected saveEntry(values: any) {
        if (values.file instanceof File) {
            return this.svc.complaintBasic.pipe(
                switchMap(complaint => this.fileUploader.upload(`/complaint/${complaint.id}`)),
                filter(e => e.state === "done"),
                switchMap(() => {
                    const file = this.form.get("file").value
                    return this.svc.saveFile(this.index, { ...values, file }).pipe(
                        delay(100),
                        tap(() => {
                            this.form.get("file").setValue(this.fs.newUploadedFile(file), {
                                emitEvent: false,
                                emitModelToViewChange: true
                            })
                        })
                    )
                })
            )
        }
        return of(null)
    }
}
