import { NgModule } from "@angular/core"
import { BillingInfo, ShippingInfo, Order, OrderItem, OrderExtraItem, OrderHistory, OrderHistoryFile, Shipping, ShippingItem, ShippingTracking, Voucher, VoucherSplit, OrderSync, ComplaintType, Complaint, ComplaintItemStatus, ComplaintItem, ComplaintItemHistory, ComplaintNote, ComplaintFile, ComplaintInvoice, OrderCrediting, ComplaintPossibilities, OrderTodoCounts, OrderHistoryRepo, ShippingRepo, ShippingItemRepo, ComplaintRepo, ComplaintItemRepo, ComplaintItemHistoryRepo, ComplaintNoteRepo, ComplaintFileRepo, OrderCreditingRepo, OrderRepo, OrderItemRepo, ComplaintFileRepoSource, ComplaintItemHistoryRepoSource, ComplaintItemRepoSource, ComplaintNoteRepoSource, ComplaintRepoSource, OrderCreditingRepoSource, OrderItemRepoSource, OrderRepoSource, ShippingItemRepoSource } from "./__anzar_rpc_output"

export { BillingInfo, ShippingInfo, Order, OrderItem, OrderExtraItem, OrderHistory, OrderHistoryFile, Shipping, ShippingItem, ShippingTracking, Voucher, VoucherSplit, OrderSync, ComplaintType, Complaint, ComplaintItemStatus, ComplaintItem, ComplaintItemHistory, ComplaintNote, ComplaintFile, ComplaintInvoice, OrderCrediting, ComplaintPossibilities, OrderTodoCounts, OrderHistoryRepo, ShippingRepo, ShippingItemRepo, ComplaintRepo, ComplaintItemRepo, ComplaintItemHistoryRepo, ComplaintNoteRepo, ComplaintFileRepo, OrderCreditingRepo, OrderRepo, OrderItemRepo, ComplaintFileRepoSource, ComplaintItemHistoryRepoSource, ComplaintItemRepoSource, ComplaintNoteRepoSource, ComplaintRepoSource, OrderCreditingRepoSource, OrderItemRepoSource, OrderRepoSource, ShippingItemRepoSource }


@NgModule({
    providers: [
        ComplaintFileRepoSource,
        ComplaintItemHistoryRepoSource,
        ComplaintItemRepoSource,
        ComplaintNoteRepoSource,
        ComplaintRepoSource,
        OrderCreditingRepoSource,
        OrderItemRepoSource,
        OrderRepoSource,
        ShippingItemRepoSource,
        ComplaintFileRepo,
        ComplaintItemHistoryRepo,
        ComplaintItemRepo,
        ComplaintNoteRepo,
        ComplaintRepo,
        OrderCreditingRepo,
        OrderHistoryRepo,
        OrderItemRepo,
        OrderRepo,
        ShippingItemRepo,
        ShippingRepo
    ],
})
export class OrderApi {}