export const STATUS_COLORS: { [key: string]: string } = {
    DRAFT: "common",
    PENDING: "common",
    PROGRESS: "info",
    WORKABLE: "dark-info",
    PREPARED: "blue",
    COMPLETED: "confirm",
    CANCELLED: "warn",
    RETURNED: "warn",
    other: "common"
}
