import { Component, Inject } from "@angular/core"

import { Observable, of } from "rxjs"

import { ComplaintEntryComponent } from "./_abstract"
import { ComplaintNoteForm, ComplaintService } from "./complaint.service"

@Component({
    selector: ".eur-complaint-note",
    templateUrl: "./complaint-note.component.pug"
})
export class ComplaintNoteComponent extends ComplaintEntryComponent<ComplaintNoteForm> {
    protected get debounce() {
        return 500
    }

    public constructor(@Inject(ComplaintService) private readonly svc: ComplaintService) {
        super()
    }

    public setIsTodo(value: boolean) {
        this.form.get("is_todo").setValue(value)
    }

    public doRemove() {
        this.svc.delNote(this.index)
    }

    protected saveEntry(values: ComplaintNoteForm): Observable<any> {
        if (!values.text) {
            return of(null)
        }
        return this.svc.saveNote(this.index, values)
    }
}
