import { Component, Inject, ViewChild } from "@angular/core"

import { FileDownloadService, ToastService } from "@anzar/core"

import { Category } from "@backend/category.api"

import { BACKEND_BASE_URL } from "../common.module"
import type { CategoryTreeComponent } from "./category-tree.component"
import { CategoryUploaderService } from "./category-uploader.component"

@Component({
    selector: ".eur-category-editor",
    templateUrl: "./category-editor.component.pug"
})
export class CategoryEditorComponent {
    @ViewChild("tree") public readonly tree: CategoryTreeComponent

    public selected: Category

    public constructor(
        @Inject(FileDownloadService) private readonly fileDownloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string,
        @Inject(CategoryUploaderService) private readonly uploader: CategoryUploaderService
    ) {}

    public doUploadCategories(event: Event) {
        this.uploader.show(event.target as HTMLElement).subscribe(() => {
            this.tree.reload()
        })
    }

    public doDownloadCategories() {
        this.fileDownloader
            .download(`${this.baseUrl}/get/categories`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", message: "Kategóriák excel generálása" }))
            .subscribe()
    }
}
