import { Directive, Input, OnInit } from "@angular/core"
import { FormGroup } from "@angular/forms"

import {
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    mapTo,
    Observable,
    of,
    pairwise,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    timer
} from "rxjs"

import { Destructible } from "@anzar/core"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DeepDiff = require("deep-diff")

const CHANGES = Symbol("CHANGES")

@Directive({})
export abstract class ComplaintEntryComponent<T extends { id?: number }> extends Destructible implements OnInit {
    @Input() public form: FormGroup
    @Input() public index: number

    protected get debounce(): { [key: string]: number } | number {
        return 0
    }

    public readonly values$ = new Subject<T>()
    public readonly id$ = this.values$.pipe(
        map(value => value.id),
        distinctUntilChanged(),
        shareReplay(1)
    )

    public constructor() {
        super()
        // ! kell, e nélkül nem megy
        this.destruct.subscription(this.id$).subscribe()

        let first = true
        this.destruct
            .subscription(this.values$)
            .pipe(
                exhaustMap(values => {
                    if (first) {
                        first = false
                        if (!values.id) {
                            return this.saveEntry(values)
                        }
                    } else {
                        const changes = (values as any)[CHANGES] as string[]
                        // Ha csak az id változik, akkor ne mentse újra
                        if (changes && changes.length === 1 && changes[0] === "id") {
                            return of(null)
                        }
                        return this.saveEntry(values)
                    }
                    return of(null)
                })
            )
            .subscribe()
    }

    public ngOnInit(): void {
        this.values$.next(this.form.value)

        this.destruct
            .subscription(this.form.valueChanges)
            .pipe(
                startWith(this.form.value as T),
                map(v => {
                    return { ...v }
                }),
                pairwise(),
                map(([old, values]) => {
                    const diff = DeepDiff.diff(old, values)
                    if (diff) {
                        const changes = this.changedPaths(diff)
                        values[CHANGES] = changes
                        const debounce = this.debounceTime(changes)
                        return { values, debounce } as const
                    } else {
                        return null
                    }
                }),
                filter(v => v !== null),
                switchMap(({ values, debounce }) => {
                    if (debounce) {
                        return timer(debounce).pipe(mapTo(values))
                    } else {
                        return of(values)
                    }
                })
            )
            .subscribe(this.values$)
    }

    protected debounceTime(changes: string[]): number {
        const debounce = this.debounce
        if (typeof debounce === "number") {
            return debounce
        }

        let result = 0
        for (const path of changes) {
            const time = debounce[path]
            if (typeof time === "number") {
                result = Math.max(result, time)
            }
        }
        return result
    }

    protected changedPaths(diff: any): string[] {
        return diff.filter((v: any) => !!v).map((v: any) => v.path.join("."))
    }

    protected abstract saveEntry(values: T): Observable<any>
}
