import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"
import { FsModule } from "@pyzar/fs.module"

import { AccountingApi } from "@backend/accounting.api"

import { EuropeerCommonModule } from "../common.module"
import { AccountingService } from "./accounting.service"
import { InvoiceBageComponent } from "./invoice-badge.component"
import { PaymentUploadScreen } from "./payment-upload.screen"
import { PaymentsUploadComponent } from "./payment-upload/payments-upload.component"
import { UploadBlockComponent } from "./payment-upload/upload-block.component"
import { UploadResultComponent } from "./payment-upload/upload-result.component"

export { AccountingService }

export const ROUTES: Route[] = [{ path: "accounting/payment-upload", component: PaymentUploadScreen }]

@NgModule({
    imports: [CommonModule, NzModule, AccountingApi, EuropeerCommonModule, PyzarCommonModule, FsModule],
    declarations: [
        PaymentUploadScreen,
        PaymentsUploadComponent,
        UploadBlockComponent,
        UploadResultComponent,
        InvoiceBageComponent
    ],
    exports: [PaymentUploadScreen, PaymentsUploadComponent, UploadResultComponent, InvoiceBageComponent],
    providers: [AccountingService],
    entryComponents: [UploadResultComponent]
})
export class AccountingModule {}
