import { Component, Inject, Input } from "@angular/core"

import { LoadFields } from "@anzar/core"

import { MerchantProduct, MerchantProductRepoSource } from "@backend/merchant.api"

import { ProductSheetService } from "../product.module"

const FIELDS: LoadFields<MerchantProduct> = [
    "id",
    "partner_id",
    "partner_entity_id",
    "product_id",
    "prepare_error",
    "sync_errors",
    { product: ["name", "sku", { manufacturer: ["name"] }] }
]

@Component({
    selector: ".eur-merchant-product-errors",
    templateUrl: "./merchant-errors.component.pug"
})
export class MerchantProductErrorsComponent {
    @Input() public partnerId: number

    public readonly fields = FIELDS

    public constructor(
        @Inject(MerchantProductRepoSource) public readonly source: MerchantProductRepoSource,
        @Inject(ProductSheetService) private readonly psheet: ProductSheetService
    ) {}

    public onRowTap(mp: MerchantProduct) {
        this.psheet.show(mp.product_id).subscribe()
    }
}
