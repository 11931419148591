import { NgModule } from "@angular/core"
import { Country, VatRate, WarrantyDurationUnit, Price, WarrantyDuration, CountryRepo, CountryRepoSource } from "./__anzar_rpc_output"

export { Country, VatRate, WarrantyDurationUnit, Price, WarrantyDuration, CountryRepo, CountryRepoSource }


@NgModule({
    providers: [
        CountryRepoSource,
        CountryRepo
    ],
})
export class CommonApi {}