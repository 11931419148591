import { Component, Inject, Injectable, InjectionToken } from "@angular/core"

import { LayerRef, LayerService, ModalLayer } from "@anzar/core"

const RESULT = new InjectionToken("RESULT")

@Component({
    selector: "eur-payment-upload-result",
    templateUrl: "./upload-result.component.pug"
})
export class UploadResultComponent {
    public readonly errors: string[]
    public readonly successCount: number

    public constructor(
        @Inject(RESULT) result: Array<{ [key: string]: any }>,
        @Inject(LayerRef) private readonly layerRef: LayerRef
    ) {
        this.errors = result.filter(v => !!v.error).map(v => v.error) as any
        this.successCount = result.length - this.errors.length
    }

    public doClose() {
        this.layerRef.hide()
    }
}

@Injectable()
export class UploadResultService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {}

    public show(result: Array<{ [key: string]: any }>) {
        const behavior = new ModalLayer({
            backdrop: { type: "filled", hideOnClick: false },
            closeable: false,
            rounded: 3,
            elevation: 10,
            position: {
                align: "center",
                constraint: {
                    ref: "viewport",
                    inset: 16
                }
            }
        })

        const ref = this.layerSvc.createFromComponent(UploadResultComponent, behavior, null, [
            { provide: RESULT, useValue: result }
        ])

        ref.show()
    }
}
