import { Component, Inject } from "@angular/core"

import { BehaviorSubject, of, shareReplay } from "rxjs"
import { exhaustMap, mapTo, switchMap, take } from "rxjs/operators"

import { FileDownloadService, LoadFields, ToastService } from "@anzar/core"
import { DataSourceDirective } from "@anzar/core"

import { TaskService } from "@pyzar/task.module"

import { Order, OrderRepo, OrderRepoSource } from "@backend/order.api"

import { BACKEND_BASE_URL, PartnerService } from "../common.module"
import { OrderDetailsWndService } from "./details/order-details-wnd.component"
import { STATUS_COLORS } from "./status"
import { OrderWizardWndService } from "./wizard/order-wizard-wnd.component"

const ORDER_FIELDS: LoadFields<Order> = [
    "id",
    "status",
    "final_price_gross",
    "partner_entity_id",
    "partner_orderno",
    "created_time",
    "cancellation_request",
    "currency",
    "payment_status",
    "proforma_invoice_id",
    "pending_todo",
    { partner: ["name"] },
    { shipping_info: ["name"] },
    { billing_info: ["payment_method", "proforma_need"] },
    { shippings: ["delivery_status"] },
    {
        items: [
            "qty_ordered",
            "qty_cancelled",
            "qty_purchased",
            "qty_pending",
            "qty_received",
            "qty_backorder",
            "supply_error",
            "supplier_ids",
            "supplier_available_ids",
            "supply_type"
        ]
    }
]

/*
if (shipping.delivery_status.value === "PREPARED") {
                return shipping
            } else if (shipping.delivery_status.value === "PROGRESS") {
                return shipping
            } else if (shipping.delivery_status.value === "FAILURE") {
                return shipping
            } else if (shipping.delivery_status.value === "SUCCESS") {
                return shipping
            } else if (shipping.delivery_status.value === "CANCELLED") {
                return shipping
            } else if (shipping.delivery_status.value === "PENDING") {
                return shipping
            }
 */
const DELIVERY_STATUS_PRIORITY: { [key: string]: number } = {
    PREPARED: 1,
    PROGRESS: 2,
    FAILURE: 3,
    SUCCESS: 4,
    CANCELLED: 5,
    PENDING: 6
}

const SUPPLY_INFO_CACHE = Symbol("SUPPLY_INFO_CACHE")

@Component({
    selector: ".eur-order-grid",
    templateUrl: "./order-grid.component.pug",
    providers: [
        {
            provide: DataSourceDirective,
            deps: [OrderRepoSource],
            useFactory: function (source: OrderRepoSource) {
                const result = new DataSourceDirective()
                result.dataSource = source
                return result
            }
        },
        OrderDetailsWndService,
        OrderWizardWndService
    ]
})
export class OrderGridComponent {
    public readonly fields = ORDER_FIELDS
    public readonly statusColors = STATUS_COLORS

    public readonly FF_ALL = {}
    public readonly FF_DRAFT = { status: "DRAFT" }
    public readonly FF_PENDING = { pending: true }
    public readonly FF_TODO = { pending_todo: { gt: 0 } }
    // public readonly FF_PROGRESS = { "or": [{ status: "PROGRESS" }, { "shippings.delivery_status": "PROGRESS" }] }
    public readonly FF_PROGRESS = { status: "PROGRESS" }
    public readonly FF_WORKABLE = { workable: true }
    // public readonly FF_PROGRESS = { status: "PROGRESS" }
    public readonly FF_PREPARED = { has_prepared_shipping: true }
    public readonly FF_COMPLETED = { status: { in: ["COMPLETED"] } }
    // public readonly FF_FAILED = { status: { "in": ["ERROR", "CANCELLED", "RETURNED"] } }
    public readonly FF_CANCELLED = { status: "CANCELLED" }
    // public readonly FF_CUSTOMER_CANCELLATION = { cancellation_request: { "neq": null as any } }
    public readonly FF_COMPLAINTS = { or: [{ qty_complaint: { gt: 0 } }, { status: "RETURNED" }] }

    public fastFilter: any = this.FF_ALL
    public supplyTypeFilter: any = null

    public get gridFilter() {
        return { ...this.fastFilter, supply_type: this.supplyTypeFilter }
    }

    private readonly reloadTodoCounts = new BehaviorSubject<void>(undefined)

    public readonly todoCounts = this.reloadTodoCounts.pipe(
        switchMap(() => this.orderRepo.get_todo_counts()),
        shareReplay(1)
    )

    public constructor(
        @Inject(DataSourceDirective) public readonly dataSource: DataSourceDirective,
        @Inject(OrderDetailsWndService) private readonly detailsSvc: OrderDetailsWndService,
        @Inject(OrderWizardWndService) private readonly wizardWnd: OrderWizardWndService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(FileDownloadService) private readonly download: FileDownloadService,
        @Inject(TaskService) private readonly taskSvc: TaskService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {
        setTimeout(() => {
            // preload partners
            this.partnerSvc.partners$.pipe(take(1)).subscribe()
        }, 500)
    }

    public onRowTap(orderId: number) {
        this.detailsSvc
            .show(orderId)
            .pipe(
                switchMap(orderId => {
                    this.reloadTodoCounts.next()
                    if (orderId === -1) {
                        return of(this.dataSource.storage.reload())
                    } else {
                        return this.dataSource.storage.reloadItem({ pk: String(orderId) } as any)
                    }
                })
            )
            .subscribe()
    }

    public printShippingLabels() {}

    public invoiceAndShip() {
        let return_value: any
        this.taskSvc
            .start("order-invoice-shipping")
            .pipe(
                exhaustMap(event => {
                    if (event.type === "success") {
                        this.reloadTodoCounts.next()
                        return_value = event.data.return_value
                        return this._downloadShippingPackage(return_value.shipping_ids).pipe(mapTo(event))
                    } else if (event.type === "button") {
                        if (event.role === "download_pacakge") {
                            return this._downloadShippingPackage(return_value.shipping_ids).pipe(mapTo(event))
                        }
                    }
                    return of(event)
                })
            )
            .subscribe()
    }

    // private _downloadShippingExcel(shippingIds: number[]) {
    //     return this.download
    //         .download(`${this.baseUrl}/get/shippings?shipping_ids=${shippingIds.join(",")}`)
    //         .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Szállító excel letöltése" }))
    // }

    // private _downloadShippingLabels(shippingIds: number[]) {
    //     return this.download
    //         .download(`${this.baseUrl}/get/carrier-label.pdf?shipping_ids=${shippingIds.join(",")}`)
    //         .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Szállító cimkék előállítása" }))
    // }

    private _downloadShippingPackage(shippingIds: number[]) {
        return this.download
            .download(`${this.baseUrl}/get/shipping-package?shipping_ids=${shippingIds.join(",")}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Szállítási adatok letöltése" }))
    }

    public relevantShipping(order: Order) {
        order.shippings.sort(
            (a, b) =>
                DELIVERY_STATUS_PRIORITY[a.delivery_status.value] - DELIVERY_STATUS_PRIORITY[b.delivery_status.value]
        )
        return order.shippings[0]
    }

    public dollarColor(order: Order) {
        if (order.payment_status.value === "PAID") {
            return STATUS_COLORS["COMPLETED"]
        } else if (order.proforma_invoice_id) {
            return "critical"
        } else {
            return "warn"
        }
    }

    public itemSupply(order: Order): { [key: string]: any } {
        if ((order as any)[SUPPLY_INFO_CACHE] == null) {
            ;(order as any)[SUPPLY_INFO_CACHE] = this._itemSupply(order)
        }
        return (order as any)[SUPPLY_INFO_CACHE]
    }
    private _itemSupply(order: Order): { [key: string]: any } {
        let qty_ordered = 0
        let qty_purchased = 0
        let qty_pending = 0
        let qty_received = 0
        let qty_cancelled = 0
        let qty_backorder = 0
        let qty_preorder = 0
        const errors: string[] = []
        let missingSupplier: boolean = false
        let hasAlternateSupplier: boolean = false

        for (const item of order.items) {
            qty_ordered += item.qty_ordered
            qty_purchased += item.qty_purchased
            qty_received += item.qty_received
            qty_pending += item.qty_pending
            qty_cancelled += item.qty_cancelled

            if (item.supply_error) {
                errors.push(item.supply_error)
            }

            const saids = item.supplier_available_ids || []
            if (!item.supplier_ids || item.supplier_ids.length === 0) {
                missingSupplier = true
            } else {
                hasAlternateSupplier =
                    hasAlternateSupplier || saids.filter(v => !item.supplier_ids.includes(v)).length > 0
            }

            if (item.supply_type.value === "PREORDER") {
                qty_preorder += order.status.value === "DRAFT" ? item.qty_ordered : item.qty_backorder
            } else {
                qty_backorder += item.qty_backorder
            }
        }

        const qty_progress = qty_pending + qty_purchased - Math.max(0, qty_received - qty_cancelled)

        return {
            qty_ordered,
            qty_progress,
            qty_received,
            qty_backorder,
            qty_preorder,
            missingSupplier,
            hasAlternateSupplier,
            error: errors.join("\n")
        }
    }

    public export() {
        const filter = JSON.stringify(this.dataSource.filter)
        const sorter = JSON.stringify(this.dataSource.sort)
        this.download
            .download(`${this.baseUrl}/get/orders?filter=${encodeURI(filter)}&sorter=${encodeURI(sorter)}`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Rendelések letöltése" }))
            .subscribe()
    }

    public toggleFilter(ff: any) {
        if (this.fastFilter === ff) {
            this.fastFilter = null
        } else {
            this.fastFilter = ff
        }
    }

    public doFilterSupplyType(st: string) {
        if (this.supplyTypeFilter === st) {
            this.supplyTypeFilter = undefined
        } else {
            this.supplyTypeFilter = st
        }
    }

    public newOrder() {
        this.wizardWnd.show().subscribe(orderId => {
            this.fastFilter = this.FF_DRAFT
            this.dataSource.storage.reload()
            this.onRowTap(orderId as number)
        })
    }
}
