import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"

import { BehaviorSubject, combineLatest, concat, map, shareReplay, take } from "rxjs"

import { ToastService } from "@anzar/core"

import { Order, OrderExtraItem, OrderItem, OrderRepo } from "@backend/order.api"

import { extraItemFormModel } from "../extra-item-form.component"
import { productFormModel } from "../product-form.component"
import { OrderDetailsService } from "./order-details.service"

export type EditType = "full" | "supplier"

@Component({
    selector: ".eur-order-items",
    templateUrl: "./items.component.pug"
})
export class OrderItemsComponent implements OnChanges {
    @Input() public order: Order
    @Input() public items: Array<OrderItem>
    @Input() public extraItems: Array<OrderExtraItem>
    @Input() public canEdit: boolean = false
    @Input() public canChangeSupplier: boolean = true
    @Input() public canAddItem: boolean = false
    @Output() public changes = new EventEmitter<void>()

    private readonly _canAddItem = new BehaviorSubject<boolean>(false)

    public readonly busy$ = new BehaviorSubject<boolean>(false)

    public readonly form = new FormGroup({
        items: new FormArray([]),
        extra_items: new FormArray([])
    })

    readonly todoRemove = new FormControl()

    public get itemsControl() {
        return this.form.get("items") as FormArray
    }

    public get extraItemsControl() {
        return this.form.get("extra_items") as FormArray
    }

    public isEdit$ = new BehaviorSubject<EditType | null>(null)
    public isFullEdit$ = this.isEdit$.pipe(
        map(type => type === "full"),
        shareReplay(1)
    )
    public isSupplierEdit$ = this.isEdit$.pipe(
        map(type => type === "supplier"),
        shareReplay(1)
    )
    public canAddItem$ = combineLatest({
        _canAddItem: this._canAddItem,
        isFullEdit: this.isFullEdit$,
        isSupplierEdit: this.isSupplierEdit$
    }).pipe(
        map(({ _canAddItem, isFullEdit, isSupplierEdit }) => _canAddItem && (isFullEdit || isSupplierEdit)),
        shareReplay(1)
    )

    public canDeleteItem = false

    constructor(
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(OrderDetailsService) private readonly orderDetailsSvc: OrderDetailsService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ("order" in changes) {
            this.canDeleteItem = this.order?.status.value === "DRAFT"
        }

        if ("canAddItem" in changes) {
            const value = changes.canAddItem.currentValue
            if (this._canAddItem.value !== value) {
                this._canAddItem.next(value)
            }
        }

        if ("items" in changes) {
            this.updateItemsControl()
        }

        if ("extraItems" in changes) {
            console.log("extraItems", this.extraItems.length, changes.extraItems.currentValue.length)
            this.updateExtraItemsControl()
        }
    }

    public doStartEdit(type: EditType) {
        this.updateItemsControl()
        this.updateExtraItemsControl()
        this.isEdit$.next(type)
    }

    private updateItemsControl() {
        this.itemsControl.clear()
        if (this.items) {
            for (const item of this.items) {
                this.itemsControl.push(productFormModel(item))
            }
        }
    }

    private updateExtraItemsControl() {
        this.extraItemsControl.clear()
        if (this.extraItems) {
            for (const item of this.extraItems) {
                this.extraItemsControl.push(extraItemFormModel(item))
            }
        }
    }

    public doCancelEdit() {
        this.isEdit$.next(null)
    }

    public doAddExtra() {
        this.extraItemsControl.push(extraItemFormModel())
    }

    public doDeleteExtra(index: number) {
        this._delete(this.extraItemsControl, index)
    }

    public doUndoExtraDelete(index: number) {
        this._undoDelete(this.extraItemsControl, index)
    }

    public doAddProduct() {
        this.itemsControl.push(productFormModel())
    }

    public doDeleteProduct(index: number) {
        this._delete(this.itemsControl, index)
    }

    public doUndoProductDelete(index: number) {
        this._undoDelete(this.itemsControl, index)
    }

    private _delete(container: FormArray, index: number) {
        const exists = container.at(index)
        if (exists != null) {
            const actionCtrl = exists.get("action")
            if (actionCtrl.value === "CREATE") {
                container.removeAt(index)
            } else {
                actionCtrl.setValue("DELETE")
            }
        }
    }

    private _undoDelete(container: FormArray, index: number) {
        const exists = container.at(index)
        if (exists != null) {
            const actionCtrl = exists.get("action")
            if (actionCtrl.value === "DELETE") {
                actionCtrl.setValue("UPDATE")
            }
        }
    }

    public doSave() {
        this.isSupplierEdit$.pipe(take(1)).subscribe(isSupplierEdit => {
            if (isSupplierEdit) {
                this._doSaveSupplier()
            } else {
                this._doNormalSave()
            }
        })
    }

    public _doNormalSave() {
        this.busy$.next(true)
        this._doSave()
            .pipe(this.toast.catchError(() => this.busy$.next(false)))
            .subscribe(() => {
                this.busy$.next(false)
                this.isEdit$.next(null)
            })
        this.changes.next()
    }

    public _doSaveSupplier() {
        this.busy$.next(true)

        concat(this._doSave(), this.orderDetailsSvc.supplierSendOrders())
            .pipe(this.toast.catchError(() => this.busy$.next(false)))
            .subscribe(() => {
                this.busy$.next(false)
                this.isEdit$.next(null)
                this.changes.next()
            })
    }

    private _doSave() {
        return this.orderRepo.patch({
            data: {
                ref: { id: this.order.id },
                items: this.itemsControl.value,
                extra_items: this.extraItemsControl.value
            }
        })
    }

    public getItemAction(index: number) {
        return this.itemsControl.controls[index]?.get("action").value || "UPDATE"
    }

    public getExtraItemAction(index: number) {
        return this.extraItemsControl.controls[index]?.get("action").value || "UPDATE"
    }
}
