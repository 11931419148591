import { Component, Input } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import { OrderExtraItem } from "@backend/order.api"

import { PriceInputComponent } from "../common.module"

export function extraItemFormModel(extraItem?: OrderExtraItem) {
    return new FormGroup({
        action: new FormControl(extraItem && extraItem.id ? "UPDATE" : "CREATE"),
        ref: new FormControl(extraItem && extraItem.id ? { id: extraItem.id } : null),
        name: new FormControl(extraItem?.name),
        item_id: new FormControl(extraItem?.item_id),
        qty_ordered: new FormControl(extraItem?.qty_ordered, [Validators.required]),
        unit_price: PriceInputComponent.formModel(extraItem?.unit_price, "Egységár megadása kötelező")
    })
}

@Component({
    selector: "eur-order-extra-item-form",
    templateUrl: "./extra-item-form.component.pug"
})
export class OrderExtraItemFormComponent {
    @Input() readonly form: FormGroup

    public constructor() {}
}
