import { Component, Inject, Input } from "@angular/core"

import { map, shareReplay } from "rxjs/operators"

import { DataSourceDirective, LoadFields, SelectionModel } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"

import { Product, ProductRepoSource } from "@backend/product.api"

import { PartnerService } from "../common.module"
import { ProductSheetService } from "./product-sheet.component"

const PRODUCT_FIELDS: LoadFields<Product> = [
    "id",
    "name",
    "sku",
    "has_stock",
    "best_wslp",
    { manufacturer: ["name"] },
    { merchants: [{ partner: ["name"] }] },
    { suppliers: [{ partner: ["name"] }] },
    { categories: ["name"] }
]

@Component({
    selector: ".eur-product-grid",
    templateUrl: "./product-grid.component.pug",
    exportAs: "eurProductGrid",
    providers: [
        {
            provide: DataSourceDirective,
            deps: [ProductRepoSource],
            useFactory: (src: ProductRepoSource) => {
                const ds = new DataSourceDirective()
                ds.dataSource = src
                ds.sort = { id: "desc" } as any
                return ds
            }
        }
    ]
})
export class ProductGridComponent {
    @Input() public padding: any = 24
    @Input() public showMerchant: boolean = true
    @Input() public showProductInfoAlt: boolean = false

    public readonly fields = PRODUCT_FIELDS

    public readonly supplierFilterItems$ = this.partnerSvc.suppliers$.pipe(
        map(suppliers =>
            suppliers.map(s => {
                return { value: s.id, label: s.name }
            })
        ),
        shareReplay(1)
    )

    public readonly merchantFilterItems$ = this.partnerSvc.merchants$.pipe(
        map(merchant =>
            merchant.map(m => {
                return { value: m.id, label: m.name }
            })
        ),
        shareReplay(1)
    )

    public readonly trueFalseSrc = [
        { value: 1, label: "Igen" },
        { value: 0, label: "Nem" }
    ]

    public readonly canViewMerchants$ = this.authSvc.hasPermission("product.view-merchants")
    public readonly canViewSuppliers$ = this.authSvc.hasPermission("product.view-suppliers")

    public constructor(
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective,
        @Inject(ProductSheetService) protected readonly sheet: ProductSheetService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(SelectionModel) public readonly selection: SelectionModel,
        @Inject(AuthService) private readonly authSvc: AuthService
    ) {}

    public reload() {
        this.source.reload()
    }

    public onRowTap(product: Product) {
        if (!this.showProductInfoAlt) {
            this.doShowProductInfo(product)
        }
    }

    public doShowProductInfo(product: Product) {
        this.sheet.show(product.id).subscribe()
    }

    public _partnerNames(val: any[]) {
        if (val && val.length) {
            return val
                .map(v => v.partner.name)
                .sort()
                .join(", ")
        } else {
            return "-"
        }
    }

    public _categoryNames(val: any[]) {
        if (val && val.length) {
            return val.map(v => v.name).join(",")
        } else {
            return "-"
        }
    }
}
