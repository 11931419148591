import { ChangeDetectorRef, Component, Inject, Injectable } from "@angular/core"
import { FormControl } from "@angular/forms"

import { Observable } from "rxjs"
import { filter, switchMap } from "rxjs/operators"

import { DropdownLayer, LayerRef, LayerService, ToastService, UploadedFile } from "@anzar/core"

import { FileUploaderService } from "@pyzar/fs.module"

import { ManufacturerRepo } from "@backend/product.api"

@Injectable()
export class ManufacturerUploaderService {
    public constructor(@Inject(LayerService) private readonly layerSvc: LayerService) {}

    public show(target: HTMLElement) {
        return new Observable(subscriber => {
            const behavior = new DropdownLayer({
                backdrop: { type: "filled", hideOnClick: false },
                closeable: false,
                menuLike: true,
                elevation: 10,
                rounded: 3,
                position: {
                    align: "top center",
                    anchor: {
                        ref: target,
                        align: "bottom center"
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(ManufacturerUploaderWndComponent, behavior)

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    subscriber.complete()
                } else if (event.type === "import-done") {
                    subscriber.next(true)
                }
            })

            ref.show()

            return () => {
                ref.hide()
            }
        })
    }
}

@Component({
    selector: "eur-manufacturer-uploader-wnd",
    templateUrl: "./manufacturer-uploader.component.pug",
    providers: [FileUploaderService]
})
export class ManufacturerUploaderWndComponent {
    public readonly file = new FormControl()

    public set isBusy(val: boolean) {
        if (this._isBusy !== val) {
            this._isBusy = val
            this.cdr.detectChanges()
        }
    }
    public get isBusy(): boolean {
        return this._isBusy
    }
    private _isBusy: boolean = false

    public constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(FileUploaderService) private readonly fsUploader: FileUploaderService,
        @Inject(ManufacturerRepo) private readonly manufacturer: ManufacturerRepo,
        @Inject(ToastService) private readonly toastService: ToastService
    ) {}

    public cancel() {
        this.layerRef.hide()
    }

    public doImport() {
        this.isBusy = true

        this.fsUploader
            .upload(`/manufacturers`)
            .pipe(
                filter(e => e.state === "done"),
                switchMap(event => {
                    const data = this.file.value as UploadedFile

                    return this.manufacturer.import_excel({ file_id: data.id }).pipe(
                        this.toastService.handleSave({
                            align: "bottom center",
                            beginMsg: "Gyártók importálása...",
                            successMsg: "Gyártók sikeresen importálva",
                            onError: error => {
                                this.isBusy = false
                                this.file.reset()
                            }
                        })
                    )
                })
            )
            .subscribe(event => {
                this.layerRef.emit({ type: "import-done" })
                this.layerRef.hide()
            })
    }
}
