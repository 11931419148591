import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from "@angular/core"
import { FormControl } from "@angular/forms"

import { BehaviorSubject, Observable, of } from "rxjs"
import { map, shareReplay, switchMap, take, tap } from "rxjs/operators"

import {
    Destructible,
    DropdownLayer,
    LayerRef,
    LayerService,
    LoadFields,
    MaskRef,
    MaskService,
    ToastService
} from "@anzar/core"

import { OrderExtraItem, OrderPermits } from "@backend/__anzar_rpc_output"
import { Complaint, Order, OrderHistory, OrderHistoryRepo, OrderItem, OrderRepo } from "@backend/order.api"

import { PartnerService } from "../../common.module"
import { ShippingService } from "../../shipping.module"
import { ComplaintProducts } from "../complaint/complaint-products.service"
import { BASIC_COMPLAINT_FIELDS } from "../complaint/complaint.service"
import { NewComplaintService } from "../complaint/new-complaint.component"
import { CancelComponent } from "./cancel.component"
import { ORDER_HISTORY_FIELDS } from "./history-item.component"
import type { HistoryResolveEvent } from "./history-item.component"
import { ORDER_EXTRA_ITEM_FIELDS, ORDER_ITEM_FIELDS } from "./item.component"
import { OrderDetailsService } from "./order-details.service"
import { SHIPPING_FIELDS } from "./shipping.component"
import { INITIAL_FIELDS } from "./supply.component"

export const ORDER_FIELDS: LoadFields<Order> = [
    "id",
    "created_time",
    "status",
    "partner_entity_id",
    "partner_orderno",
    "base_price_net",
    "base_price_gross",
    "final_price_net",
    "final_price_gross",
    "discount_price_net",
    "discount_price_gross",
    "cancellation_request",
    "currency",
    "conversion_rate",
    "payment_status",
    "proforma_invoice_id",
    { items: ORDER_ITEM_FIELDS },
    { extra_items: ORDER_EXTRA_ITEM_FIELDS },
    {
        shipping_info: [
            "id",
            "name",
            "phones",
            "emails",
            "address_formatted",
            "country",
            "city",
            "postcode",
            "shire",
            "address",
            "note",
            "contact_name",
            "price",
            { shipping_method: ["id", "description"] }
        ]
    },
    {
        billing_info: [
            "id",
            "name",
            "phones",
            "emails",
            "address_formatted",
            "country",
            "city",
            "postcode",
            "shire",
            "address",
            "note",
            "tax_number",
            "payment_method",
            "proforma_need",
            "fulfillment_days",
            "due_days"
        ]
    },
    { partner: ["id", "name"] },
    { shippings: SHIPPING_FIELDS },
    { history: ORDER_HISTORY_FIELDS },
    { sync: ["last_read_time"] },
    { complaints: BASIC_COMPLAINT_FIELDS },
    { sorders: INITIAL_FIELDS }
]

@Component({
    selector: ".eur-order-details",
    templateUrl: "./order-details.component.pug",
    providers: [NewComplaintService]
})
export class OrderDetailsComponent extends Destructible implements OnChanges {
    @Input() public order: Order
    @Output() public readonly changes = new EventEmitter<number>()

    private readonly _reset = new BehaviorSubject<number>(null)

    private readonly permits$ = this._reset.pipe(
        switchMap(orderId => (orderId ? this.orderRepo.permits({ id: orderId }) : of({} as OrderPermits))),
        shareReplay(1)
    )

    public readonly canShip$ = this.permits$.pipe(
        map(p => !!p.ship),
        shareReplay(1)
    )
    public readonly canInvoice$ = this.permits$.pipe(
        map(p => !!p.invoice),
        shareReplay(1)
    )
    public readonly canProforma$ = this.permits$.pipe(
        map(p => !!p.proforma),
        shareReplay(1)
    )
    public readonly canOffer$ = this.permits$.pipe(
        map(p => !!p.offer),
        shareReplay(1)
    )
    public readonly canCancel$ = this.permits$.pipe(
        map(p => !!p.cancel),
        shareReplay(1)
    )
    public readonly canEdit$ = this.permits$.pipe(
        map(p => !!p.edit),
        shareReplay(1)
    )
    public readonly canRemove$ = this.permits$.pipe(
        map(p => !!p.remove),
        shareReplay(1)
    )
    public readonly canFinalize$ = this.permits$.pipe(
        map(p => !!p.finalize),
        shareReplay(1)
    )
    public readonly canComplaint$ = this.permits$.pipe(
        map(p => !!p.complaint),
        shareReplay(1)
    )
    public readonly canChangeShippingInfo$ = this.permits$.pipe(
        map(p => !!p.change_shipping_info),
        shareReplay(1)
    )
    public readonly canChangeBillingInfo$ = this.permits$.pipe(
        map(p => !!p.change_billing_info),
        shareReplay(1)
    )
    public readonly canChangeDeliveryStatus$ = this.permits$.pipe(
        map(p => !!p.change_delivery_status),
        shareReplay(1)
    )
    public readonly canChangeSupplier$ = this.permits$.pipe(
        map(p => !!p.change_supplier),
        shareReplay(1)
    )
    public readonly canAddItem$ = this.permits$.pipe(
        map(p => !!p.add_item),
        shareReplay(1)
    )

    public readonly partner$ = this._reset.pipe(
        switchMap(orderId =>
            this.partnerSvc.partners$.pipe(
                map(partners => partners.find(partner => partner.id === this.order.partner.id))
            )
        ),
        shareReplay(1)
    )

    public readonly merchantOrderUrl = this.partner$.pipe(
        map(partner => this.partnerSvc.orderViewUrl(partner, this.order)),
        shareReplay(1)
    )

    public set busy(val: boolean | string) {
        if (this._busy !== val) {
            this._busy = val
            if (val) {
                this._maskShow()
            } else {
                this._maskHide()
            }
        }
    }
    public get busy(): boolean | string {
        return this._busy
    }
    public _busy: boolean | string

    public readonly newNote = new FormControl()
    public readonly isTodo = new FormControl()

    public inAddHistory: boolean = false
    public historyEntries: OrderHistory[] = []
    public items: OrderItem[] = []
    public extraItems: OrderExtraItem[] = []
    public complaints: Complaint[] = []
    public editShippingInfo: boolean = false
    public editBillingInfo: boolean = false
    public totalItemQty = 0
    // public hideShipping: boolean = false
    private maskRef: MaskRef

    public constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(OrderRepo) private readonly orderRepo: OrderRepo,
        @Inject(OrderHistoryRepo) private readonly orderHistoryRepo: OrderHistoryRepo,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(MaskService) private readonly maskSvc: MaskService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(ShippingService) private readonly shippingSvc: ShippingService,
        @Inject(NewComplaintService) private readonly newComplaintSvc: NewComplaintService,
        @Inject(ComplaintProducts) private readonly complaintProducts: ComplaintProducts,
        @Inject(OrderDetailsService) private readonly svc: OrderDetailsService
    ) {
        super()

        this.destruct.any(this._maskHide.bind(this))
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ("order" in changes) {
            this._onOrderChanged(changes.order.currentValue)
        }
    }

    private _onOrderChanged(order: Order) {
        this.items = order.items.sort((a, b) => a.line - b.line)
        this.extraItems = order.extra_items.sort((a, b) => a.line - b.line)
        this.complaints = order.complaints.sort((a, b) => a.created_time.getTime() - b.created_time.getTime())
        this.historyEntries = order.history.sort((a, b) => b.created_time.getTime() - a.created_time.getTime())

        for (const item of this.items) {
            this.totalItemQty += Math.max(0, item.qty_ordered - item.qty_cancelled)
        }

        this.complaintProducts.orderId = order.id
        this._reset.next(order ? order.id : null)
    }

    public onCancel() {
        this.layerRef.hide()
    }

    public doCancel(event: Event) {
        this._showWnd(event.target as HTMLElement, CancelComponent)
            .pipe(switchMap(() => this.doReload()))
            .subscribe()
    }

    public doInvoice() {
        this.busy = "invoice"
        this.orderRepo
            .do_invoice({ order_id: this.order.id })
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Számla kiállítása" }))
            .subscribe()
        this.doReload().subscribe()
    }

    public doProforma() {
        this.busy = "proforma"
        this.orderRepo
            .do_proforma({ order_id: this.order.id })
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Díjbekérő küldése" }))
            .subscribe()
        this.doReload().subscribe()
    }

    public doOffer() {
        this.busy = "offer"
        this.orderRepo
            .do_offer({ order_id: this.order.id })
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Árajánlat készítése" }))
            .subscribe()
        this.doReload().subscribe()
    }

    public doFinalize() {
        this.busy = "finalize"
        this.orderRepo
            .patch({ data: { ref: { id: this.order.id }, status: "PENDING" } })
            .pipe(this.toast.catchError())
            .subscribe()
        this.doReload().subscribe()
    }

    public doRemove() {
        this.busy = "remove"
        this.orderRepo
            .remove({ id: this.order.id })
            .pipe(
                this.toast.handleSave({
                    align: "bottom center",
                    beginMsg: "Megrendelés törlése"
                })
            )
            .pipe(switchMap(() => this.svc.nextOrder()))
            .subscribe(nextOrder => {
                if (!nextOrder) {
                    this.layerRef.hide()
                } else {
                    this.busy = false
                }
            })
        this.changes.next(-1)
    }

    public doReload() {
        this.changes.next(this.order.id)
        return new Observable(o => {
            this._reset.next(this.order.id)
            const s = this.orderRepo
                .get({ id: this.order.id }, { loadFields: ORDER_FIELDS })
                .pipe(
                    tap(order => {
                        this.order = order
                        this._onOrderChanged(order)
                        this.busy = false
                        this.cdr.detectChanges()
                    })
                )
                .subscribe(o)
            return s.unsubscribe.bind(s)
        }).pipe(take(1))
    }

    public onItemChanges() {
        this.doReload().subscribe()
    }

    public onShippingChanges() {
        this.doReload().subscribe()
    }

    public onShippingBusy(busy: boolean) {
        this.busy = busy
    }

    public onHistoryAdded() {
        this.inAddHistory = false
        this.doReload().subscribe()
    }

    private _showWnd(target: HTMLElement, cmp: any): Observable<any> {
        return new Observable(sub => {
            const behavior = new DropdownLayer({
                backdrop: { type: "empty", hideOnClick: true },
                rounded: 3,
                elevation: 10,
                menuLike: true,
                position: {
                    align: "top center",
                    anchor: {
                        align: "bottom center",
                        ref: target
                    }
                }
            })
            const ref = this.layerSvc.createFromComponent(cmp, behavior, null, [
                { provide: Order, useValue: this.order }
            ])

            const s = ref.output.subscribe(event => {
                if (event.type === "operation") {
                    sub.next(event.data)
                    sub.complete()
                } else if (event.type === "hiding") {
                    sub.complete()
                }
            })

            ref.show()

            return () => {
                s?.unsubscribe()
                ref.hide()
            }
        })
    }

    private _maskShow() {
        if (!this.maskRef) {
            this.maskRef = this.maskSvc.show(this.el.nativeElement, { backgroundColor: "rgba(255, 255, 255, 0.01)" })
        }
    }

    private _maskHide() {
        if (this.maskRef) {
            this.maskRef.dispose()
            delete this.maskRef
        }
    }

    public onShippingInfoChange(event: any) {
        this.doReload().subscribe()
    }

    public onBillingInfoChange(event: any) {
        this.doReload().subscribe()
    }

    public onResolveHistory(event: HistoryResolveEvent) {
        this.orderHistoryRepo
            .resolve_todo({ history_id: event.id, resolved: event.resolved })
            .pipe(this.toast.catchError())
            .subscribe(() => this.changes.next(this.order.id))
    }

    public doNewComplaint(event: Event) {
        event.preventDefault()
        this.newComplaintSvc.show(this.order.id, event.target as HTMLElement).subscribe(complaint => {
            this.doReload().subscribe()
        })
    }

    public onComplaintUpdated() {
        this.changes.next(this.order.id)
    }

    public onComplaintRemoved() {
        this.doReload().subscribe()
    }

    public onComplaintReloadOrder() {
        this.doReload().subscribe()
    }

    public complaintTrackBy(index: number, item: any) {
        return item?.id
    }

    public shippingTrackBy(index: number, item: any) {
        return item?.id
    }

    public sorderTrackingBy(index: number, item: any) {
        return item?.id
    }

    public onSupplyChanges() {
        this.doReload().subscribe()
    }
}
