import { Component, Inject } from "@angular/core"

import { take } from "rxjs"

import { DataSourceDirective, Destructible, LoadFields } from "@anzar/core"

import { OrderCrediting, OrderCreditingRepoSource } from "@backend/order.api"

import { OrderCreditingService } from "./crediting.service"

const FIELDS: LoadFields<OrderCrediting> = [
    "id",
    "credit_date",
    "amount",
    { merchant: ["name"] },
    { supplier: ["name"] },
    { manufacturer: ["name"] }
]

@Component({
    selector: ".eur-order-crediting-grid",
    templateUrl: "./grid.component.pug",
    providers: [
        {
            provide: DataSourceDirective,
            deps: [OrderCreditingRepoSource],
            useFactory: function (source: OrderCreditingRepoSource) {
                const result = new DataSourceDirective()
                result.dataSource = source
                return result
            }
        }
    ]
})
export class CreditingGridComponent extends Destructible {
    public readonly fields = FIELDS

    public constructor(
        @Inject(DataSourceDirective) public readonly dataSource: DataSourceDirective,
        @Inject(OrderCreditingService) private readonly crediting: OrderCreditingService
    ) {
        super()

        this.destruct.subscription(crediting.changes).subscribe(value => {
            if (value == null) {
                this.dataSource.reload()
            } else {
                this.dataSource.storage
                    .reloadItem({ pk: `${value}` } as any)
                    .pipe(take(1))
                    .subscribe()
            }
        })
    }

    public onRowTap(crediting: OrderCrediting) {
        this.crediting.mod(crediting.id).subscribe()
    }
}
