import { ChangeDetectorRef, Component, Inject } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"

import { combineLatest } from "rxjs"
import { map, shareReplay } from "rxjs/operators"

import { Destructible, FileDownloadService, ToastService, ViewportService } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"

import { User } from "@backend/pyzar.api"

import { BACKEND_BASE_URL } from "../common.module"

@Component({
    selector: "mc-viewport",
    templateUrl: "./viewport.component.pug",
    providers: [ViewportService]
})
export class ViewportComponent extends Destructible {
    private _currentUrl: string
    public currentUser: User

    public readonly canViewOrders$ = this.authSvc.hasPermission("order.view")
    public readonly canOrderCraditing$ = this.authSvc.hasPermission("order.crediting")
    public readonly canViewPayment$ = this.authSvc.hasPermission("accounting.payment")
    public readonly canViewProducts$ = this.authSvc.hasPermission("product.view")
    public readonly canManageProducts$ = this.authSvc.hasPermission("product.manage")
    public readonly canViewCategories$ = this.authSvc.hasPermission("category.view")
    public readonly canViewManufacturers$ = this.authSvc.hasPermission("manufacturers.manage")
    public readonly canViewPartners$ = this.authSvc.hasPermission("partner.view")
    public readonly canViewUsers$ = this.authSvc.hasPermission("auth.user.manage")
    public readonly canViewPerms$ = this.authSvc.hasPermission("auth.perm.edit")
    public readonly canViewBgtask$ = this.authSvc.currentUser$.pipe(map(user => user.username === "admin"))
    public readonly canViewAnySettings$ = combineLatest([
        this.canViewCategories$,
        this.canViewPartners$,
        this.canViewUsers$,
        this.canViewPerms$,
        this.canViewBgtask$
    ]).pipe(
        map(v => v.some(v => v === true)),
        shareReplay(1)
    )

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(Router) protected readonly router: Router,
        @Inject(AuthService) public readonly authSvc: AuthService,
        @Inject(FileDownloadService) private readonly filedDownload: FileDownloadService,
        @Inject(BACKEND_BASE_URL) private readonly backendBaseUrl: string,
        @Inject(ToastService) private readonly toast: ToastService
    ) {
        super()

        this.destruct.subscription(router.events).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._currentUrl = event.url
                this.cdr.markForCheck()
            }
        })

        this.destruct.subscription(authSvc.currentUser$).subscribe(user => {
            this.currentUser = user
            cdr.markForCheck()
        })
    }

    public routeIsActive(prefix: string): boolean {
        return this._currentUrl && this._currentUrl.startsWith(prefix)
    }

    public isDashboard(): boolean {
        return !this._currentUrl || this._currentUrl.length === 1
    }

    public logout() {
        this.authSvc.logout()
    }

    public downloadStats() {
        this.filedDownload
            .download(`${this.backendBaseUrl}/get/stat/all.xlsx?regen=1`)
            .pipe(this.toast.handleFileDownload({ beginMsg: "Statisztika generálása", align: "bottom center" }))
            .subscribe()
    }
}
