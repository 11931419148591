import { NgModule } from "@angular/core"
import { CarrierTrait, PickupAddressTrait, ShippingMethod, ShippingMethodRepo, ShippingMethodRepoSource } from "./__anzar_rpc_output"

export { CarrierTrait, PickupAddressTrait, ShippingMethod, ShippingMethodRepo, ShippingMethodRepoSource }


@NgModule({
    providers: [
        ShippingMethodRepoSource,
        ShippingMethodRepo
    ],
})
export class CarrierApi {}